@import "../../mixins/layouts";

$include-fonts: false;
@import "../../setup/settings";
@import "../../mixins/helpers";
@import "../../plugins/slick";

.ratings-reviews-section {
    [class^=tt-c-], [class^=tt-l-], [class^=tt-o-], [class^=tt-u-], [class^=tt-w-] {
        font-family: 'Poppins', Arial, sans-serif;
        @include breakpoint(medium up) {
            order: 2;
        }
    }

    .tt-l-grid__row.tt-c-review__grid-row{
        justify-content: flex-start;

        @include breakpoint(medium down) {
            display: flex;
            flex-direction: column;
        }
    }
    #ratingsReviewsTitle {
        .title {
            font-weight: 600;
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            margin-bottom: rem-calc(16);

            @media screen and (max-width: 1023px) {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
            }
        }
    }
    .tt-o-byline__item.tt-o-byline__author {
        display: block;
        text-transform: none;
        letter-spacing: rem-calc(0.35);
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $black;
        text-transform: capitalize;
        font-weight: $global-weight-dark;
        margin-bottom: rem-calc(8);
    }
    .tt-o-badge {
        display: block;
        background-color: initial;
        padding: 0;
        margin: 0;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        font-weight: 400;
        text-transform: lowercase;
        width: max-content;
        color: $medium-gray;
        @media screen and (max-width: 1023px) {
            max-width: 100%;
        }
        &.tt-o-badge--staff {
            display: none;
        }
    }
    .tt-c-review {
        padding: rem-calc(40 0 0);
        border-bottom: rem-calc(1) solid $light-gray;
        &:last-child {
            @include breakpoint(tablet up) {
                border-bottom: none;
            }
        }
        @media screen and (max-width: 1023px) {
            margin-bottom: 0;
        }

        .tt-c-review__text-content-wrapper.tt-u-mb--md {
            margin-bottom: rem-calc(8);
        }
    }
    .tt-c-reviews-summary__no-reivews {
        display: none;
    }
    .tt-c-ratings-breakdown__table, .tt-c-summary-dim, .tt-c-reviews-summary__write-review-wrap {
        display: none;
    }
    .tt-c-reviews-summary__content {
        background-color: #FFF;
        margin: rem-calc(60 40 0);
        padding-bottom: rem-calc(60);
        border-bottom: rem-calc(1) solid $light-gray;

        @media screen and (max-width: 1023px) {
            margin: rem-calc(0 16);
            border: 0;
            padding-bottom: 0;
        }
        > *:first-child {
            display: none!important;
        }
        > :last-child {
            display: none!important;
        }
        [class^=tt-c-], [class^=tt-l-], [class^=tt-o-], [class^=tt-u-], [class^=tt-w-] {
             @include breakpoint (medium up){
                order: 1;
             }
        }
    }
    .tt-o-listbox__list {
        background-color: #FFFFFF;
        margin-left: rem-calc(0);
        margin-top: rem-calc(1);
        width: rem-calc(210);
        box-shadow: rem-calc(0 3 6) #00000029;
        display: flex;

        .tt-o-listbox__option {
            text-align: left;
            position: relative;
            line-height: rem-calc(8);
            padding: rem-calc(8 16);
            outline: none;
            display: flex;
            align-items: flex-end;

            &:first-child {
                padding-top: rem-calc(16);
            }
            &:last-child {
                padding-bottom: rem-calc(16);
            }
            .tt-o-listbox__option-icon-wrap {
                width: rem-calc(16);
                height: rem-calc(16);
                border: 1px solid $black;
                margin-right: rem-calc(8);
                position: relative;
                min-width: 0;

                &::after {
                    background: $black;
                    content: "";
                    position: absolute;
                    height: rem-calc(8);
                    width: rem-calc(8);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: none;
                }

                .tt-o-icon.tt-o-icon--check.tt-o-icon--md.tt-o-listbox__option-icon.tt-o-listbox__option-icon {
                    display: none;
                }
            }
            .tt-o-listbox__option-text {
                font-size: rem-calc(12);
            }
            &[aria-selected=true] {
                .tt-o-listbox__option-icon-wrap {
                  &::after {
                    display: block;
                  }
                }
            }
            .tt-o-icon.tt-o-icon--checkbox--off.tt-o-icon--md.tt-o-listbox__option-icon.tt-o-listbox__option-icon {
                display: none;
            }
            .tt-o-icon.tt-o-icon--checkbox--on.tt-o-icon--md.tt-o-listbox__option-icon.tt-o-listbox__option-icon {
                display: none;
            }
        }
    }
    .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--2 {
        width: 100%;
        margin-bottom: rem-calc(32);
        @include breakpoint (medium up) {
            &:last-child {
                margin-bottom: 0;
            }
        }

        @include breakpoint (medium down) {
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(30);
        }
    }
    @media screen and (max-width: 1023px) {
        .tt-l-grid__row.tt-c-reviews-summary__grid-row {
            text-align: left;
            display: block;
            padding: 0;
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            border-right: 0!important;
            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                border-right: rem-calc(1) solid $black;
                margin-right: rem-calc(4);
                padding-right: rem-calc(4);
            }
            .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
                order: 2;
                .tt-c-ratings-breakdown__heading.tt-u-align-center {
                    white-space: nowrap;
                }
            }
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            line-height: rem-calc(12)!important;
        }
    }
    @media screen and (max-width: 1023px) {
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--3 {
            width: 100%;
            .tt-c-dimension__heading.tt-u-mb--sm {
                .tt-c-dimension__value-label {
                    font-size: rem-calc(12);
                    line-height: rem-calc(16);
                    letter-spacing: rem-calc(0.3);
                    color: $black;
                    padding: rem-calc(8 16);
                    font-weight: 500;
                    border: 1px solid $light-gray;
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: rem-calc(20);
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--3 {
            margin: 0;
            padding: 0;
            .tt-c-dimension__heading.tt-u-mb--sm {
                margin: 0;

                .tt-c-dimension__label {
                    display: none;
                }
                .tt-c-dimension__value-label {
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.28);
                    color: $black;
                }
            }
        }
        .tt-c-rating.tt-c-review__rating.tt-u-mb--sm {
            order: 3;
            width: 100%;
            margin-bottom: rem-calc(20);
            margin-top: rem-calc(16);
        }
        .tt-c-review__date.tt-u-mb--sm {
            order: 8!important;
            margin: rem-calc(0 0 10 0);
        }
        .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--1.tt-c-review__grid-col--1 {
            .tt-o-byline.tt-u-spacing--left--xs.tt-c-review__byline.tt-u-mb--lg {
                  order: 1;
                  margin-bottom: 0;
              }
        }

        .tt-c-review__heading.tt-u-mb--sm {
            order: 3;
        }
        .tt-c-review__text {
            order: 4;
            margin-bottom: rem-calc(32);
            width: 100%;
            margin-bottom: rem-calc(24);

            @include breakpoint (medium up) {
                margin-bottom: rem-calc(9);
            }

            .tt-u-mb--md {
                margin-bottom: 0;
            }
        }
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--2 {
            margin-bottom: rem-calc(35);
            order: 6;

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(12);
            }
        }
        .tt-c-review__expander.tt-o-expander.tt-o-expander--with-dashed-border {
            width: 100%;
            order: 5;
        }
        .tt-l-grid__row.tt-c-review__grid-row {
            .tt-l-grid__footer.tt-c-review__grid-footer {
                .tt-c-review-toolbar.tt-u-mb--xs {
                    .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
                        .tt-o-button.tt-o-button--toolbar.tt-o-button--flat-icon-left.tt-c-review-toolbar__vote.tt-c-review-toolbar__vote:first-of-type {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .tt-l-grid__row.tt-c-reviews-summary__grid-row {
            text-align: left;
            display: block;
            padding: 0;
            line-height: rem-calc(14);
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                border-right: 1px solid $black;
                margin-right: rem-calc(4);
                padding-right: rem-calc(4);
            }
            .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
                order: 2;
                .tt-c-ratings-breakdown__heading.tt-u-align-center {
                    white-space: nowrap;
                    @media screen and (max-width: 415px) {
                        font-size: rem-calc(14);
                    }
                }
            }
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            line-height: rem-calc(12)!important;
        }
        .tt-l-grid__header.tt-c-review__grid-header {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .reviewFilterBtn {
        .tt-o-listbox__list {
            margin-left: 0;
            margin-top: 0;

            .tt-o-listbox__option {
                padding-left: rem-calc(24);
                .tt-o-listbox__option-text {
                    font-size: rem-calc(12);
                }
                .tt-o-listbox__rating-option {
                    position: relative;
                }
            }
        }
    }
    .tt-c-reviews-summary__rating-number, .tt-c-reviews-summary__rating-text, .tt-c-ratings-breakdown__heading {
        font-family: 'Poppins', Arial, sans-serif;
        margin-right: 0;
    }
    .tt-l-grid__row.tt-c-reviews-summary__grid-row {
        text-align: left;
        display: block;
        padding: 0;
        line-height: rem-calc(14);
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            margin: 0!important;
            display: inline-flex;

            @include breakpoint (ipad down) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%!important; // remove style inline from js
            }

            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                display: inline;
                order: 2;
                white-space: nowrap;
                margin-bottom: 0;

                @media screen and (max-width: 1023px) {
                   margin: 0;
                   padding-right: rem-calc(10);
                }

                .tt-c-reviews-summary__rating-number,
                .tt-c-reviews-summary__rating-text {
                   font-size: rem-calc(14);
                   line-height: rem-calc(21);
                   letter-spacing: rem-calc(0.2);
                   font-weight: 400;
                }

                @include breakpoint (medium up) {
                    border-right: rem-calc(1) solid $black;
                    padding-right: rem-calc(6);
                }

                @include breakpoint (medium down) {
                    margin-bottom: 0;
                }
            }
            .tt-c-rating.tt-c-reviews-summary__stars.tt-u-mb--lg {
                margin-bottom: 0;
                align-items: center;
                padding-right: rem-calc(6.5);

                @include breakpoint (medium down) {
                    width: 100%;
                    margin-bottom: rem-calc(10);
                }
            }
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--2.tt-c-reviews-summary__grid-col--2 {
            width: min-content!important;
            margin: 0!important;
            display: inline-block;
            padding-left: 0;
            vertical-align: top;
            line-height: rem-calc(14);
            .tt-c-ratings-breakdown__heading.tt-u-align-center {
                white-space: nowrap;
            }
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--3.tt-c-reviews-summary__grid-col--3 {
            width: min-content!important;
            margin: 0!important;
            flex: 0!important;
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--4.tt-c-reviews-summary__grid-col--4 {
            width: min-content!important;
            margin: 0!important;
            flex: 0!important;
        }
        .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
            order: 2;
            padding-left: rem-calc(10);

            @media screen and (max-width: 1023px) {
                display: flex;
                align-items: center;
            }

            .tt-c-ratings-breakdown__heading.tt-u-align-center {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                font-weight: normal;
            }
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            text-align: left;
            line-height: rem-calc(14);
            white-space: nowrap;
        }
        .tt-c-ratings-breakdown {
            margin-bottom: 0;
        }
    }
    .tt-c-rating__star {
        width: rem-calc(14);
        height: rem-calc(14);
        margin-right: rem-calc(2);

        .tt-o-icon {
            width: 100%;
            height: 100%;
            fill: $black;
            stroke-width: rem-calc(1.5);
        }
    }
    .tt-c-reviews-list__header {
        display: none;
    }
    .tt-c-review__heading-text {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        font-weight: 600;
        order: 3;
    }
    .tt-c-review__heading.tt-u-mb--sm {
        margin-bottom: rem-calc(16);

        @media screen and (max-width: 1023px) {
            margin-bottom: rem-calc(20);
        }
    }
    .tt-c-review__text-content {
        font-weight: normal;
        color: $black;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
    }
    .tt-o-search-field.tt-o-search-field--with-search-icon.tt-c-reviews-toolbar__search {
        display: none;
    }
    .tt-w-reviews-list {
        .tt-c-reviews-list__content {
            margin: 0 auto;
            padding: rem-calc(20 40 60);

            @media screen and (max-width: 1023px) {
              padding: rem-calc(0 16 38);
            }
        }
    }
    .tt-c-product-about.tt-c-review__product.tt-u-mb--md {
        display: none;
    }
    .tt-l-grid.tt-c-review__content {
        position: relative;
        padding-bottom: rem-calc(50);

        @include breakpoint (medium down) {
            padding-bottom: rem-calc(20);
        }
    }
    .tt-c-review__date.tt-u-mb--sm {
        width: 100%;
        bottom: 0;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
        letter-spacing: rem-calc(0.2);
        color: $dark-gray;
        margin-bottom: rem-calc(16);
        @media screen and (max-width: 1023px){
            margin-bottom: rem-calc(20);
            margin-top: rem-calc(10);
        }
    }
    .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--3 {
        width: 100%;
        .tt-c-dimension__heading.tt-u-mb--sm {
            .tt-c-dimension__value-label {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                letter-spacing: rem-calc(0.3);
                color: $black;
                padding: rem-calc(8 16);
                font-weight: 500;
                border: 1px solid $light-gray;
                border-radius: rem-calc(20);
            }
        }
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--1.tt-c-review__grid-col--1 {
        width: auto !important;
        margin-right: rem-calc(40)!important;
        @include breakpoint(large up) {
            width: 18.37481698389458vw !important;
        }
        .tt-c-review__heading.tt-u-mb--sm {
            width: 100%;
        }
        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review__byline.tt-u-mb--lg {
            margin-bottom: rem-calc(20);
            max-width: 80%;
        }

        .tt-c-dimension__value-label {
            padding: rem-calc(9 20 8 20);
            border: 1px solid $black;
        }

        .tt-c-dimension__bar-wrap.tt-c-dimension__bar-wrap--range {
            display: none;
        }

        .tt-c-review__heading.tt-u-mb--sm {
            .tt-c-review__heading.tt-u-mb--sm {
                font-weight: normal;
                font-family: 'Poppins', Arial, sans-serif;
                line-height: rem-calc(22);
                font-size: rem-calc(16);
                letter-spacing: rem-calc(0.23);
                color: $black;
                opacity: 1;
            }
        }
    }
    .tt-c-dimension {
        .tt-c-dimension__heading.tt-u-mb--sm {
            margin-bottom: rem-calc(10);

            @media screen and (max-width: 1023px) {
                margin-bottom: rem-calc(9);
            }

            .tt-c-dimension__label, .tt-c-dimension__value-label {
                color: $black;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                font-weight: 400;
                text-transform: capitalize;
            }
            .tt-c-dimension__value-label{
                font-weight: 600;
            }
        }
        .tt-c-dimension__bar-wrap.tt-c-dimension__bar-wrap--range {
            .tt-c-dimension__bar {
                height: rem-calc(15);
                background: #fff 0% 0% no-repeat padding-box;
                order: 1;
                .tt-c-dimension__dot {
                    width: rem-calc(14);
                    height: rem-calc(14);
                    background-color: $black;
                }
                &::after {
                    left: 0;
                    height: rem-calc(2);
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    background-color: $medium-light-gray;
                }
                &:first-child {
                    margin-bottom: rem-calc(5);
                }
            }
            .size-label-container {
                display: none;
                flex-wrap: nowrap;
                gap: rem-calc(8);
                width: 100%;
                order: 1;
                .label-wrapper {
                    width: 50%;
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    .rr-dimension-label {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .label-too-small {
                        text-align: left;
                    }
                    .label-too-large {
                        text-align: right;
                    }
                }
                &:nth-child(3) {
                    display: flex;
                }
            }
            .rr-dimension-label {
                display: block;
            }
        }
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--2.tt-c-review__grid-col--2 {
        flex: 0 1 auto!important;
        @include breakpoint (medium up){
            margin: 0!important;
            max-width: 44.14348462664714vw!important;
            width: 100%!important;
            display: flex;
            flex-direction: column;
        }
        @include breakpoint (xxlarge up) {
            width: 44.14348462664714vw!important;
        }
        .tt-c-review-toolbar.tt-u-mb--xs {
            order: 8;
        }
    }
    .tt-c-rating.tt-c-review__rating.tt-u-mb--sm {
        margin-bottom: rem-calc(16);
        height: rem-calc(12);

        @media screen and (max-width: 1023px) {
            margin-bottom: rem-calc(20);
            margin-top: rem-calc(16);
        }
    }
    .tt-c-review__purchased.tt-u-mb--sm {
        display: none;
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--3.tt-c-review__grid-col--3 {
        margin: 0 !important;
        width: initial!important;
        flex: 1!important;
        min-width: 0;

        @include breakpoint (medium down){
            flex: 1!important;
            width: 100%!important;
        }

        .tt-c-review__purchased.tt-u-mb--sm {
            display: none;
        }
    }
            .tt-c-review-toolbar.tt-u-mb--xs {
                .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
                    .tt-o-button.tt-o-button--toolbar.tt-o-button--flat-icon-left.tt-c-review-toolbar__vote.tt-c-review-toolbar__vote:first-of-type {
                        margin-left: 0;
                    }
                }
            }
    .tt-c-review-toolbar.tt-u-mb--xs {
        .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
            .tt-c-review-toolbar__text {
                display: none;
            }
        }
        .tt-c-review-toolbar__content--right {
            .tt-c-review-toolbar__actions.tt-u-spacing--left--lg {
                display: none
            }
        }
    }
    .tt-o-pagination {
        padding: rem-calc(12 0 0 0);
        margin: rem-calc(0 117 0 101);
        border-top: rem-calc(1) solid #D9D9D9;
        margin-bottom: rem-calc(123);
        display: flex;
        justify-content: flex-end;
        position: relative;

        @media screen and (max-width: 1023px) {
            margin: rem-calc(0 16 108 16);
            justify-content: center;
            margin-top: rem-calc(23);
            position: initial !important;
            display: flex !important;
            padding-top: rem-calc(18);
        }

        .review-pagination-progress {
            position: absolute;
            left: 0;
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.42);
            color: #949494;
            span {
                color: inherit;
                font-size: inherit;
            }
            @media screen and (max-width: 1023px) {
                font-size: rem-calc(10);
                letter-spacing: rem-calc(0.3);
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .tt-o-button.tt-o-button--icon {
            padding: rem-calc(0 4);
            margin: rem-calc(0 8);
            height: rem-calc(16);
            width: rem-calc(16);

            svg {
                fill: #949494;
            }
        }

        .tt-o-pagination__back {
           position: relative;
           left: rem-calc(71);
           top: rem-calc(11);
           z-index: 1023;

           @media screen and (max-width: 1023px) {
                top: rem-calc(48);
                left: rem-calc(39);
            }
        }

        .tt-o-pagination__next {
            position: relative;
            right: rem-calc(7);
            top: rem-calc(11);

            @media screen and (max-width: 1023px) {
                top: rem-calc(48);
                right: rem-calc(39);
            }
        }
        .tt-o-page-list {
            padding: 0;
            border: rem-calc(1) solid #E2E2E2;
            border-radius: rem-calc(36);
            padding: rem-calc(6 36 6 36);
            position: relative;
            left: rem-calc(32);

            @media screen and (max-width: 1023px) {
                margin-top: rem-calc(38);
                position: static;
            }
            .tt-o-page-list__number {
                color: #949494;
                margin: 0;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                &.tt-is-active {
                    color: $black;
                    .tt-o-page-list__link {
                        background-color: initial;
                        border-radius: initial;
                        color: $black;
                    }
                }
                .tt-o-page-list__link {
                    font-family: 'Poppins', Arial, sans-serif;
                    letter-spacing: rem-calc(0.32);
                    font-size: rem-calc(16);
                    line-height: rem-calc(28);
                    color: #949494;
                    padding: rem-calc(0 4 0 4);
                    margin: rem-calc(0 4 0 4);
                }
            }
        }
    }
    .rr-dimension-label {
        display: none;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
        letter-spacing: rem-calc(0.2);
        color: $dark-gray;
    }
}
.tt-c-reviews-list-utilities {
    @include flexGenerator(row, flex-start, center);
    margin: 0;
    @include fixHeight(rem-calc(50));
    width: 100%;
    border-bottom: rem-calc(1) solid #DDDDDD;
    @media screen and (max-width: 1023px) {
        padding-left: rem-calc(0);
        padding-right: rem-calc(0);
    }
    &.hidden {
        display: none!important;
    }
    &-filter {
        &:hover {
            cursor: pointer;
            background-color: #F6F6F5;
        }
        .filterBtn {
            @include fixHeight(rem-calc(50));
            padding: rem-calc(16 18);
            color: currentColor;
        }
    }
    .filterMenu-header {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: rem-calc(16);
        font-size: rem-calc(12);
        > :first-child {
            margin-right: rem-calc(8);
        }
        .icon {
            .small {
                width: rem-calc(10);
                height: rem-calc(6);
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
    .tt-c-reviews-applied-filters {
        display: none;
    }
    .tt-c-reviews-toolbar__sort {
        margin-bottom: initial;
        order: 1;
    }
    .tt-c-reviews-toolbar .tt-o-listbox.tt-c-reviews-toolbar__sort .tt-o-listbox__btn.tt-o-listbox__btn--with-label {
        .tt-o-listbox__btn-label {
            display: none;
        }
    }
    .tt-c-reviews-toolbar {
        margin-bottom: 0;
        order: 2;
        height: 100%;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 1023px) {
          position: relative;
        }

        .tt-c-review-filters {
            margin-bottom: 0;
            order: unset;
            margin-right: rem-calc(7);

            @media screen and (max-width: 1023px) {
                display: flex;
                align-items: center;
            }
            .tt-o-listbox {
                max-height: initial;
                .tt-o-listbox__btn.tt-o-listbox__btn--with-label {
                    @media screen and (max-width: 1023px) {
                        position: relative;
                    }
                    .tt-o-listbox__btn-label {
                        display: none;
                    }
                }
            }
        }
        .tt-c-reviews-toolbar__sort {
            .tt-o-listbox__list {
                .tt-o-listbox__option {
                    padding: rem-calc(8 16 8 16);
                    &:first-child {
                        padding: rem-calc(16 16 8 16);
                    }
                    &:last-child {
                        padding: rem-calc(8 16 16 16);
                    }
                }
            }
        }
    }
    .tt-c-reviews-filters-row {
        margin-bottom: 0;
    }
    ul.tt-o-listbox__list {
        min-width: rem-calc(195)!important;
        padding: 0!important;

        .tt-o-listbox__option {
            .tt-o-listbox__option-text {
                font-size: rem-calc(12);
                padding-top: ren-calc(1);
            }
            .tt-o-listbox__rating-option {
                padding-bottom: rem-calc(1);
                .tt-o-icon {
                    margin-right: rem-calc(2);
                    width: rem-calc(16);
                    height: rem-calc(16);
                    fill: #FF6598;
                }
            }
        }
    }
    .tt-c-reviews-toolbar .tt-o-listbox.tt-c-reviews-toolbar__sort .tt-o-listbox__btn.tt-o-listbox__btn--with-label, .tt-c-review-filters .tt-o-listbox .tt-o-listbox__btn, .tt-o-listbox__btn.tt-o-listbox__btn--with-label {
        padding: rem-calc(9.5 16);
        font-size: rem-calc(12);
        width: initial;
        min-width: initial;
        border: 0;
        border-radius: rem-calc(4);

        &[aria-expanded=true] {
          box-shadow: 0px 3px 6px #00000029;
          border-radius: rem-calc(4 4 0 0);
        .tt-o-icon {
                fill: $black!important;
            }
        .tt-o-listbox__btn-label, .tt-o-listbox__label {
                color: $black;
            }
        }

        &:hover {
            box-shadow: 0px 3px 6px #00000029;
        }

        .tt-o-icon.tt-o-icon--caret-down {
            width: rem-calc(11);
            height: rem-calc(9);
            fill: #666666;
            &.tt-o-listbox__btn-icon--open {
                top: 35%;
            }
        }

        > :first-child {
            margin-right: rem-calc(7);
        }
        .tt-o-listbox__label, .tt-o-listbox__btn-label {
            font-size: rem-calc(12);
            text-transform: inherit;
            margin-right: rem-calc(8);
            white-space: nowrap;
            color: #666666;
        }
        &:hover {
            cursor: pointer;
            font-weight: bold;
            .tt-o-icon {
                fill: $black;
            }
            .tt-o-listbox__btn-label, .tt-o-listbox__label {
                color: $black;
            }
        }
        .icon {
            .small {
                width: rem-calc(10);
                height: rem-calc(6);
            }
        }
    }

    .tt-o-listbox__list.tt-o-listbox__list--has-selection,
    .tt-o-listbox__list {
        border-radius: rem-calc(0 4 4 4) !important;
    }
    .tt-c-review-filters {
        .tt-o-listbox {
            margin-bottom: 0;
        }
    }
    .btn {
        position: relative;
        height: 100%;
        .icon {
            max-width: rem-calc(20);
            max-height: rem-calc(20);
            &.filter {
                padding: rem-calc(3.5 2.5);
                box-sizing: border-box;
            }
            &.arrow {
                padding: rem-calc(2 0);
            }
        }
        .text {
            font-size: rem-calc(12);
            letter-spacing: 0.2px;
            line-height: rem-calc(20);
        }
        .under {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 14px;
            padding-bottom: rem-calc(3);
            border-bottom: 1px solid $black;
            text-transform: capitalize;
        }
    }
    .circleNumberLabel {
        border-radius: 50%;
        border: 1px solid #C75015;
        color: #C75015;
        display: inline-block;
        font-size: rem-calc(11);
        height: rem-calc(20);
        letter-spacing: 0.2px;
        line-height: rem-calc(18);
        margin-left: rem-calc(7);
        text-align: center;
        vertical-align: top;
        width: rem-calc(20);
    }
    .grid-mode-toggle a {
        color: #CCCCCC;
        text-decoration: none;
        transition: color 0.25s ease;
        &:hover,
        &.active {
            color: $black;
        }
        &+a {
            margin-left: rem-calc(10);
        }
    }
}

.flcActiveFilter {
    @include flexGenerator(row, space-between, center);
    background-color: #F6F6F5;
    padding: rem-calc(0 40);
    &-refinement {
        @include flexGenerator(row);
        flex-wrap: wrap;
        flex-basis: min-content;
    }
    &-active {
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 12px;
        margin-right: rem-calc(5);
        overflow: hidden;
        padding: rem-calc(19 0);
        white-space: nowrap;
        width: min-content;
        .icon.close {
            height: rem-calc(20);
            margin-left: rem-calc(5);
            margin-right: rem-calc(35);
            width: rem-calc(20);
        }
    }
    &-removeAll {
        border-bottom: 1px solid $black;
        min-width: min-content;
        overflow: hidden;
        padding-bottom: rem-calc(3);
        white-space: nowrap;
        cursor: pointer;
    }
}
.flcSortContainer {
    padding: rem-calc(16 18);
    &:hover {
        cursor: pointer;
        background-color: #F6F6F5;
    }
}
.review-summary-pdp {
    display: none;
    margin-bottom: 0;
    margin-top: 0;
    width: min-content;
    &.loaded {
        display: flex;
        align-items: center;
        padding: 0;
        margin-top: rem-calc(3);
        margin-bottom: rem-calc(16);
        @include breakpoint (medium down) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 1023px) {
        padding: rem-calc(0 14);
    }
    &:hover {
        cursor: pointer;
        .total-reviews {
            text-decoration: underline;
        }
    }
    .tt-c-rating {
        margin-bottom: 0;
        margin-right: rem-calc(12);
        .tt-c-rating__star {
            width: rem-calc(22);
            height: rem-calc(22);
            margin-right: rem-calc(6);

            .tt-o-icon {
                width: 100%;
                height: 100%;
                fill: $black;
                stroke-width: rem-calc(1.5);
            }

            .tt-o-icon--star--half {
                color: #fff;
                stroke: $black;
            }
        }
    }
    .total-reviews {
        font-size: rem-calc(12);
        line-height: normal;
        letter-spacing: rem-calc(0.24);
        font-weight: 600;
        color: $black;
        margin-bottom: 0;
        margin: rem-calc(0 12);
        margin-left: 0;
        width: max-content;

        @include breakpoint(small down) {
            text-decoration: underline;
            margin-left: 0;
        }
    }
    &-mobile {
        margin: rem-calc(0 40 30 40);
        @media screen and (max-width: 1023px) {
            margin: rem-calc(2 0 19 0);
        }
    }
}

.tt-c-review-responses.tt-u-mb--lg {
    margin: rem-calc(14.5 0 0 0);
    order: 9;
    @media screen and (max-width: 1023px) {
        margin: rem-calc(-1 0 0 0);
        order: 7;
    }

}

.tt-c-review-responses__item {
    margin-bottom: 0;
}

.tt-c-review-responses__source-wrap.tt-u-mb--lg {
    border: none;
    background-color: #F2EDED;
    padding: rem-calc(24 16);
    margin: 0;

    .tt-c-review-responses__source.tt-u-mb--sm {
        margin-bottom: rem-calc(8);
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        font-weight: 600;
    }

    .tt-c-review-responses__responses.tt-u-mb--md {
        margin: 0;

        .tt-c-review-responses__item.tt-u-mb--lg {
            margin: 0;
        }

        .tt-c-review-responses__text.tt-u-mb--md {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(14);
            line-height: rem-calc(16);
        }

        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review-responses__byline {
            .tt-o-byline__item.tt-o-byline__author {
                display: none;
            }

            .tt-o-byline__item.tt-o-byline__on-date {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                margin: 0;
                color: $black;
            }
        }
    }
}

.tt-l-grid.tt-c-reviews-summary__content {
    .tt-c-rating__star {
        width: rem-calc(22);
        height: rem-calc(22);
        margin-right: rem-calc(6);

        svg {
            width: 100%;
            height: 100%;
            fill: $black;
            stroke-width: rem-calc(1.5);
        }

        .tt-o-icon--star--half {
            color: #fff;
            stroke: $black;
        }
    }
}
.tt-o-icon.tt-o-icon--helpful-off.tt-o-icon--sm.tt-c-review-toolbar__icon.tt-c-review-toolbar__icon,
.tt-o-icon.tt-o-icon--unhelpful-off.tt-o-icon--sm.tt-c-review-toolbar__icon.tt-c-review-toolbar__icon {
    margin-right: rem-calc(8)!important;
}
.tt-o-button.tt-o-button--toolbar.tt-o-button--flat-icon-left.tt-c-review-toolbar__vote.tt-c-review-toolbar__vote {
   margin-right: rem-calc(0);
}

.tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--1.tt-c-review__grid-col--1 {
   .tt-c-dimension__label {
    display: none;
   }
}

.tt-c-reviews-list-utilities.grid-container {
    display: none;
    max-height: none;
    height: rem-calc(82);
    border-bottom: rem-calc(1) solid #D9D9D9;
    padding-left: 7.3%;
    padding-right: 8.5%;

    @media screen and (max-width: 1023px) {
        padding: rem-calc(0 16 0 16);
        height: rem-calc(77);
    }

}

.tt-c-review-responses.tt-u-mb--lg {
    margin: rem-calc(24.5 0 0 0);

    @media screen and (max-width: 1023px) {
        margin: rem-calc(20.5 0 0 0);
        order: 7;
    }

}

.tt-c-review-responses__item {
    margin-bottom: 0;
}

.tt-c-review-responses__source-wrap.tt-u-mb--lg {
    border: none;
    background-color: #F2F3ED;
    padding: rem-calc(20);
    margin: 0;

    @media screen and (max-width: 1023px) {
        padding: rem-calc(20);
    }

    .tt-c-review-responses__source.tt-u-mb--sm {
        margin-bottom: rem-calc(16);
        font-size: rem-calc(14);
        line-height: rem-calc(25);
        letter-spacing: rem-calc(0.35);
        font-weight: 500;
    }

    .tt-c-review-responses__responses.tt-u-mb--md {
        margin: 0;

        .tt-c-review-responses__item.tt-u-mb--lg {
            margin: 0;
        }

        .tt-c-review-responses__text.tt-u-mb--md {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(14);
            line-height: rem-calc(25);
            letter-spacing: rem-calc(0.35);
        }

        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review-responses__byline {
            .tt-o-byline__item.tt-o-byline__author {
                display: none;
            }

            .tt-o-byline__item.tt-o-byline__on-date {
                font-size: rem-calc(12);
                line-height: rem-calc(25);
                letter-spacing: rem-calc(0.3);
                font-weight: 500;
                margin: 0;
                color: $black;
                text-transform: capitalize;
            }
        }
    }
}
