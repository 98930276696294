.delivery-and-return-modal {
    box-shadow: none;
    border-radius: 0;

    @include breakpoint(small down){
        margin: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .modal__closeIcon {
        display: block;
        top: rem-calc(34);

        @include breakpoint(small down){
            top: rem-calc(14);
            right: 0;
            margin-right: rem-calc(16);
        }
    }

    .tingle-modal-box__content {
        padding: rem-calc(40);

        @include breakpoint(small down){
            padding: rem-calc(19 16);
        }
    }
    h4 {
        font-size: rem-calc(18);
        line-height: rem-calc(27);
        font-weight: 500;
        margin-bottom: rem-calc(16);
    }
    a {
        text-decoration: underline;
    }
    p, a, span, li {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        font-weight: $global-weight-normal;
        margin-bottom: 0;
    }

    .shipping-recap {
        border-top: rem-calc(1) solid $light-gray;
        border-bottom: rem-calc(1) solid $light-gray;
        padding: rem-calc(20 0);
        margin: rem-calc(20 0);

        @include breakpoint(small down){
            border: 0;
            margin-bottom: rem-calc(4);
        }
    }

    .shipping-recap-item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: rem-calc(8);

        @include breakpoint(small down){
            border-bottom: rem-calc(1) solid $light-gray;
            padding-bottom: rem-calc(16);
            margin-bottom: rem-calc(16);
        }
    }

    .shipping-type__title {
        font-weight: 600;
    }

    .shipping-cost {
        a {
            text-decoration: none;
        }
    }

    .return-content-asset {
        ul {
            margin-top: rem-calc(13);
        }
    }
}

html[lang="pt"],[lang="pt-PT"] {
    .tingle-modal-box.delivery-and-return-modal {
        width: rem-calc(900);
        max-width: rem-calc(900);
    }
}