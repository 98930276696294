.pd-scrolling-text {
    visibility: hidden;
    display: flex;
    padding: rem-calc(3 0);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(150px);
    transition: all 0.3s ease;
    color: $sustainability;
    border-radius: rem-calc(4 4 0 0);

    @keyframes scrollingtext {
        from {
            -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0)
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0)
        }
    }

    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &__content-text {
        display: inline-block;
        position: relative;
        text-decoration: none;

        &-p {
            display: inline-flex;
            align-items: center;
            box-sizing: content-box;
            animation: scrollingtext 10s linear -1s infinite forwards;
            position: relative;
            white-space: nowrap;
            margin: 0;
            padding-right: rem-calc(8);
            font-weight: 500;
            font-size: rem-calc(12);
            line-height: rem-calc(18);

            @include breakpoint (small down) {
                font-size: rem-calc(10);
                line-height: rem-calc(10);
            }
        }
    }
}

