@import 'swatch';

.product {
    &-id {
        display: inline-block;
        margin-bottom: rem-calc(8);
    }
}

.modal {
  display: none;
  width: 100%;
  height: 200vh;
  background-Repeat: no-repeat;
  z-Index: 2000;
  position: fixed;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: zoom-out;
  overflow: hidden !important;
  background-color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: zoom-in;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: fixed;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color:black;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 0px solid transparent;
}

/* Position the "next button" to the right */
.next {
  right: 352px;
  border-radius: 3px 0 0 3px;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.detail-view{
    background-repeat:no-repeat;
    cursor:zoom-in;
}

.productImageCarouselItem{
  cursor:zoom-in;
}

  @media only screen AND (max-width: 1025px) {
    .detail-view {
      display: none;
    }
  }

  .zoom{
    display: flex;
  }


.bonus-modal--tezenis{
  .tingle-modal-box{
    height: 100%;

    &.rightModalAttribute {
        position: absolute;
        right: 0;
        min-height: 100% !important;
        width: 100%;
        max-width: rem-calc(459);
        border-radius: 0;

        &::-webkit-scrollbar {
          scrollbar-width: none;
        }

        @include breakpoint(medium down) {
            min-width: 100vw !important;
        }

        .panel-container-bonus-attributes{
          .accordion-panel-group{
            margin: rem-calc(5 0);
            &::-webkit-scrollbar {
              width: rem-calc(3);
              border-radius: (20);
            }
            &::-webkit-scrollbar-track {
              background:  rgba(0, 0, 0, 0.2);
              border-radius: rem-calc(20);
              margin: rem-calc(5 0);
            }
            &::-webkit-scrollbar-thumb {
              background: #646464;
              border-radius: rem-calc(20);
            }
          }
        }
    }
    .tingle-modal-box__content{
      padding: 0;
      height: 100%;

      > div {
        height: 100%;

        > .modal-content {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        }
      }

      .modal-header{
        display: flex;
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        z-index: 1;
        background: white;
        padding: rem-calc(24 40 24 24);
        @include breakpoint (medium down){
          padding: rem-calc(24 16);
        }
        h4{
          font-size: rem-calc(14);
          font-weight: 600;
          margin-bottom: 0;
        }
        .close-custom{
          top: rem-calc(21);
          right: rem-calc(40);
          position: absolute;
          z-index: 1;
          width: rem-calc(24);
          height: rem-calc(24);
          background-image: url("../images/close-button.svg");
          @include breakpoint (medium down){
            top: rem-calc(20);
            right: rem-calc(16);
          }
        }
        .cc-back-custom{
          z-index: 1;
          width: rem-calc(24);
          height: rem-calc(24);
          background-image: url("../images/arrow-left-bold.svg");
          margin-right: rem-calc(16);
          @include breakpoint (medium down){
            top: rem-calc(20);
            right: rem-calc(16);
            width: rem-calc(20);
            height: rem-calc(20);
          }
          cursor: pointer;
        }
      }
      .modal-content{
        width: 100%;
        max-width: unset;

        .modal-footer-button {
          padding: rem-calc(16);
          border-top: rem-calc(1) solid $light-gray;

          @include breakpoint (large up) {
            padding: rem-calc(24 40 24 24);
          }

          .button {
            margin: 0;
            width: 100%;
          }
        }

        .modal-body{
          padding: rem-calc(0 16);
          flex: 1;
          position: relative;

          @include breakpoint (large up) {
            padding: rem-calc(0 24);
          }

          .product-quickview-header {
            display: none;
          }

          .bonus-product-item {
            .product-wrapper {
              padding: rem-calc(16 0);
              border-bottom: rem-calc(1) solid $light-gray;

              &.product-quickview-bonus-product {
                padding-bottom: rem-calc(86);
                border-bottom: none;

                @include breakpoint (large up) {
                  padding-bottom: rem-calc(102);
                }
              }
            }

            &:last-child {
              border-bottom: none;
            }

            .bonus-product-price {
              margin-bottom: rem-calc(4);
            }

            .primary-images{
              width: rem-calc(77);
              .carousel{
                width: 100%;
                margin: 0;
                .carousel-item{
                  margin-bottom: 0;
                  img{
                    border-radius: rem-calc(4);
                  }
                }
              }
            }
          }
        }
      }

      .bonus-boolean-field {
        padding-left: rem-calc(24);
        margin: 0;
        align-self: center;
        height: rem-calc(19);
        padding-right: 0;

        @include breakpoint (large up) {
          padding-right: rem-calc(18);
        }

        .bonus-product-radio {
          margin: 0;
        }

        .radio-input {
          margin: 0;
        }
      }
      .free-product-pill{
        span{
          font-size: rem-calc(10);
          font-weight: 500;
          line-height: rem-calc(10);
          padding: rem-calc(4 12);
          background: $water-green-light;
          border-radius: rem-calc(4);
          border: none;
          color: $black;
        }
      }
      .product-name{
        font-size: rem-calc(14);
        font-weight: 400;
        margin: rem-calc(6 0 4 0);
        @include breakpoint (medium down){
          padding-top: 0;
        }
      }
      .m-d-price-container{
        font-size: rem-calc(14);
        font-weight: 500;
      }
      .button-tezenis-bonus{
        border-radius: rem-calc(40);
        padding: rem-calc(10 30);
        margin: 0;
        margin-bottom: rem-calc(10);
        border-bottom: 0;

      }
      .info-bonus-cell{
        padding-left: rem-calc(24);
        display: flex;
        flex-direction: column;
      }
      .colors-size-bonus-button{
        margin-top: rem-calc(24);
        width: 100%;
      }
      .button-add-bonus-box{
        border-bottom: 1px solid $light-gray;
        .tezenis-button-add-bonus{
          width: 100%;
          border-radius: rem-calc(40);
          border: none;
          margin: rem-calc(40 0);
        }
      }
      .accordion-panel-group{
        @include breakpoint (medium down){
          width: auto;
        }
      }
      .carousel{
        &.slide{
          @include breakpoint (medium down){
            margin-bottom: 0;
            img{
              margin-top: 0;
            }
          }
        }
      }
      .attribute-button{
        border: none;
        align-self: center;
        display: flex;
        padding: rem-calc(0 0 8 0);
        text-transform: capitalize;
        font-weight: 400;
        margin-right: rem-calc(0);
        position: relative;
        margin-top: rem-calc(27);
        border-bottom: 1px solid $medium-gray;
        color: $black;
        font-size: rem-calc(14);
        &.open{
          z-index: 1;
          .triangle-down{
            border-color: white;
          }
        }
        .product-selection-label-color, .product-selection-name-color, .product-selection-label-size, .product-selection-name-size {
          color: $medium-gray;
        }
        .attribute-selection-color-description{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
          .arrow-down {
              position: absolute;
              top: rem-calc(4);
              right: 0;
              display: inline-block;
              width: rem-calc(16);
            &.rotate {
                transform: translateY(4px) scale(0.2) rotate(-132deg);
            }
        }
        .attribute-selection{
          margin-left: rem-calc(3);
          margin-right: rem-calc(3);
          font-weight: 400;
          span{
            &:not(.swatch-circle):not(.radio-label){
              display: none;
            }
          }
        }

        &.open {
          .arrow-down {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .attribute-bonus-cell{
    .attribute{
      border: rem-calc(1) solid $water-green;
      padding-right: rem-calc(3.5);
      background: $white;
    }
    .panel-container-bonus-attributes{
      z-index: 1;
      position: absolute;
      bottom: rem-calc(1);
      border-radius: rem-calc(8);
      left: 0;
      right: 0;
      height: 0;
      background: $white;
      &::-webkit-scrollbar {
        width: rem-calc(3);
      }
      &::-webkit-scrollbar-track {
        background:  rgba(0, 0, 0, 0.2);
        border-radius: rem-calc(20);
        margin: rem-calc(5 0);
      }
      &::-webkit-scrollbar-thumb {
        background: #646464;
        border-radius: rem-calc(20);
      }

      .accordion-panel-group{
        border-radius: 0;
        text-align: center;
        background: $white;
        max-height: rem-calc(185);
        overflow: auto;

        .bonus-color-to-select{
          text-align: center;
          font-size: rem-calc(12);
          margin-bottom: rem-calc(16);
        }
        label{
          &.swatch-boolean{
            padding: rem-calc(8);
            width: 100%;
            margin: 0;
            text-align: left;
            @include breakpoint (medium down){
              padding-right: rem-calc(12);
            }
            .radio-label {
              width: 0;
              height: 0;
              line-height: rem-calc(21);
              font-size: rem-calc(14);
              font-weight: 400;
              border: none;
              margin: 0;
              padding: 0;
            }
          }
        }
        .bonus-size-to-select{
          text-align: center;
          font-size: rem-calc(12);
          margin-bottom: rem-calc(16);
        }
        .bonus-color-description-selected{
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          margin-bottom: 16px;
          .family-color, .attribute-label{
            letter-spacing: 0;
            font-weight: 600;
          }
        }
        .bonus-size-description-selected{
          text-align: center;
          font-size: rem-calc(12);
          margin-bottom: rem-calc(16);
          @include breakpoint (medium down){
            margin-bottom: rem-calc(16);
          }
          .attribute-label{
            letter-spacing: 0;
            font-weight: 600;
          }
        }
        .variation-color-box{
          padding: rem-calc(8);
          .color-variation {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            font-weight: 400;
            &.selected{
              background-color: $water-green-light;
              border-radius: 0;
              margin: rem-calc(-8);
              padding: rem-calc(8);
            }
            img {
              display: none;
            }
            .swatch-circle {
              width: rem-calc(16);
              height: rem-calc(16);
              margin: rem-calc(0 8 0 0);
            }
            .swatch-circle.selected ~ img {
                display: block;
                position: absolute;
                top: rem-calc(-7);
                right: rem-calc(-2);
                width: rem-calc(13);
                height: rem-calc(13);
            }
          }
        }
        .swatch-boolean {
          &:last-of-type {
              margin-right: 0;
          }
          img {
              display: none;
          }
          input[type="radio"].clicked-size+.radio-label ,
          input[type="radio"]:checked+.radio-label {
              text-decoration: none;
              background-color: $water-green-light;
              width: calc(100% + 16px);
              height: auto;
              border: none;
              border-radius: 0;
              text-align: left;
              padding: rem-calc(8);
              margin: rem-calc(-8);
              @include breakpoint (small down){
                width: calc(100% + 20px);
              }

              & ~ img {
                  display: block;
                  position: absolute;
                  top: rem-calc(17);
                  right: rem-calc(13);
                  width: rem-calc(13);
                  height: rem-calc(13);
                  @include breakpoint (small down){
                    top: rem-calc(0);
                    right: rem-calc(11);
                  }
              }
          }
        }
      }
    }
  }
}

.free-gift-button-newstyle{
  @include breakpoint (ipad down) {
    background: $water-green-light;
  }
  .box-product-info {
    @include breakpoint (ipad down) {
      margin-left: 0;
      .button-wrapper {
        margin-top: rem-calc(16);
        .button {
          width: 100%;
        }
      }
    }
    .title {
      display: block;
      font-size: rem-calc(14);
      font-weight: 600;
    }
    .description {
      font-size: rem-calc(12);
    }
    .title, .description {
      letter-spacing: rem-calc(0.2);
      margin-bottom: rem-calc(8);
    }
    .button-wrapper {
      .button {
        margin-bottom: 0;
      }
    }
  }
}

//Remove product from shopping bag modal
.cart-remove-product{
  .tingle-modal-box{
    border-radius: rem-calc(5);
    max-width: rem-calc(450);
    @include breakpoint(small down){
      width: 90%;
    }
    &__content{
      padding: rem-calc(30 32 43);
      .modal-content{
        margin: 0;
        width: 100%;
        .modal-header,
        .modal-body{
          .modal-title,
          .modal-body-text{
            margin-bottom: rem-calc(32);
          }
        }
        .modal-header{
          width: 85%;
          font-weight: $global-weight-dark;
          .modal-title{
            line-height: rem-calc(24);
          }
          .modal-body-text{
            line-height: rem-calc(20);
          }
        }
      }
      .tingle-modal__close{
        display: block;
        &Icon{
          position: absolute;
          top: rem-calc(30);
          right: rem-calc(32);
          font-size: rem-calc(24);
          line-height: rem-calc(27);
          cursor: pointer;
        }
      }
    }
  }
}
.cart-remove-all-products {
  .tingle-modal-box{
    max-width: rem-calc(635);
    @include breakpoint(small down){
      width: 90%;
    }
    &__content{
      padding: rem-calc(40);
      @include breakpoint (ipad down) {
        padding: rem-calc(24);
      }
      .modal-content{
        margin: 0;
        width: 100%;
        .modal-header,
        .modal-body .modal-body-text{
          font-size: rem-calc(14);
          letter-spacing: rem-calc(0.2);
          margin-bottom: rem-calc(24);
          @include breakpoint (ipad down) {
            margin-bottom: rem-calc(24);
          }
        }
        .modal-header{
          width: 85%;
          .modal-title{
            line-height: rem-calc(24);
          }
          .modal-body-text{
            line-height: rem-calc(20);
          }
        }
      }
      .tingle-modal__close{
        display: block;
        margin-right: 0;
        &Icon{
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 1;
        }
      }
      .remove-all-products {
        width: 100%;
        height: auto;
        margin-top: rem-calc(8);
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        padding: rem-calc(16);
        @include breakpoint (ipad down) {
          margin-top: rem-calc(0);
        }
      }
      .back-to-cart {
        font-size: rem-calc(14);
        font-weight: 500;
        text-align: center;
        letter-spacing: rem-calc(0.2);
        margin: rem-calc(16 0 0);
        width: 100%;
        line-height: rem-calc(19);
        padding: rem-calc(16);
        height: auto;
        @include breakpoint (ipad up) {
          color: black;
          background-color: initial;
          font-weight: 400;
          text-decoration: underline;
          text-underline-offset: rem-calc(6);
          line-height: rem-calc(21);
          padding: 0;
          border: 0;
          margin-bottom: rem-calc(4);
        }
      }
    }
  }
}

.bonus-product-line-item,
.mini-product-item {
  .free-product-pill {
    span.promo-pill {
      font-size: rem-calc(10);
      font-weight: 500;
      line-height: rem-calc(10);
      padding: rem-calc(4 12);
      background: $water-green-light;
      border-radius: rem-calc(4);
      border: none;
      color: $black;
    }
  }
}

.bonus-product-summary .mini-product-item {
  padding: rem-calc(16 16 24);

  @include breakpoint(large up) {
    padding: rem-calc(16 40 24 24);
  }

  .product-image {
    border-radius: rem-calc(4);
  }
}
