.made-in-modal {
    min-width: unset;
    @include breakpoint (medium up){
        min-width: rem-calc(980);
    }
    .tingle-modal-box__content .close {
        top: rem-calc(23);

        @include breakpoint(small down){
            top: rem-calc(21);
        }
    }
    .production-chain-container,
    .fabric-content {
        display: block;

        .ca-traceabilityBanner{
            &-videoContainer{
                &-video{
                    height: 24.61vh;
                    width: 100%;
                    object-fit: fill;
                }
            }
        }
        .fabric-img-container {
            & > img {
                height: 100%;
                object-fit: fill;
            }

            @include breakpoint(small down){
                display: none;
            }
        }

        .production-chain-cell,
        .fabric-description-cell {
            margin: 0;
            padding: rem-calc(0 4 40 40);

            @include breakpoint(small down){
                padding: rem-calc(24);
            }

            .fabric-title {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                font-weight: 600;

                @include breakpoint(small down){
                    margin-bottom: rem-calc(24);
                }
            }
            .production-chain-title,
            .fabric-description {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                margin-bottom: rem-calc(168);

                @include breakpoint(small down){
                    margin-bottom: rem-calc(10);
                }
            }
            .production-chain-title{
                font-weight: 600;
            }
        }
    }
    .ca-traceabilityModal{
        &-productionChain{
            &-description{
                line-height: normal;
                letter-spacing: rem-calc(0.2);
                margin-bottom: rem-calc(10);
                @include breakpoint (medium up){
                    margin-bottom: rem-calc(16);
                }
            }
            &-place{
                margin-bottom: rem-calc(10);
                @include breakpoint (medium up){
                    margin-bottom: rem-calc(8);
                }
                &__highlighted{
                    font-weight: 500;
                }
            }
            &-operationCategory{
                margin-bottom: rem-calc(24);
                @include breakpoint (medium up){
                    margin-bottom: rem-calc(40);
                }
                &__highlighted{
                    font-weight: 500;
                }
            }
            &-button{
                min-width: rem-calc(158);
                width: auto;
                @include breakpoint (medium up){
                    min-width: rem-calc(155);
                    margin: rem-calc(0 0 0 -10);
                }
            }
        }
    }

}
