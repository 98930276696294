@import "fontello";

[class*=" icon-"]:after,
[class*=" icon-"]:before,
[class^=icon-]:after,
[class^=icon-]:before {
    font-size: 1.5rem;

    &.fe-lg {
        font-size: 1.75rem;
    }

    &.fe-2x {
        font-size: 2rem;
    }

    &.fe-3x {
        font-size: 3rem;
    }
}

.icon-shipping {
    &:before,
    &:after {
        font-size: 1.25rem;
    }
}
