.findinstore, .pickuppoint {
    z-index: 1010 !important;
    &-wrapper {
        width: 96vw;
        padding: rem-calc(32);
        position: relative;

        input[name="query"] {
            // use pxs to join with next border
            margin-right: -1px;
        }
    }

    &-close {
        position: absolute;
        top: rem-calc(12);
        right: rem-calc(16);
        // override tingle styles
        margin-right: 0 !important;
        line-height: 1;
        font-weight: $global-weight-normal;
        cursor: pointer;
        z-index: 1;

        @include breakpoint (medium up) {
            right: rem-calc(32);
        }
        &.close-custom{
            position: absolute;
            top: rem-calc(24);
            right: rem-calc(40);
            font-size: 0;
            cursor: pointer;
            @include breakpoint (medium down) {
                top: rem-calc(16);
                right: rem-calc(16);
            }
        }
    }

    &-checkout {
        z-index: 1021 !important;

        .button.rounded {
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(10);
        }

        .findinstore-wrapper,
        .pickuppoint-wrapper {
            &.tingle-modal-box{
                max-width: 100vw;
                padding: 0;

                @include breakpoint (small down) {
                    height: 100%;
                }
            }
            .box-modal-right{
                padding: rem-calc(24 40 24 24);
                position: relative;
                height: 100vh;
                @include breakpoint (small down) {
                    height: auto;
                }
                @include breakpoint (ipad down) {
                    position: unset;
                }
                @include breakpoint (medium down) {
                    padding: rem-calc(19 16 0 16);
                }
                h4 {
                    font-weight: 500;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    padding-bottom: rem-calc(24);
                    @include breakpoint (medium down) {
                        font-size: rem-calc(14);
                        line-height: rem-calc(21);
                        padding-bottom: rem-calc(16);
                    }
                }
                .modal-content{
                    position: unset;
                    background: none;
                    .scrollbar-wrapper{
                        position: unset;
                    }
                }
            }
        }

        .modal-header {
            position: sticky;
            top: 0;
            padding: rem-calc(24);
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            margin: rem-calc(0 -24 16 -24);
            background-color: $white;
            z-index: 1;

            @include breakpoint (medium down) {
                padding: rem-calc(18 16);
                margin: rem-calc(0 -16 16 -16);
            }

            h4 {
                font-weight: 500;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                letter-spacing: rem-calc(0.2);
                color: #1c1c1c;

                @include breakpoint (medium down) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                }
            }

            .modal-close {
                position: unset;
                line-height: 0;
            }
        }

        .modal-content {
            width: unset;
            #findinstore-form,
            .js-pup-summary {
                .label-inside {
                    margin-bottom: rem-calc(24);
                }

                .input-group {
                    position: relative;
                    @include breakpoint (large up) {
                        margin-bottom: rem-calc(28);
                    }

                    &__icon {
                        position: absolute;
                        width: rem-calc(15);
                        top: 50%;
                        transform: translateY(-50%);
                        left: rem-calc(16);
                    }

                    &-field {
                        &:focus {
                            &::placeholder {
                                visibility: hidden;
                            }
                        }
                    }
                }
                .input-text-search-city {
                    background-color: #F2F4ED;
                    padding: rem-calc(16 16 16 42);
                    height: rem-calc(56);
                    font-size: rem-calc(16);
                    border: 0;
                    border-bottom: 1px solid $black;
                    border-top-left-radius: rem-calc(3);
                    border-top-right-radius: rem-calc(3);
                    font-weight: 400;
                    &:focus{
                        outline: none;
                        border-bottom: 2px solid #FF6699 !important;;
                    }
                }
                .usecurrentposition-link {
                    margin-bottom: rem-calc(16);
                    display: inline-block;
                    border: none;
                    @include breakpoint (ipad down){
                        margin-bottom: rem-calc(28);
                    }

                    span {
                        font-weight: 400;
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(0.2);
                        color: #1c1c1c;
                        border-bottom: 1px solid #1c1c1c;
                    }
                }
            }
            .js-store-total,
            .js-pup-total {
                font-size: rem-calc(12);
                margin-bottom: rem-calc(16);
                @include breakpoint (medium down) {
                    margin-bottom: rem-calc(19);
                }

            }

            .store-locator-nearby-box{
                display: none;
            }
            .store-locator-nearby-button{
                .storeLocatorNearbyList{
                    border-right: 1px solid $light-gray;
                    padding: rem-calc(0 16);
                    cursor: pointer;
                }
                .storeLocatorNearbyMap{
                    padding-left: rem-calc(16);
                    cursor: pointer;
                    img {
                        margin-right: 0;
                    }
                }
                .change-opacity{
                    opacity: .3;
                }
            }
        }

        .findinstore-results,
        .pickuppoint-results{
            @include breakpoint (large up){
                position: unset;
                height: calc(100vh - 216px);
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: rem-calc(16);
                margin-right: rem-calc(-16);
                margin-bottom: rem-calc(85);
                &::-webkit-scrollbar {
                    border-radius: rem-calc(1.5);
                    height: rem-calc(3);
                    width: rem-calc(3);
                }

                &::-webkit-scrollbar-thumb {
                    background: $black;
                }

                &::-webkit-scrollbar-track {
                    background: $light-gray;
                }
            }
            .result {
                padding: rem-calc(16 0);
                border-radius: 0;
                margin-bottom: rem-calc(16);
                border: none;

                @include breakpoint (medium down) {
                    padding: rem-calc(16 8);
                    margin-bottom: rem-calc(8);
                }

                .store-result-marker{
                    margin-right: rem-calc(16);
                }

                .store-details {

                    .store-name {
                        font-size: rem-calc(14);
                        line-height: rem-calc(21);
                    }
                }

                .store-hours-cell {
                    margin-bottom: rem-calc(8);
                    .store-hours-list{
                        list-style: none;
                        padding: rem-calc(10 0 0 16);
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        & li {
                            margin-bottom: rem-calc(3);
                        }
                    }
                }

                .store-hours-current {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);

                    span {
                        font-weight: 600;
                    }

                    .store-hours-open,
                    .store-hours-closed,
                    .store-hours-temporarily-closed {
                        &:after {
                            content: ' - ';
                            font-weight: 400;
                            color: #1c1c1c;
                        }
                    }

                    .store-hours-open {
                        color: $water-green;
                    }
                }

                .icon-phone {
                    display: none;
                }

                .js-store-phone {
                    font-weight: 400;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    border-bottom: 1px solid #1c1c1c;
                }

                .fi-show-on-map-inpost {
                    font-weight: 400;
                    border-width: 1px;
                    padding-bottom: 0;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    @include breakpoint (medium down) {
                        font-size: rem-calc(12);
                    }
                }

                .js-choose-store, .chose-pickupPoint-button {
                    margin: 0;
                }
            }
            .view-more-button {
                margin-bottom: 0;
                &-cell {
                    padding: rem-calc(24 40 24 24);
                    border-top: 0;
                    background: $white;
                    @include breakpoint (ipad down) {
                        width: 100vw;
                        margin-left: rem-calc(-16);
                        padding: rem-calc(16);
                        background-color: #fff;
                    }
                    &.view-more-map-open{
                        position: absolute;
                        margin-left: rem-calc(-16);

                        @include breakpoint (small down) {
                            bottom: 0;
                        }
                    }
                    &.view-more-map-close{
                        position: sticky;
                        margin-left: rem-calc(-16);
                    }
                }
            }
        }
        .pickuppoint-results {
            @include breakpoint (large up){
                height: calc(100vh - #{rem-calc(350)});
                margin-bottom: 0;
            }
            @include breakpoint (ipad down){
                height: auto !important;
            }
            img {
                margin-right: rem-calc(16);
            }
            .view-more-button-cell {
                button{
                    margin-bottom: 0;
                }
            }
        }
    }


    #findinstore-usecurrentposition {
        font-weight: $global-weight-normal;
        padding-bottom: rem-calc(1);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
    }

    .input-group {
        &.has-loader {
            transition: padding 0.3s ease;

            &.loading {
                padding-right: rem-calc(42);
            }
        }
    }

    .find-in-store {
        display: none;

        @include breakpoint (medium up) {
            display: block;
        }
    }

    // Google's map
    #map {
        min-height: calc(100vh - 190px);
        background-color: $light-gray;

        @include breakpoint (large up) {
            display: block;
            height: 100vh;
        }

        &.map-canvas-map-open{
            min-height: calc(100vh - 340px);
        }

        .store-hours {
            &-open {
                font-weight: bold;
                color: #67BE7A;
            }

            &-closed {
                font-weight: bold;
                color: #C7383C;
            }
        }

        .gm-style .gm-style-iw-c {
            border: 1px solid $water-green;
            padding: rem-calc(16);
            width: rem-calc(306);
            height: auto;
            max-width: none!important; // remove inline style

            @include breakpoint (medium down) {
                width: rem-calc(343);
                border-color: $black;
            }

            .gm-style-iw-d {
                padding: 0;
                overflow: scroll;

                & > div {
                    & > *:not(.primary-info) {
                        margin-left: rem-calc(37);
                    }
                }
            }

            .primary-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: rem-calc(8);

                & > div {
                    display: flex;
                    flex-direction: row;
                }
            }

            h6 {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: 600;
                margin-right: rem-calc(8);
                margin-bottom: 0;
            }

            .pin-icon {
                width: rem-calc(20);
                height: rem-calc(20);
                margin-right: rem-calc(16);
            }

            p, span {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }

            p {
                margin-bottom: rem-calc(8);
            }

            .text-brand-green {
                color: $success;
            }

            .store-hours-open {
                display: inline-block;
                margin-top: rem-calc(8);
                color: $black;
            }

            .show-details {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: rem-calc(2);
                cursor: pointer;
            }
        }

        .gm-style-iw-tc {
            display: none;
        }
    }

    &:not(.checkout-review) {
        .scrollbar-wrapper {
            @include breakpoint(large up) {
                height: calc(100% - 335px);
                overflow: hidden;
                position: relative;
                width: 100%;
            }

            &.full-height {
                @include breakpoint(large up) {
                    height: calc(100% - 247px);
                }
            }
        }
    }

    &-results {
        &:not(.results-checkout) {
            @include breakpoint(large up) {
                position: absolute;
                left: 0;
                top: rem-calc(14);
                right: 0;
                bottom: rem-calc(-17);
                padding: rem-calc(17 17 17 0);
                overflow: auto;
            }
        }
        .result {
            padding: rem-calc(24 0);

            @include breakpoint(large up) {
                padding-bottom: 0;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $border-gray;
            }

            i {
                &.fas {
                    margin: rem-calc(4);
                }
            }
        }

        .store-row{
            display: flex;
            flex-direction: row;
            gap: rem-calc(16);
        }
        .pin-icon {
            width: rem-calc(20);
            height: rem-calc(20);
        }
        .store-name {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            font-weight: 600;
            margin-bottom: rem-calc(4);
        }

        .store-details {
            p {
                display: block;
                margin-bottom: 0;

                span {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
            }
        }

        .size-availability p,
        .store-distance {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-bottom: 0;
        }

        .size-availability {
            display: flex;
            flex-direction: row;
            gap: rem-calc(8);
        }

        .size-availability,
        .store-hours-info {
            margin-top: rem-calc(14);
            margin-left: rem-calc(36);


            .store-hours-open {
                color: $water-green;
            }
        }

        .text-brand-green {
            color: $success;
        }

        .fis-show-on-map,
        .js-store-phone {
            &:not(.button-tertiary-black){
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: 400;
                text-underline-offset: rem-calc(2);
            }
        }

        .store-hours {
            .store-hours-next-phase {
                display: inline-block;

                &:first-letter {
                    text-transform: capitalize
                }
            }

            .store-hours-list {
                ul {
                    list-style: none;
                    margin: rem-calc(16) 0 rem-calc(8);

                    li {
                        margin-bottom: rem-calc(8);
                    }

                    .active {
                        font-weight: bold;
                    }
                }
            }

            .store-hours-current {
                font-weight: 400;
                display: inline;
            }

            .toggle-store-hours-dropdown {
                margin-left: rem-calc(6);

                &.flip {
                    transform: rotate(180deg);
                }
            }
        }

        .add-margin {
            margin-bottom: rem-calc(16);

            span {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }

        .icon-phone {
            width: rem-calc(24);
            height: rem-calc(24);
            margin-right: rem-calc(8);
        }

        .semibold {
            font-weight: 600;
        }
    }

    .iphone-disable-zoom {
        font-size: rem-calc(16);
    }
}

#pickupPointListDEL,
#pickupPointListGLS,
#pickupPointListMRE,
#pickupPointListGLSHU,
#pickupPointListDPD,
#pickupPointListFAN,
#pickupPointListXBS
#pickupPointListBPOST{
    &.pickuppoint-results{
        &.results-checkout{
        @include breakpoint (large up){
            height: calc(100vh - #{rem-calc(175)});
            margin-bottom: 0;
            }
        }
    }
}

#pickupPointListEST{
    &.pickuppoint-results{
        &.results-checkout{
            @include breakpoint (large up){
                height: calc(100vh - #{rem-calc(320)});
                margin-bottom: 0;
            }
        }
    }
}

#pickupPointListEVR,
#pickupPointListPTN{
    &.pickuppoint-results{
        &.results-checkout{
            @include breakpoint (large up){
                height: calc(100vh - #{rem-calc(215)});
                margin-bottom: 0;
            }
        }
    }
}
.fis-select-size-modal{
    .modal-title-selectsize{
        font-size: rem-calc(22);
        letter-spacing: 600;
        margin-bottom: rem-calc(20);
    }
    .find-in-store-size-select-btn{
        border-radius: rem-calc(40);
        padding: rem-calc(12 30);
    }
    li button{
        width: rem-calc(48);
        height: rem-calc(48);
        display: inline-block;
        line-height: rem-calc(48);
        position: relative;
        font-weight: 400;
        border: 1px solid #ccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            display: none;
            position: absolute;
            top: rem-calc(-2);
            right: rem-calc(-3);
            width: rem-calc(22);
            height: rem-calc(22);
        }
        &.selected{
            img{
                display: block;
            }
        }
    }
}

.size-modal-mobile{
    z-index: 9999!important;
}

.fis-select-size-modal,
.prod-select-size-modal {
    z-index: 906; // above quickview

    .tingle-modal-box__content{
        padding: rem-calc(16);
    }

    .tingle-modal-box {
        max-width: rem-calc(446);
    }

    button {
        margin-right: 0;
    }

    .add-to-cart{
        width: rem-calc(240);

        &.disabled{
            background-color: #CCCCCC;
            color: white;
        }
    }


    ul {
        list-style-type: none;
        width: 100%;
        margin: rem-calc(13 0 0);
    }

    li {
        display: inline-block;

        button {
            margin: rem-calc(0 8);

            &[disabled] {
                opacity: 0.4;
            }

            &.selected,
            &:focus,
            &:active,
            &:hover {
                outline: none;

                &:not([disabled]) {
                    border-color: $black;
                }
            }
        }
    }
}

.prod-select-size-modal {
    .find-in-store-size-select-btn {
        display: none;
    }
}

.fis-select-size-modal {
    .add-to-cart {
        display: none;
    }
}

#pickupPointMap, #pickupPointMapInPost, #pickupPointMapPPL, #pickupPointMapDHL, #pickupPointMapDPD, #pickupPointMapBRT, #pickupPointMapHermes, #pickupPointMapCLSS, .cc-pickupPointMapStandard {
    height: 0;
    min-height: calc(100vh - 190px);
    background-color: $light-gray;
    @include breakpoint (large up) {
        display: block;
        height: 100vh;
    }
    &.map-canvas-map-open{
        min-height: calc(100vh - 340px);
    }
    .gm-style {
        .gm-style-iw-t{
            font-family: 'Poppins';
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            transform: translate(0px, 182px);
            @include breakpoint (ipad down) {
                transform: translate(0px, 20px);
            }
            .gm-style-iw-c{
                max-width: rem-calc(306) !important;
                max-height: rem-calc(150) !important;
                width: 100%;
                padding: rem-calc(16 0 0 16);
                border-radius: rem-calc(4);
                border: 1px solid $water-green;
                box-shadow: none;
                @include breakpoint (ipad down) {
                    padding: rem-calc(16);
                }
                button{
                    margin: 0;
                    &.gm-ui-hover-effect{
                        display: none !important;
                    }
                }
            }
        }
        .gm-style-iw-d{
            padding-bottom: rem-calc(7);
            img {
                &.pickup-point-pin-menu{
                    margin-right: rem-calc(16);
                }
            }
            .store-name{
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                padding-bottom: rem-calc(8);
            }
            p{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin-bottom: rem-calc(8);
            }
            .store-gotomps-box {
                margin-top: rem-calc(-10);
            }
        }
        .gm-style-iw-tc{
            display: none;
        }
    }
}
#js-pickupPointMapPDB{
    &.map-canvas-map-open{
        min-height: calc(100vh - 400px);
    }
}
#js-pickupPointMapFAN{
    &.map-canvas-map-open{
        min-height: calc(100vh - 400px);
    }
}

.localities-container {
    color: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    font-family: $body-font-family;
    z-index: 1021;
}

.localities-item {
    color: #7B7B7B;
    height: auto;
}

.localities-item-query {
    color: #7B7B7B;
}

.localities-icon {
    background-color: #b2b2b2;
}

.localities-matched {
    color: $black;
}

.localities-input {
    color: $black;
    border-radius: 0;
}

/* result line when selected or hovered */
.localities-item-selected,
.localities-item:hover {
    background-color: #edf4f9;
}

/* result icon when selected or hovered */
.localities-item-selected .localities-icon,
.localities-item:hover .localities-icon {
    background-color: #f2a;
}

.localities-input-wrapper,
.localities-input-container,
.woosmap-label-input {
    width: 100%;
}

.localities-input-container {
    display: block;
}

.mapPoint__price {
    display: none !important;
}

#modalOSM {
    max-width: rem-calc(1000) !important;
}

.ruch_widget_but_s {
    margin-top: rem-calc(-10) !important;
}

#pickupPointPDB {
    #PDB-summary {
        margin: 0;
        font-weight: 500;
    }

    .pickup-point-service-types {
        font-size: rem-calc(14);
        padding: rem-calc(24 0);
        display: flex;
        flex-direction: column;
        gap: rem-calc(12);

        .boolean-field {
            width: 100%;
            margin-bottom: 0;
            cursor: pointer;
            font-size: rem-calc(14);
        }

        .radio-input {
            padding-left: rem-calc(20);
            margin-right: rem-calc(12);
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }
}
#pickupPointFAN {
    #FAN-summary {
        margin: 0;
        font-weight: 500;
    }

    .pickup-point-service-types {
        font-size: rem-calc(14);
        padding: rem-calc(24 0);
        display: flex;
        flex-direction: column;
        gap: rem-calc(12);

        .boolean-field {
            width: 100%;
            margin-bottom: 0;
            cursor: pointer;
            font-size: rem-calc(14);
        }

        .radio-input {
            padding-left: rem-calc(20);
            margin-right: rem-calc(12);
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }
}


.localities-container {
    box-shadow: none;
}

.cc-pdp {
    &__findInStoreModal {
        width: calc(33.33333%);
        left: auto;
        align-items: end;
        z-index: 10000;

        @include breakpoint (medium down) {
            width: 100%;
        }

        .tingle-modal-box {
            width: 100%;
            height: 100%;
            max-height: none;
            overflow: hidden;
        }

        .tingle-modal-box__content {
            padding: 0;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: rem-calc(1) solid $light-gray;
            padding: rem-calc(27 31);

            @include breakpoint(small down) {
                padding: rem-calc(20);
            }

            svg {
                fill: $white;
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }

        &__body {
            padding: rem-calc(24 31);
            max-height: calc(100vh - 180px);
            overflow-y: auto;

            @include breakpoint(small down) {
                padding: rem-calc(24 16);
            }

            svg  {
                width: rem-calc(20);
                height: rem-calc(20);
                fill: $white;
            }
            p {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: 400;
                margin-bottom: rem-calc(16);
                letter-spacing: rem-calc(0.2);
                margin-left: rem-calc(35);
            }
            a {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: rem-calc(4);
                margin-top: rem-calc(24);
                margin-left: rem-calc(35);
            }
        }

        &__title {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            letter-spacing: rem-calc(0.2);
            font-weight: 600;
            margin-bottom: rem-calc(16);
        }

        &__sectionTitle {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            font-weight: 600;
            margin-bottom: 0;
        }

        &__distance  {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: 400;
        }

        &__section {
            border-bottom: rem-calc(1) solid $light-gray;
            padding-bottom: rem-calc(24);
        }

        &__sectionHeader {
            display: flex;
            flex-direction: row;
            gap: rem-calc(16);
            margin-top: rem-calc(24);
            margin-bottom: rem-calc(16);
        }

        &__sectionMainHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__storeHoursList {
            list-style-type: none;
            margin-left: rem-calc(35);
            margin-top: rem-calc(8);

            li {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.2);
                font-weight: 400;
                margin-top: rem-calc(8);
            }
        }

        &__bottomWrapper {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: $white;
            border-top: rem-calc(1) solid $light-gray;
            z-index: 1;
            padding: rem-calc(24 31);

            @include breakpoint(small down) {
                padding: rem-calc(24 16);
            }

            .button {
                margin: 0;
                width: 100%;
            }
        }
    }
}
