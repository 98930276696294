$include-fonts: false;

@import "../setup/settings";
@import "plp/quickview";
@import "plp/tile";
@import "pdp/findinstore";
@import "pdp/ratingsReviews";
@import "../layout/pdpplpcomponents";
@import "../plugins/scalapay";
@import "../components/scrollingText.scss";
@import "../components/zoom.scss";
@import "../components/deliveryAndReturnModal.scss";
@import "../components/madeInModal.scss";

body.is-modal-opened {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
}

.navigation--tezenis .header-search-holder {
    z-index: 0;
}

#esw-fab.ui-eswback {
    z-index: 900 !important;
}

.cc-hidePercentage {
    display: none;
}

.icons-container-hidden {
    display: none;
}

@include breakpoint(small down){
    .special-top-banner{
        margin-top: rem-calc(-30);
        & + .listing-banner{
            margin-top: rem-calc(20);
        }
    }
}
.listing-banner{
    padding: rem-calc(50);
    position: relative;
    margin-bottom: rem-calc(45);
    @include breakpoint(medium down){
        padding: rem-calc(40);
        margin-bottom: rem-calc(15);
    }
    @media (max-width: 768px){
        padding: rem-calc(25 20 30);
    }
    @include breakpoint(small down){
        margin-top: rem-calc(-30);
        & + .special-top-banner{
            margin-top: 0;
        }
    }
    a, p, h4{
        color: white;
    }
    h4{
        font-weight: bold;
        font-size: rem-calc(20);
    }
    p{
        font-size: rem-calc(18);
        @include breakpoint(medium down){
            font-size: rem-calc(14);
        }
    }
    a{
        margin-right: rem-calc(15);
        font-size: rem-calc(14);
        border-bottom: 2px solid white;
        font-weight: $global-weight-bold;
        &:hover{
            border-color: white;
        }
    }
    .text-container{
        padding: rem-calc(0 300);
        @media (max-width: 1380px){
            padding: rem-calc(0 170);
        }
        @media (min-width: 600px) and (max-width: 800px){
            padding: rem-calc(0 70);
        }
        @media (max-width: 600px){
            padding: 0;
        }
    }
    img{
        position: absolute;
        bottom: rem-calc(20);
        left: rem-calc(140);
        @media (max-width: 1380px){
            left: rem-calc(60);
        }
        @include breakpoint(medium down){
            display: none;
        }
    }
}

@media screen and (min-height: 700px) and (max-height: 1366px){
        .special-tablet{
            max-height: 150px;
        }
}
@include breakpoint(medium up) {
    .page > .grid-container .add-to-cart-messages {
        display: none;
    }
}

@include breakpoint(small down) {
    .main-header-wrap .add-to-cart-messages {
        display: none;
    }

    .internal-message .add-to-cart-messages {
        top: 0;
        z-index: 3;
        width: 100vw;
        position: fixed;
        right: 0;
        left: 0;

    }

    .page > .grid-container.affix-top-for-small-only .add-to-cart-messages {
        // TODO: to check
        position: fixed;
        max-width: 70vw;
        right: rem-calc(20);
        display: block !important;
    }
}

.unique-size {
    display: none;
}

#descriptionSidebar {
    padding-top: rem-calc(10);
    background: $white;
    position: relative;
    @include breakpoint(medium up) {
        margin: 0;
    }

    .m-d-price-container {
        text-align: right;
        @include breakpoint(medium up) {
            margin-top: rem-calc(13);
            text-align: left;
            font-size: rem-calc(16);
        }
    }
}

.product-code {
    padding-right: rem-calc(20);
    padding-top: rem-calc(18);
    padding-bottom: rem-calc(18);
    font-size: rem-calc(11);
}

.page[data-action="Product-Show"]{
    .promo-message {
        &.header-message {
            @include breakpoint (small down) {
                display: none;
            }
        }
    }
}

#Look {
    &.look--tezenis {
        position: relative;
        @include breakpoint(small down) {
            margin-top: rem-calc(8);
            padding-bottom: rem-calc(0);
            h6 {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
                text-align: left;
                font-weight: 600;
                margin-left: rem-calc(16);
                margin-right: rem-calc(16);
                margin-bottom: rem-calc(16);
            }
        }

        .cross-sell-header {
            .tingle-modal__closeIcon {
                display: none;
            }
        }
    }
}
#completeTheLook,
#crossSellVariation {
    margin-top: rem-calc(16);

    .slick-track {
        margin-left: rem-calc(16);

        .product {
            margin-right: rem-calc(4);

            .price--tezenis {
                &.hidden {
                    display: none;
                }
            }
        }
    }

    .slick-arrow {
        @include breakpoint(small down) {
            display: none!important; //contrast js inline style
        }
    }
}

.suggested-products-promo-title {
    color: $black;
    font-weight: 600;
}

.slider-pos {
    position: fixed;
    margin-left: rem-calc(20);
    margin-top: rem-calc(20);

    & .slide {
        width: rem-calc(5);
        height: rem-calc(5);
        border-radius: 50%;
        background: $medium-light-gray;
        margin-bottom: rem-calc(5);

        &.active {
            background: $primary;
        }
    }
}
#ProdRecommendation, #upsellRecommendation {
    background-color: #f2f3ed;
    padding-left: rem-calc(20);

    .recommendation {
        color: #ff689a;
    }

    .label-cta,
    .label-cta-loyalty,
    .color-swatches .plp-swatch {
        @include breakpoint(small down) {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            height: unset;
        }
    }

    .pdp-link__title,
    .price-container {
        @include breakpoint(small down) {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
        }
    }

    &.theMostPopularContainer--tezenis.upsell-container {
        padding: 0;
        background-color: #FFF;
        margin-top: 0;
        .upsell-container-title {
            font-size: rem-calc(18);
            line-height: rem-calc(28);
            letter-spacing: rem-calc(0.54);
            margin-bottom: rem-calc(16);
            font-weight: bold;
            @include breakpoint (large down) {
                margin-top: rem-calc(20);
            }
            .highlited {
                color: #FF6699;
            }
        }
        .upsell-carousel {
            @media (max-width: 1024px) {
                padding: 0;
            }
            overflow: hidden;
            .prev-button-ei,
            .next-button-ei {
                top: 38%;
            }
            .slick-list {
                margin: rem-calc(0 -9);
                width: calc(100% + 14px);
                @include breakpoint(large down) {
                    margin: rem-calc(0 -10);
                }
                .slick-track {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .slick-slide {
                margin: rem-calc(0 9);
                outline: none;
                @include breakpoint(large down) {
                    margin: rem-calc(0 10);
                }
                * {
                    outline: none;
                }
            }
        }
    }

    &.theMostPopularContainer--tezenis {
        background-color: $white;
        padding: 0;

        &__cart{
            .einstein-carousel .next-button-ei, .cc-einstein-carousel .next-button-ei{
                right: calc(6% + 25px) !important;
            }
        }
        .carousel-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: rem-calc(24 16 0);
            margin-bottom: rem-calc(40);
            @include breakpoint(large up) {
                padding: rem-calc(80 40 0);
            }

            svg {
                path {
                    fill: $white;
                }
            }

            &__button-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                gap: rem-calc(25);

                @include breakpoint(small down) {
                    display: none;
                }

                .slick-arrow {
                    cursor: pointer;
                }
            }
        }
        h4 {
            font-size: rem-calc(32);
            font-weight: 600;
            line-height: rem-calc(36);
            text-align: left;
            color: $black;
            margin-bottom: 0;

            @include breakpoint(small down) {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
            }
        }
        .einstein-carousel, .upsell-carousel, .cc-einstein-carousel, .quiz-four-products {
            position: relative;
            @media (max-width: 1024px) {
                padding: rem-calc(0 45);
            }
            @include breakpoint(small down) {
                padding: rem-calc(0 0 0 5);
            }

            .product {
                margin-right: rem-calc(16);

                @include breakpoint(medium down) {
                    margin-right: rem-calc(16);
                }
            }

            .price-container--tezenis{
                display: flex;
                flex-flow: row wrap;
                .price--tezenis{
                    flex: 0 0 auto;

                    &.price-with-omnibus {
                        flex: unset;
                        @include breakpoint (medium down) {
                            font-size: rem-calc(12);
                            line-height: rem-calc(12);
                        }

                        .price-span {
                            .sales .value {
                                word-break: break-word;
                                white-space: normal !important;
                            }
                        }
                    }

                    &.hidden {
                        display: none;
                    }
                }
                .promo-message.js-promo-container{
                    flex: 1 1 0px;
                    display: flex;
                }
                .promo-message.js-promo-msg{
                    @include breakpoint (medium up){
                        margin-left: rem-calc(8);
                    }
                }
                &.price-container.price-with-omnibus {
                    padding-top: 0;
                    margin-top: 0;

                    .strike-through ~ .sales {
                        margin-left: rem-calc(8);
                    }
                }
            }
        }
        .einstein-carousel, .cc-einstein-carousel {
            padding: rem-calc(0 0 0 40);
            @include breakpoint (medium down){
                padding: 0;
            }

            .slick {
                &-track {
                    display: flex;
                    margin-left: 0;
                }

                &-list {
                    @include breakpoint (medium down){
                        padding-left: rem-calc(16);
                    }
                }
                &-slide {
                    height: inherit;
                }
            }
            .product-tile {
                height: 100%;
                justify-content: space-between;
            }
        }
        .image-container {
            aspect-ratio: 0.6666666666666667;

            video {
                height: 100%;
                object-fit: cover;
            }
        }
        .quiz-four-products{
            width: 100%;
            padding: rem-calc(0 40);
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: rem-calc(16);
            margin-top: rem-calc(40);
            .product {
                margin-right: rem-calc(0);
                flex-grow: 1;
                flex: 1;
                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }
            }
        }
    }
}

.pdp-message-custom {
    * {
        display: inline;
        font-size: rem-calc(12) !important;
    }
}
.plp-message-custom {
    * {
        display: inline;
        font-size: rem-calc(12) !important;
    }
}

.carousel-item {
    margin-bottom: rem-calc(10);

    @include breakpoint(small down) {
        margin-bottom: rem-calc(0);
        .fixed-only-mobile-img {
            position:fixed!important;
            z-index: 999999!important;
            width:100%;
            height:100%;
            background-color: $black;
            margin: 0;

            img{
                height: 100vh;
                width: 100%;
            }
        }
        .quiz-four-products{
            width: 100%;
            padding: rem-calc(0 40);
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: rem-calc(16);
            margin-top: rem-calc(40);
            .product {
                margin-right: rem-calc(0);
                flex-grow: 1;
                flex: 1;
                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }
            }
        }
    }
}
@include breakpoint(small down) {
    .carousel.slide {
        margin-bottom: rem-calc(200);
        margin-right: rem-calc(-9);
        margin-left: rem-calc(-9);

        &.no-margin-bottom {
            margin-bottom: 0;
        }

    }

}

@include breakpoint(medium up) {
    .add-to-cart-actions {
        margin-top: rem-calc(100);
    }
}

.info-container {
    margin-top: rem-calc(10);
    padding: rem-calc(10);
    background: $light-gray;
}

.description-and-detail {
    align-items: flex-end;

    & .content {
        width: 100%;
        min-height: rem-calc(80);
        padding: rem-calc(5);
            .icon-img {
                width: rem-calc(20);
                height: rem-calc(20);
            }
    }

    .block-title{
        font-size: rem-calc(14);
        font-weight: 700;
    }
}

.authenticity{
    margin-bottom: rem-calc(25);
    font-size: rem-calc(14);
    line-height: rem-calc(24);
    button{
        cursor: pointer;
    }
}

.made-in-modal{
    @include breakpoint(small down){
        background-color: transparent;
    }
    .tingle-modal-box__content{
        padding: 0;
        .close{
            width: rem-calc(24);
            height: rem-calc(24);
            top: rem-calc(19);
            right: rem-calc(21);
            z-index: 1;
            margin: 0;
        }
        .fabric-content{
            display: flex;
            flex-direction: column;
        }
        .fabric-img-container{
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            min-width: 100%;
            height: 24.61vh;
            @include breakpoint (small down){
                display: contents;
            }
            img{
                display: none;
                @include breakpoint (medium up){
                    display: unset;
                    width: 100%;
                    height: 24.61vh
                }
            }
             .fabric-info{
                position: absolute;
                width: 100%;
                bottom: rem-calc(60);
                margin: rem-calc(10 0 24);
                @include breakpoint (medium up){
                    bottom: rem-calc(-240);
                    margin: rem-calc(16 0 40);
                }
                .grid-x{
                    display: block;
                    padding: rem-calc(0 24);
                    width: 100%;
                    @include breakpoint (medium up){
                        padding: rem-calc(0 40);
                    }
                }
                .fabric-info-cell{
                    display: inline-flex;
                    width: 100%;
                    height: rem-calc(21);
                    &:first-child{
                        margin-bottom: rem-calc(10);
                        @include breakpoint (medium up){
                            margin-bottom: rem-calc(8);
                        }
                    }
                }

                .fabric-place,
                .fabric-operation-category{
                    margin-right: rem-calc(8);
                    font-weight: $global-weight-dark;
                    &, &-value{
                        font-size: rem-calc(14);
                        margin-bottom: 0;
                        white-space: pre;
                    }
                    &-value{
                        font-size: rem-calc(14);
                        font-weight: $global-weight-normal;
                        letter-spacing: rem-calc(0.2);
                        white-space: unset;
                    }
                }
            }
        }
        .fabric-description-cell{
            display: flex;
            flex-direction: column;
        }
        .fabric-description-cell,
        .production-chain-cell{
            flex: 1;
            @include breakpoint(small down){
                margin: 0;
                padding: rem-calc(24);
                background-color: white;
            }
            .fabric-title{
                font-size: rem-calc(14);
                line-height: rem-calc(26);
                margin: rem-calc(40 0 24);
                text-align: left;
                @include breakpoint(small down){
                    font-size: rem-calc(14);
                    margin: rem-calc(0 0 24);
                }
            }
            .fabric-description{
                margin-bottom: rem-calc(108);
                text-align: left;
                @include breakpoint(small down){
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                    margin-bottom: rem-calc(85);
                }
            }
        }
        .production-chain-cell{
            @include breakpoint(small down){
                padding: rem-calc(24);
                margin: 0;
            }
            .production-chain-title{
                margin-bottom: rem-calc(24);
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                @include breakpoint (medium up){
                    margin: rem-calc(40 0 24 0);
                }
            }
        }
        .extended-for-mobile{
            width: fit-content;
            width: -moz-fit-content;
            font-size: rem-calc(12);
            font-weight: 500;
            padding: rem-calc(8 40);
            line-height: normal;
            border-radius: rem-calc(43);
            @include breakpoint(medium up){
                width: fit-content;
                width: -moz-fit-content;
                font-size: rem-calc(14);
                font-weight: 500;
                padding: rem-calc(16 32);
                // override the no-margin class inside the content asset
                margin-left: rem-calc(-10) !important;
                line-height: normal;
                border-radius: rem-calc(43);
            }
        }
    }
}

.js-panel-opener.open .caret {
    transform: rotate(-180deg);
}

.checkbox-Z_TAGLIE_0033 .radio-label {
    letter-spacing: 0.25rem;
}

.size-interactive-guide {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: rem-calc(10);

    & .size-guide-label {
        margin-top: auto;
        margin-bottom: auto;
    }

    & .size-guide-select {
        border: 0;
        border-bottom: rem-calc(1) solid $black;
        display: inline-block;
    }
}

.small-triangle-down {
    @include css-triangle(rem-calc(6), $black, 'down');
    margin-top: rem-calc(10);
    margin-right: rem-calc(7);
}

.small-triangle-up {
    @include css-triangle(rem-calc(6), $black, 'up');
    margin-top: rem-calc(10);
    margin-right: rem-calc(7);
}

.color-variation {
    display: inline-block;
    width: rem-calc(22);
    height: rem-calc(22);
    position: relative;
    margin-right: rem-calc(5);

    & .swatch-circle {
        vertical-align: super;
    }

    & .swatch-circle.unselectable {
        cursor: not-allowed;
        opacity: 0.2;
    }

    & .swatch-circle.selected:after {
        display: block;
        position: absolute;
        width: rem-calc(27);
        height: rem-calc(27);
        border: rem-calc(1) solid $medium-gray;
    }
}

.desc-sale {
    margin-bottom: rem-calc(12);
    color: $medium-gray;
    &.on-sale-color{
        color:$primary;
    }
}

.accordion-panel-group {
    @include breakpoint(small down) {
        width: 100vw;
        background: $white;
    }
    & .color-variation {
        width: 30px;
        height: 30px;
        display:inline-block;
        line-height:50px;
        vertical-align:middle !important;
        font-size: 0;
    }
}

ul.select-size-list li button:disabled.size-selector{
    text-decoration: line-through;
    opacity: unset;
}

label.swatch-boolean {
    z-index: 1;
    padding: rem-calc(20 8 0 8);
    @include breakpoint(small down) {
        padding-top: 0;
    }

    & input[type="radio"]:focus + .radio-label {
        border-color: $black;
        img {
            display: inline;
        }
    }
    & input[type="radio"]:checked + .radio-label {
        text-decoration: underline;
    }

    & input[type="radio"]:disabled + .radio-label {
        opacity: 0.4;
        text-decoration: line-through;
        svg {
            &.alertNotify{
                display: none;
                margin-left: rem-calc(5);
                width: rem-calc(14);
            }
        }
    }
    .radio-label {
        width: rem-calc(48);
        height: rem-calc(48);
        display: inline-block;
        line-height: rem-calc(48);
        position: relative;
        font-weight: 500;
        border: 1px solid $light-gray;
        border-radius: 50%;

        img{
            display: none;
            position: absolute;
            top: rem-calc(-4);
            right: rem-calc(-4);
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
}

.tingle-modal-box label.swatch-boolean .radio-label{
    svg {
        &.alertNotify{
            display: none !important;
        }
    }
}

.quantity-value {
    display: inline;
    margin-right: rem-calc(10);
    margin-left: rem-calc(10);
}

.icon-wash{
    width: rem-calc(32);
    height: rem-calc(32);
    display: inline-block;
    margin-right: rem-calc(15);
    padding: rem-calc(4);
}

.quickselect-size-modal{
    @include breakpoint(small down){
        border-radius: rem-calc(5);
    }
    .tingle-modal-box__content{
        padding: rem-calc(16 9 8 9);
    }
    .modal__closeIcon{
        margin: 0;
        top: rem-calc(13);
        right: rem-calc(13);
        line-height: rem-calc(11);
    }
}

.quickSizeModal{
    .quick-add-title {
        margin: rem-calc(0 0 8 7);
        display: block;
        font-variant: normal;
        font-style: normal;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        font-weight: 600;
        font-family: Poppins;
        letter-spacing: rem-calc(0.42);
        color: $black;
        opacity: 1;
        }

    .attribute-container {
        & .accordion-panel-group {
            padding: rem-calc(10);
            box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.05);
            border-top: 0;
            @include breakpoint(small down) {
                border: 0;
            }
        }
        .product-content-size{
            &-row {
                display: inline-block;
                label{
                    padding: 0;
                    margin: 0;
                }
            }
            @include breakpoint(small down) {
                display: flex;
                flex-wrap: wrap;
            }
            .radio-label{
                @include breakpoint(small down) {
                    font-size: rem-calc(16);
                    margin: rem-calc(8) rem-calc(7);
                    width: rem-calc(47);
                    height: rem-calc(47);
                    display: inline-block;
                    line-height: rem-calc(47);
                    position: relative;
                    font-weight: 400;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    img{
                        display: none;
                        position: absolute;
                        top: rem-calc(-4);
                        right: rem-calc(-4);
                        width: rem-calc(16);
                        height: rem-calc(16);
                    }
                    &.selected{
                        img{
                            display: block;
                        }
                    }
                    &.notifyMe {
                        min-width: fit-content;
                        color: gray;
                        display: flex;
                        border-radius: 50%;
                        align-items: center;
                        svg {
                            &.alertNotify{
                                display: block;
                                margin-left: rem-calc(5);
                                width: rem-calc(11);
                            }
                        }
                    }
                    .suggested-products & {
                        font-size: rem-calc(12);
                        width: auto;
                        height: auto;
                        line-height: rem-calc(18);
                        padding: rem-calc(8 16);
                        border-radius: rem-calc(20);
                    }
                }
            }
        }
    }
}

.tingle-modal-box.quickselect-size-modal {
    max-width: rem-calc(327);
}

.breadcrumb-item {
    &, &__divider {
        font-size: rem-calc(10);
        line-height: rem-calc(15);
    }

    & > a {
        font-weight: $global-weight-normal;
        color: $black;
    }

    &:last-of-type {
        a {
            color: $black;
        }

        font-weight: 500;
    }

    @include breakpoint(small down) {
        &.last-category-item a {
            font-weight: 500;
            color: black;
        }
    }
}

.product-breadcrumb {
    margin-bottom: rem-calc(10);
}

.crosssell-view {
    & .price-container {
        text-align: center;
        @include breakpoint(small down) {
            text-align: left;
        }
    }

    .image-container {
        aspect-ratio: 0.6692913385826772;

        .js-video {
            height: 100%;
            aspect-ratio: 0.6692913385826772;
            object-fit: cover;
        }
    }
}

.attribute-button {
    border-bottom: rem-calc(1) solid $black;
    padding-bottom: rem-calc(5);
    padding-left: rem-calc(5);
    text-align: left;
    text-transform: uppercase;
    margin-right: rem-calc(16);
    line-height: rem-calc(25);
    font-size: rem-calc(11);
    font-weight: 500;

    @include breakpoint(small down) {
        &:nth-child(1) {
            &.bonus-product-attribute {
                border-left: rem-calc(1) solid $black;
            }
        }
        &:nth-of-type(2) {
            margin-right: 0;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    & .attribute-selection {
        display: inline-block;
        max-width: rem-calc(30);
        height: rem-calc(25);
        margin-right: rem-calc(10);
        font-weight: $global-weight-bold;

        & > .swatch-circle {
            width: rem-calc(20);
            height: rem-calc(20);
            margin-top: rem-calc(2);
            display: block;
            border-radius: 50%;
        }
    }
}

.price .strike-through ~ .sales {
    color: $sale;
    margin-left: rem-calc(8);
}

.price {
    &--tezenis {
        &:not(.hidden) {
            @media (max-width: 1024px) {
                display: grid;
            }
            @include breakpoint(small down) {
                display: inline-block;
                margin-right: rem-calc(5);
            }
        }
        .strike-through ~ .sales {
            margin-left: rem-calc(5);
        }
        .onsale {
            display: contents;
            margin-left: rem-calc(10);
            text-transform: uppercase;
            font-weight: 600;
            font-size: rem-calc(12);
            @include breakpoint(small down) {
                margin-left: rem-calc(2);
                font-size: rem-calc(10);
            }
        }
        .price-second-currency{
            margin-top: rem-calc(4);
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: 400;
            @include breakpoint(small down) {
                font-size: rem-calc(10);
                line-height: rem-calc(15);
            }

            .double-currency-sales{
                &.discount-sale {
                    display: inline-block;
                    margin-left: rem-calc(4);
                    color: #D10B82;
                }
            }
            .double-currency-sales, .double-currency-list, .discount-value-percentage{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: 400;
                @include breakpoint(small down) {
                    font-size: rem-calc(10);
                    line-height: rem-calc(15);
                }
            }
        }
        &.cc-hidePercentage {
            display: none;
        }
    }
}

.price--tezenis ~ .promo-message--tezenis {
    margin-left: rem-calc(10);
    @media (max-width: 1440px) {
        margin-left: 0;
        text-align: center;
        font-size: rem-calc(11);
    }
    @media (max-width: 1366px) {
        display: block;
        margin-top: rem-calc(5);
    }
}

.cell.recently-viewed .price--tezenis ~ .promo-message--tezenis {
    display: none;
}

@include breakpoint(small down) {
    .carousel-item.active img {
        margin-top: rem-calc(-40);
    }

    .neg-margin {
        margin-right: -(map-get($grid-margin-gutters, small) / 2);
        margin-left: -(map-get($grid-margin-gutters, small) / 2);
    }
    .add-to-cart {
        margin-bottom: 0;
        border-top: rem-calc(1) solid $black;
        padding: rem-calc(10);
        height: rem-calc(53);
        font-size: rem-calc(14);
        &.button-edited-cart {
            padding: rem-calc(2);
            font-size: rem-calc(10);
            &:disabled,
            &[disabled] {
                background-color: #949494;
                color: #fff;
                border: none;
            }
        }
        &.added {
            background: rgb(255,102,153);
            background: linear-gradient(90deg, rgba(255,102,153,1) 0%, rgba(79,156,158,1) 100%);
            color: #fff;
            border: none;
        }
    }

    .margin-right-added {
        margin-right: rem-calc(3);
    }

    #descriptionSidebar {
        border: rem-calc(1) solid $black;
        border-bottom: 0;
        padding-top: rem-calc(7);
        padding-bottom: env(safe-area-inset-bottom, 0);

        & .prices {
            margin-top: auto;
            margin-bottom: rem-calc(5);
            font-size: rem-calc(13);
            width: 19%;
            display: inline-block;
        }

        & .promo-and-name-cell {
            width: 80%;
            display: inline-block;

            @include breakpoint(medium up) {
                width: 100%;
            }
        }
    }

    .attribute-button {
        margin: 0;
        height: rem-calc(40);
        border: rem-calc(1) solid $black;
        border-left: 0;
        padding-top: rem-calc(7);
        padding-left: rem-calc(10);
        font-size: rem-calc(11);
    }

    .product-name {
        font-size: rem-calc(11);
        letter-spacing: normal;
    }

    .main-header {
        position: relative;
        &--tezenis {
            @include breakpoint (small down) {
                border-bottom: solid 1px #DEDFE0;
            }
        }
    }

    .button {
        border-bottom: rem-calc(1) solid $black;
    }

    .inactive-logic {
        .attribute-button {
            &.open {
                border: rem-calc(1) solid $black;
            }

            &:not(.open) {
                opacity: 0.4;
                border-color: $medium-gray !important;
            }
        }
        .button {
            opacity: 0.4;
            border: rem-calc(1) solid $black;
        }
    }

    .icon-container {
        position: absolute;
        width: 100%;
        margin-top: rem-calc(10);
        padding: rem-calc(10 10);

        &.at-top {
            position: fixed;
            top: rem-calc(0);
        }

        & .aligned-right {
            float: right;

            & .wishlist-icon {
                display: block;
            }

            & .find-in-store-icon {
                display: block;
                margin-left: rem-calc(5);

                & svg.big {
                    width: rem-calc(20);
                }
            }
        }


        & .slider-pos {
            position: relative;
            margin: 0;
            width: rem-calc(20);
        }
    }
}

.playVideoIcon {
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    @include breakpoint(medium up) {
        top: 50%;
        height: rem-calc(61);
    }
    @include breakpoint(small down) {
        top: 45%;
        height: rem-calc(45);
    }
    &.playVideoIconPrimary {
        cursor: pointer;
        opacity: 0.5;
    }
}
.js-productMiniVideoContainer {
    position: relative;
    .playVideoIcon {
        height: rem-calc(14);
    }
}
.store-locator-note {
    letter-spacing: 0;
    font-size: rem-calc(10);
    display: inline-block;
    padding-bottom: rem-calc(18);

    & .img-container {
        height: rem-calc(12);
        width: rem-calc(15);
        display: inline-block;
    }

    & .find-in-store-btn {
        letter-spacing: 0;
    }
}

.return-info {
    margin-bottom: rem-calc(20);
}

#notifications {
    position: fixed;
    z-index: 1020;
    top: 0;

    & .header-message.dismissable {
        height: unset !important;
    }
}

// in PDP page the scroll to top icon should be always hidden
.page[data-action="Product-Show"] ~ a.scroll-top-icon {
    display: none !important;
}

.recommendations {
    &.recentlyviewed {
        & .outer-container {
            overflow: hidden;
            @include breakpoint(small down) {
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;

                & .wrapper {
                    width: rem-calc(1030);
                }
            }
        }

        &--tezenis {
            margin-left: rem-calc(-20);
            margin-bottom: rem-calc(50);
            * {
                outline: none;
            }
            @include breakpoint(small down) {
                width: 100%;
                margin-left: auto;
            }
            .outer-container {
                position: relative;
            }
            .wrapper {
                width: auto !important;
                &.no-slick {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: baseline;
                    .recently-viewed {
                        flex: 1;
                    }
                }
            }
            .slick-list {
                padding: 0!important;
            }
            .prev-button,
            .next-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                z-index: 1;
                @include breakpoint(small down) {
                    display: none;
                }
            }
            .prev-button {
                left: 4%;
            }
            .next-button {
                right: 4%;
            }
            h5 {
                font-size: rem-calc(30);
                text-align: center;
                letter-spacing: 0.8px;
                font-weight: 500;
                margin-bottom: rem-calc(20);
                letter-spacing: 0.8px;
                @include breakpoint(small down) {
                    font-size: rem-calc(20);
                    font-weight: 600;
                }
            }
            .image-container {
                .quickview--tezenis {
                    display: none;
                }
            }
            .recently-viewed {
                margin-right: auto;
                margin: rem-calc(0 45);
                max-width: rem-calc(180);
                width: 100%;

                @media (max-width: 1440px) {
                    margin: rem-calc(0 35);
                }

                @include breakpoint(small down) {
                    margin: rem-calc(0 15);
                    max-width: rem-calc(120);
                    width: auto;
                }


                .price-container {
                    text-align: left;
                    border-bottom: none;
                    padding-bottom: 0;
                    display: inline-block;
                    padding-top: rem-calc(15);
                    .price {
                        span {
                            display: inline-block;
                            font-size: rem-calc(13);
                            letter-spacing: 0.8px;
                            font-weight: 400;
                            @media (max-width: 768px) {
                                font-size: rem-calc(11);
                            }
                        }
                        .strike-through {
                            > span {
                                text-decoration: line-through;
                                color: #949494;
                            }
                        }
                        .strike-through ~ .sales {
                            margin-left: rem-calc(5);
                            color: #EE7199;
                        }
                    }
                }

                button.add-to-cart {
                    display: none;
                }
            }
        }
    }

    & .recently-viewed {
        width: 8%;
        margin-right: 1.8%;
        float: left;

        @include breakpoint(small down) {
            width: rem-calc(85);
            margin-right: rem-calc(15);
            & .plp-wishlist {
                display: none;
            }
        }
    }
}

.button-padding-right{
    padding-right: rem-calc(10);
}
.button-padding-left{
    padding-left: rem-calc(10);
}

 // ModalImageCarousel
.wrapperModalImageCarousel{
    &.tingle-modal{
        z-index:1000;
    }

    .tingle-modal__close{
        display: inline-block;
    }

    .tingle-modal__close-wrapper {
        position: absolute;
        display: block;
        text-align: right;
        z-index: 999;
    }

    .tingle-modal-box{
         border-radius: rem-calc(0);
         height: 100vh;
         max-height: 100vh;
         width: 100vw;
         max-width: 100vh;
         margin: 0;
         padding: 0;

         .tingle-modal-box__content{
             padding: 0;
         }
         .modalImageCarousel {
             padding: rem-calc(0);

             .modalBody{
                 padding: 0;
                 margin: 0;

             }
         }
     }
}

@include breakpoint(small down) {
    .breadcrumb-wrap {
        display: none;
    }
}

.button.add-to-wish-list.button-primary.added {
    background-color: $black;
    color: $white;
}

.unavailable-product-message {
    margin-bottom: rem-calc(10);
}

.product-wrapper--tezenis {
    padding-top: rem-calc(12);
    @include breakpoint (small down) {
        padding-top: 0;
        margin-left: rem-calc(-15.75);
        margin-right: rem-calc(-15.75);
        width: calc(100% + 31.5px);
    }
    .carousel-container {
        position: relative;
        @include breakpoint (small down) {
            z-index: 1;
        }

        .primary-images {
            position: sticky;
            top: rem-calc(55);

            @include breakpoint (small down) {
                position: relative;
                top: auto;
            }

            .pdpCarousel__wrapper {
                padding-right: 0;

                .pdpCarousel__slider-container {
                    position: relative;
                    @include breakpoint (large up) {
                        margin-right: 6.442166910688141vw;
                    }

                    .pdp-complete-mobile-btn {
                        position: absolute;
                        left: rem-calc(17.5);
                        bottom: rem-calc(35);
                        border-bottom: 0;
                        z-index: 1;
                    }

                    .js-pdpCarousel {
                        height: 100%;
                        width: 100%;
                        @include breakpoint (ipad up) {
                            aspect-ratio: 2 / 3;
                            border-radius: 4px;
                        }
                    }
                }

                .productImageCarouselItem {
                    aspect-ratio: 2 / 3;
                    width:100%;
                    @include breakpoint (large up) {
                        border-radius: rem-calc(4);
                    }
                    @include breakpoint (ipad down) {
                        padding-bottom: rem-calc(17);
                    }
                }
            }

            .pdp-message-custom-label {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;
            }
        }
    }
    .carousel.slide {
        margin: 0;
    }
    @include pdp-carousel;
    .show-more {
        display: none;
        @include breakpoint (small down) {
            display: none !important;
        }
        a {
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(18);
            display: inline-block;
            padding-bottom: rem-calc(3);
            margin-left: rem-calc(18);
            border-bottom: 2px solid $black;
            &::first-letter{
                text-transform: capitalize;
            }
        }
    }

    .breadcrumb {
        margin-left: 0;
        margin-bottom: rem-calc(12);
        line-height: rem-calc(15);

        @include breakpoint (small down) {
            margin: rem-calc(8 16);
            line-height: rem-calc(12);
        }
    }

    .pdpCarousel__thumbnail {
        margin: 0;
        cursor: pointer;
        max-width: 8.199121522693996vw;
        width: 8.199121522693996vw;

        img {
            max-width: 100%;
            border-radius: rem-calc(4);
        }
    }
}
.tezenis--fond-yoursize-link{
    .link-find-your-size{
        font-size: rem-calc(12);
    }
}

.modal-box-findYourSize-tool{
    max-width: rem-calc(951);
    border-radius: 0;
    overflow: hidden;

    @include breakpoint (medium down){
        max-width: 100%;
        width: 100vw;
        margin: 0;
        max-height: 100%;
        height: 100%;
    }

    .tingle-modal-box__content {
        padding: rem-calc(40);

        @include breakpoint (medium down){
            padding: rem-calc(17) 0;
        }

        &:before {
            @include breakpoint (medium down){
                content: '';
                position: absolute;
                display: block;
                top: 0;
                height: rem-calc(54);
                width: 100vw;
                border-bottom: rem-calc(1) solid $light-gray;
                background: $white;
            }
        }
    }
    .close-button{
        position: absolute;
        top: rem-calc(30);
        right: rem-calc(41);
        cursor: pointer;
        margin-right: 0;

        @include breakpoint (medium down){
            top: rem-calc(16);
            right: rem-calc(16);
        }

        &.back-icon {
            display: none;
            left: rem-calc(22);
            right: auto;

            @include breakpoint (medium down){
                display: block;
            }

            svg {
                fill: $white;
            }
        }
    }
    .content-asset-size-wrapper {
        overflow: auto;
        padding: rem-calc(8) rem-calc(16) rem-calc(110);
        max-height: 100vh;
    }
    .calculate-size-cell{
        display: flex;
        flex-direction: column;
        .calculate-size{
            margin-top: auto;
            width: 100%;
            @include breakpoint (large up){
                width: 70%;
                align-self: flex-end;
            }
            .button-calculate-size{
                border-radius: rem-calc(40);
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
    .find-your-size-image{
        padding-right: rem-calc(30);
        @include breakpoint (medium down){
            padding-right: rem-calc(10);
        }
    }
    .your-size-result{
        background: #FDCFF2;
        padding: rem-calc(15 30);
    }
    .wrongSize{
        background: #FDCFF2;
        padding: rem-calc(15 30);
    }
    .title-tool{
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        font-weight: 500;

        @include breakpoint (medium down){
            position: fixed;
            top: rem-calc(16);
            left: 0;
            margin-left: rem-calc(57);
        }

    }
    .text-tool{
        font-size: rem-calc(13);
        letter-spacing: rem-calc(0.3);
        padding-bottom: rem-calc(10);
        border-bottom: 1px solid $border-gray;
    }
    .fnSizeTable-table {
        border-spacing:0;
        width: 100%;
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
        table-layout: fixed;

        @include breakpoint (medium down){
            margin-top: rem-calc(52);
            margin-bottom: rem-calc(37);
            width: auto;
            overflow-x: auto;
        }

        th, td:first-child {
            font-weight: 500;
        }
        th, td {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            padding: rem-calc(16);
            text-align: center;
            border-bottom: rem-calc(1) solid $light-gray;

            &:not(:last-child) {
                border-right: rem-calc(1) solid $light-gray;
            }
        }
        tbody tr:last-child {
            td {
                border-bottom: 0;
            }
        }
    }

    .find-your-size-event {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: $global-weight-normal;

        @include breakpoint (medium down){
            border: rem-calc(1) solid $black;
            border-radius: rem-calc(43);
            text-decoration: none;
            width: 100%;
            display: block;
            text-align: center;
            padding: rem-calc(15);
            font-weight: 600;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
        }

        &:hover {
            @include breakpoint (medium down){
                background-color: rgba(0,0,0,0.2);
                border-color: #646464;
                color: #646464;
            }
        }
    }

    .find-your-size-event-wrapper {
        @include breakpoint (medium down){
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-top:  rem-calc(1) solid $light-gray;
            padding: rem-calc(16);
            background-color: $white;
            z-index: 1;
        }
    }

    .slidecontainer {
        width: 100%;
        position: relative;
    }

    .slider {
    -webkit-appearance: none;
    width: 100%;
    height: rem-calc(5);
    border-radius: rem-calc(5);
    background: #949494;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    }

    .slider:hover {
    opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: rem-calc(70);
        height: rem-calc(40);
        border-radius: rem-calc(20);
        background: $white;
        border: 1px solid #949494;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: rem-calc(70);
        height: rem-calc(40);
        border-radius: rem-calc(20);
        background: $white;
        border: 1px solid #949494;
        cursor: pointer;
    }
    .range-thumb {
        position: absolute;
        left: 0px;
        /* half :thumb width */
        top: rem-calc(-9);
        width: rem-calc(70);
        /* same as :thumb */
        height: rem-calc(40);
        /* same as :thumb */
        text-align: center;
        color: black;
        line-height: rem-calc(18);
        font-size: rem-calc(14);
        pointer-events: none;
        letter-spacing: rem-calc(0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        /* ignore mouse */
    }

    .range-thumb::before {
        content: attr(data-val) " ";
    }
}

.descriptionSidebar {
    &__container {
        @include breakpoint (ipad down) {
            padding: rem-calc(0 16);
        }
    }
}

#descriptionSidebar {
    top: rem-calc(130);
    &.descriptionSidebar--tezenis {
        top: 0;
        padding-top: 0;
        height: 100%;
        @include breakpoint (small down) {
            height: auto;
            border: none;
            top: unset;
            bottom: 0;
        }
        .promo-and-name-cell {
            width: 100%;
        }
        .neg-margin {
            @include breakpoint (small down) {
                padding: rem-calc(3 7 3 9);
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
        }
        .descriptionSidebar__details {

            .cell.product-name {
                .tezenis--fond-yoursize-link{
                    .link-find-your-size{
                        font-size: rem-calc(12);
                    }
                }
            }
            .name-wishlist {
                display: flex;
                flex-direction: row;
                width: 100%;
                @include breakpoint (ipad down) {
                    margin-top: 0;
                }
                h1.product-name {
                    line-height: rem-calc(27);
                    font-weight: 500;
                    font-size: rem-calc(18);
                    flex: 1;
                    margin-bottom: rem-calc(8);
                }
                .add-to-wish-list {
                    flex-basis: 55px;
                    border-color: #fff;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    background: #fff;
                    outline-color: #fff;
                    height: rem-calc(20);
                    @media screen and (max-width: 768px) {
                        height: rem-calc(24);
                        flex-basis: rem-calc(24);
                        top: rem-calc(4);
                    }
                    svg {
                        position: absolute;
                        top: rem-calc(4);
                        right: 0;
                        width: auto;
                        height: auto;
                        max-width: rem-calc(22);
                        transition: all .2s ease-out;
                        @include breakpoint (small down) {
                            top: 0;
                            max-width: rem-calc(24);
                            width: rem-calc(24);
                            height: rem-calc(24);
                        }
                        &.empty {
                            opacity: 1;
                            visibility: visible;
                            pointer-events: auto;
                        }
                        &.full {
                            opacity: 0;
                            visibility: hidden;
                            pointer-events: none;
                            fill: $water-green;
                        }
                    }
                    &:hover {
                        background: #fff;
                        color: $black;
                        svg {
                            &.empty {
                                fill: $water-green;
                            }
                        }
                    }
                    &.added {
                        background: #fff;
                        color: $primary;
                        svg {
                            &.empty {
                                opacity: 0;
                                visibility: hidden;
                                pointer-events: none;
                            }
                            &.full {
                                opacity: 1;
                                visibility: visible;
                                pointer-events: auto;
                            }
                        }
                    }
                }
            }

            .m-d-price-container {
                margin-top: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                & + .cc-omnibusStack {
                    margin: rem-calc(4 0 6 0);
                    gap: rem-calc(4);
                }
                @include breakpoint (small down) {
                    width: 100%;
                    text-align: left;
                    margin-bottom: rem-calc(8);
                    & + .cc-omnibusStack {
                        margin: rem-calc(-4 0 6 0);
                    }
                }
                @include price-tezenis;
            }
            .cc-omnibusStack .cc-omnibusInfo {
                line-height: unset;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                &-icon {
                    content: url(../../images/tooltip-grey.svg);
                    cursor: pointer;
                    &:hover + .tooltip {
                        opacity: 1;
                        visibility: visible;
                        z-index: 99999;
                    }
                }
                &-tooltip {
                    min-width: rem-calc(246);
                    width: rem-calc(246);
                    font-size: rem-calc(10);
                    line-height: normal;
                    letter-spacing: rem-calc(0.2);
                    padding: rem-calc(16);
                    transform: translate(-46%, -86%);
                    @include breakpoint(medium down) {
                        transform: translate(-37%, -86%);
                    }
                }
            }
            @include product-info-details;
        }
        .cart-and-ipay {
            margin-bottom: rem-calc(9);

            @include breakpoint (small down) {
                margin-top: rem-calc(26);
                margin-bottom: 0;

                .add-to-cart-sticky-bar {
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: -100%;
                    z-index: 2;
                    background-color: $white;
                    border-top: 1px solid $light-gray;
                    padding: rem-calc(16 16 0);
                }
            }
        }

        .descriptionSidebar__details {
            .size-selected-on-sale {
                color: $sale;

                &:before {
                    content: '';
                    display: inline-block;
                    width: rem-calc(4);
                    height: rem-calc(4);
                    background-color: $sale;
                    border-radius: 100%;
                    margin: rem-calc(0 3 2 3);
                }
            }
            .m-d-price-container {
                .price--tezenis {
                    margin-right: 0;

                    span {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        letter-spacing: rem-calc(0.2);

                        &.hidden {
                            display: none;
                        }
                    }

                    &.hidden {
                        display: none;
                    }

                    .price-second-currency{
                        margin-top: rem-calc(4);
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        font-weight: 400;

                        .double-currency-sales{
                            &.discount-sale {
                                display: inline-block;
                                margin-left: rem-calc(4);
                                color: #D10B82;
                            }
                        }
                        .double-currency-sales, .double-currency-list, .discount-value-percentage{
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            font-weight: 400;
                        }
                    }

                    .cc-CountryWithDifferentTemplate {
                        .strike-through {
                            margin-left: rem-calc(8);
                        }
                        .discount-value-percentage {
                            margin-left: rem-calc(6);
                        }
                    }
                }

                .strike-through ~ .sales {
                    color: $sale;
                    margin-left: rem-calc(8);
                }

                .label-sale {
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    letter-spacing: rem-calc(0.2);
                    color: $sale;
                    margin-left: rem-calc(8);
                    margin-bottom: 0;
                }
            }
            .attrValuesSlider{
                width: auto;
                height: auto;
                overflow-y: hidden;
            }

            .attribute-container {
                @include breakpoint (small down) {
                    position: relative;
                    top: 0;
                    bottom: 0;
                }
                .accordion-panel-group {
                    &[data-attr="color"] {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-bottom: rem-calc(24);
                        margin-left: 0;
                        border-bottom: rem-calc(1) solid $light-gray;
                        margin-bottom: rem-calc(24);

                        @include breakpoint (small down) {
                            width: auto;
                            margin: rem-calc(16 0 0);
                            padding-top: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        * {
                            overflow: visible;
                        }

                        .otherColors {
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            font-weight: $global-weight-normal;
                            text-decoration: underline;
                            text-underline-offset: rem-calc(4);
                            margin-left: rem-calc(4);

                            @include breakpoint (small down) {
                                margin-left: rem-calc(9);
                            }
                        }

                        .hideGreatThanFive:nth-child(n + 5) {
                            display: none;
                        }
                    }
                    &[data-attr="size"] {
                        padding-top: rem-calc(14);

                        @include breakpoint (small down) {
                            padding-top: rem-calc(19);
                        }

                        .size-guide {
                            &-icon {
                                width: rem-calc(15);
                                height: rem-calc(15);
                                margin-right: rem-calc(8);
                            }

                            &-btn {
                                display: inline-block;
                                margin-top: rem-calc(8);
                                font-weight: $global-weight-normal;
                                text-decoration: underline;
                                text-underline-offset: rem-calc(4);
                                border: 0;
                                margin-bottom: rem-calc(17);
                                @include breakpoint (small down) {
                                    margin: rem-calc(16 0 0);
                                }
                            }
                        }
                    }
                    .slider-values-container {
                        .color-variation {
                            width: 3.806734992679356vw;
                            height: 5.856515373352855vw;
                            margin-left: 0;
                            margin-bottom: 0;
                            // Someone misused important in another location
                            margin-right: rem-calc(8)!important;

                            @include breakpoint (small down) {
                                transform: none;
                                width: 13.86666666666667vw;
                                height: 21.33333333333333vw;
                            }

                            .swatch-circle {
                                width: 100%;
                                height: 100%;
                                background-size: 100%;
                                background-repeat: no-repeat;
                                background-color: #F2F2EA;

                                &:after {
                                    width: 100%;
                                    height: 100%;
                                    border-color: transparent;
                                }

                                &.selected {
                                    &:after {
                                        border-color: $medium-gray;
                                    }

                                    & ~ img {
                                        display: none;
                                    }
                                }
                            }

                            &.color-on-sale {
                                .swatch-circle {
                                    &:before {
                                        content: '';
                                        width: rem-calc(4);
                                        height: rem-calc(4);
                                        border-radius: 100%;
                                        background: $sale;
                                        display: block;
                                        position: absolute;
                                        bottom: rem-calc(4);
                                        left: 50%;
                                        transform: translateX(-50%);

                                    }
                                }

                                &:hover {
                                    .tooltip-sale-wrapper {
                                        @include breakpoint (small up) {
                                            visibility: visible;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .tooltip {
                                &:hover {
                                    .tooltiptext {
                                       display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                &.quantity {
                    margin-top: rem-calc(24);
                    padding-top: rem-calc(23);
                    border-top: rem-calc(1) solid $light-gray;
                    border-radius: 0;

                    .accordion-panel-group {
                        // Someone misused important in another location
                        display: flex!important;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: nowrap;
                        border: 0;
                        padding: 0;
                        margin-bottom: rem-calc(16);
                    }

                    .quantity-value {
                        border-radius: rem-calc(20);
                        border: rem-calc(1) solid $light-gray;
                        padding: rem-calc(7 21);
                        text-align: center;
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        margin: 0 rem-calc(24);
                    }

                    .quantity-circle {
                        width: rem-calc(24);
                        height: rem-calc(24);
                        border-radius: 100%;
                        background-color: $water-green;
                        color: $black;
                        padding: 0;
                        font-size: rem-calc(21);
                        font-weight: $global-weight-normal;
                    }
                }
            }

            .size-label {
                font-weight: $global-weight-normal;
                margin-bottom: 0;
            }

            .boolean-field-wrapper {
                display: inline-block;
            }

            .boolean-field {
                margin-bottom: 0;

                .radio-label {
                    width: auto;
                    height: auto;
                    padding: rem-calc(8 16);
                    border-radius: rem-calc(20);
                    line-height: rem-calc(18);
                    font-size: rem-calc(12);
                    letter-spacing: rem-calc(0.2);
                    @include breakpoint (small down) {
                        margin-right: rem-calc(8);
                    }
                }
                .notifyMe svg.alertNotify {
                    height: rem-calc(13);
                }
                .notifyMe,
                .lowInStock {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: rem-calc(4);

                    svg.alertNotify {
                        display: block;
                        margin-left: rem-calc(5);
                        width: rem-calc(13);
                        height: rem-calc(13);
                        margin-top: 0;
                    }
                }

                &:hover + .tooltip-stock-wrapper {
                    @include breakpoint (small up) {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .tooltip-stock-wrapper {
                display: block;
                position: absolute;
                width: auto;
                left: calc(50%);
                transform: translateX(-50%);
                bottom: rem-calc(-31);
                padding: rem-calc(4 8);
                background: $black;
                border-radius: rem-calc(4);
                visibility: hidden;
                opacity: 0;
                z-index: 3;
                white-space: nowrap;

                .tooltip-stock-text {
                    position: relative;
                    display: block;
                    font-size: rem-calc(10);
                    line-height: rem-calc(15);
                    font-weight: $global-weight-normal;
                    color: $white;
                    text-align: center;

                    &:before {
                        content: '';
                        position: absolute;
                        top: rem-calc(-12);
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 0;
                        border-bottom: rem-calc(30) solid $black;
                        border-left: rem-calc(18) solid transparent;
                        border-right: rem-calc(18) solid transparent;
                    }

                    span {
                        position: relative;
                        background: $black;
                        z-index: 2;
                    }
                }
            }

            .tooltip-hidden {
                display: none;
            }

            .add-to-cart-actions {
                .button.add-to-cart {
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    padding: rem-calc(15 0 16);
                    height: auto;
                    margin-bottom: rem-calc(16);
                    width: 100%;
                    margin-right: 0;
                }
                .button-smart-gift{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &_image{
                        width: rem-calc(20);
                        height: rem-calc(20);
                        margin-right: rem-calc(8);
                        
                    }
                    &:hover{
                        .button-smart-gift_image{
                            opacity: .6;
                        }
                    }
                }
            }
            .cart-and-ipay {
                .button.button-primary:not(:disabled):not(.disabled).find-in-store-btn.button {
                    height: auto;
                    margin-top: rem-calc(16);
                    margin-bottom: 0;

                    @include breakpoint (small down) {
                        margin-top: rem-calc(25);
                    }

                    span {
                        font-weight: $global-weight-normal;
                        line-height: rem-calc(18);
                        text-decoration: underline;
                        text-underline-offset: rem-calc(4);

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .product-availability {
                margin-bottom: 0;
            }

            .fit-text {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                @include breakpoint (small down) {
                    margin: rem-calc(24 0);
                }
            }

            .item-product-code {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                padding-bottom: 0;
                color: #646464;
            }

            .pills-wrapper {
                display: flex;
                flex-direction: row;
                gap: rem-calc(8);

                .label-cta {
                    margin-bottom: rem-calc(8);
                    @include breakpoint (large up) {
                        margin-bottom: rem-calc(12);
                    }

                    &.pills-message__sold-out,
                    &.bg-white {
                        border: rem-calc(1) solid $light-gray;
                        color: $black;
                    }

                    &.pills-message__coming-soon {
                        background-color: $black;
                    }
                }

                .pdp-message-custom-label {
                    margin-bottom: rem-calc(8);

                    & > div {
                        position: relative;
                        border-radius: rem-calc(4);
                        padding: rem-calc(4 12);
                        color: $white;
                        background-color: $sustainability;

                        p {
                            animation: none;
                            margin-bottom: 0;
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            padding-right: 0;

                            @include breakpoint (small down) {
                                font-size: rem-calc(10);
                                line-height: rem-calc(10);
                            }
                        }
                    }
                }
            }

            .pdp-size-accordion {
                .accordion-content {
                    @include breakpoint (small down) {
                        display: none;
                        padding: rem-calc(19 16);
                    }

                    .sizes-container {
                        display: flex;
                        flex-flow: row wrap;
                        margin-bottom: rem-calc(16);

                        .boolean-field-wrapper {
                            margin-bottom: 0;
                        }
                    }

                    .modal-select-size-header {
                        @include breakpoint (small down) {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: rem-calc(22);
                        }

                        .modal-select-size-title {
                            font-size: rem-calc(14);
                            line-height: rem-calc(21);
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        .close-modal-size {
                            max-width: rem-calc(20);
                            cursor: pointer;
                        }
                    }
                }

                &.open-modal {
                    .accordion-content {
                        @include breakpoint (small down) {
                            display: block;
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: $white;
                            z-index: 10;
                            overflow-y: auto;
                            padding-bottom: rem-calc(85);
                            border-radius: 0;
                        }
                    }
                }

                .add-to-cart-button-wrapper {
                    position: fixed;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 2;
                    background: $white;
                    border-top: rem-calc(1) solid $light-gray;
                    padding: rem-calc(16);

                    button{
                        width: 100%;

                        &.disabled {
                            background-color: $dark-gray;
                            color: $medium-light-gray;
                        }
                    }
                }

                .accordion-title {
                    @include breakpoint (small down) {
                        display: none;
                    }

                   &.size-selected {
                        @include breakpoint (small down) {
                            display: flex;
                            margin: 0;
                            border-bottom: rem-calc(1) solid $light-gray;
                            padding-top: rem-calc(24);
                            padding-bottom: rem-calc(24);
                        }

                        .arrow-down {
                            @include breakpoint (small down) {
                                transform: rotate(90deg);
                            }
                        }
                   }
                }
            }
        }

        .review-summary-pdp-wrapper {
            margin-top: rem-calc(6);
            margin-bottom: 0;

            @include breakpoint (small down) {
                margin-top: rem-calc(8);
                margin-bottom: rem-calc(8);
            }

            .tt-c-rating {
                margin-right: rem-calc(6);
            }

            .tt-c-rating__star {
                margin-right: 0;

                .tt-o-icon {
                    width: rem-calc(12);
                    fill: $black;
                }
                .tt-o-icon--star--half {
                    stroke: $black;
                }
            }

            .total-reviews {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-normal;
                text-decoration: underline;
                text-underline-offset: rem-calc(4);
            }
        }

        .default-code-color {
            margin-top: rem-calc(16);
            padding-top: rem-calc(24);
            border-top: rem-calc(1) solid $light-gray;

            .code-color {
                font-weight: 500;
            }
        }
    }

    .delivery-and-return-btn {
        padding: 0;
        text-align: left;
        border: none;
        outline: none;
        background: $white;
        color: $black;
        height: auto;
        margin-top: rem-calc(22);

        span {
            position: relative;
            font-size: rem-calc(12);
            font-weight: $global-weight-normal;
            margin-left: rem-calc(8);
            line-height: rem-calc(18);
            text-decoration: underline;
            text-underline-offset: rem-calc(4);
        }
    }

    .tooltip-sale-wrapper {
        position: absolute;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem-calc(-31);
        padding: rem-calc(4 8);
        background: $sale;
        border-radius: rem-calc(4);
        visibility: hidden;
        opacity: 0;
        white-space: nowrap;

        .tooltip-sale-text {
            position: relative;
            display: block;
            font-size: rem-calc(10);
            line-height: rem-calc(15);
            font-weight: $global-weight-normal;
            color: $white;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                top: rem-calc(-12);
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
                border-bottom: rem-calc(30) solid $sale;
                border-left: rem-calc(18) solid transparent;
                border-right: rem-calc(18) solid transparent;
            }

            span {
                position: relative;
                background: $sale;
                z-index: 2;
            }
        }
    }
}

.mobile-container {
    &--tezenis {
        @include breakpoint(small down) {
            .description-and-detail {
                margin-bottom: 0;
            }
            .item-description {
                font-size: rem-calc(13);
                font-weight: 400;
                line-height: rem-calc(20);
                margin-bottom: rem-calc(0);
                .content {
                    padding: 0;
                    min-height: auto;
                }
            }
            .item-product-code {
                font-size: rem-calc(13);
                font-weight: 500;
                line-height: rem-calc(28);
                color: #999999;
                margin-bottom: rem-calc(10);
            }
            .item-composition {
                font-size: rem-calc(13);
                line-height: rem-calc(22);
                .content {
                    padding: 0;
                    min-height: auto;
                    .js-composition-info {
                        font-style: italic;
                        font-size: rem-calc(12);
                        line-height: rem-calc(15);
                        > .cell {
                            width: 100%;
                            &:first-of-type {
                                margin-bottom: rem-calc(7);
                            }
                            .comp-material {
                                display: inline-block;
                                margin-right: rem-calc(7);
                                font-style: normal;
                            }
                            .icon-wash {
                                float: none !important;
                                margin-right: rem-calc(3);
                                padding: 0;
                                svg {
                                    top: auto;
                                    transform: scale(0.8);
                                    transform-origin: top left;
                                }
                            }
                        }
                    }
                }
            }
            .find-in-store-btn {
                font-size: rem-calc(12);
                font-weight: 400;
                letter-spacing: 0.3px;
                border: none;
                background-color: white;
                color: black;
                padding: unset;
                margin-bottom: rem-calc(24);
                span {
                    margin-left: rem-calc(8);
                }
                svg {
                    height: 100%;
                }
                &.not-enabled {
                    color: #949494;
                    pointer-events: none;
                    border: 1px solid #949494;
                }
            }
        }
    }
}

.order-info {
    width: 100%;
    margin-bottom: rem-calc(30);
    @include breakpoint(small down) {
        margin-top: rem-calc(10);
        margin-bottom: 0;
    }
    &__single {
        display: flex;
        padding: rem-calc(15 0);
        border-bottom: 1px solid #E9E9E9;
        @media (max-width: 1440px) {
            padding: rem-calc(10 0);
        }
        &:last-of-type {
            border-bottom: none;
        }
        .icon {
            flex-basis: rem-calc(55);
            text-align: center;
            svg {
                width: auto;
                height: auto;
            }
        }
        .text {
            flex: 1;
            color: #FF6699;
            font-size: rem-calc(13);
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: rem-calc(12);
            }
        }
    }
}

.close-mobile-color {
    @include breakpoint (small down) {
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(13);
        width: auto;
        height: auto;
    }
}

.coming-soon-banner {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    z-index: 1;
    padding: rem-calc(40 0);
    @include breakpoint (small down) {
        top: 34%;
        padding: rem-calc(30 0);
    }
    svg {
        top: 0;
        width: auto;
        height: auto;
        margin-bottom: rem-calc(15);
        @include breakpoint (small down) {
            transform: scale(0.8);
            transform-origin: top center;
            margin-bottom: 0;
        }
    }
    h4 {
        font-size: rem-calc(18);
        font-weight: 500;
        line-height: rem-calc(24);
        @include breakpoint (small down) {
            font-size: rem-calc(17);
            line-height: 1;
        }
    }
    span {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(24);
        @include breakpoint (small down) {
            font-size: rem-calc(13);
            line-height: 1;
        }
    }
    .button.add-to-wish-list.button-primary,
    .button.add-to-wish-list.expanded.added {
        background-color: transparent !important;
        padding: 0;
        border: none;
        margin-bottom: 0;
        margin-top: rem-calc(35);
        outline: none;
        @include breakpoint (small down) {
            margin-top: rem-calc(25);
        }
        > span {
            color: $black;
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(18);
            border-bottom: 2px solid $black;
            display: inline-block;
            padding-bottom: rem-calc(3);
            @include breakpoint (small down) {
                font-size: rem-calc(13);
            }
        }
    }
    &--small {
        svg {
            transform: scale(0.7);
            transform-origin: top center;
            margin-bottom: 0;
        }
        h4 {
            font-size: rem-calc(15);
            line-height: 1;
        }
        span {
            font-size: rem-calc(12);
            line-height: 1;
        }
        .button.add-to-wish-list.button-primary,
        .button.add-to-wish-list.expanded.added {
            > span {
                font-size: rem-calc(12);
                border-bottom: 1px solid $black;
                padding-bottom: rem-calc(2);
            }
        }
    }
}

.soldout-banner {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    z-index: 1;
    padding: rem-calc(40 0);
    @include breakpoint (small down) {
        top: 45%;
        padding: rem-calc(30 0);
    }
    i {
        top: 0;
        width: auto;
        height: auto;
        margin-bottom: rem-calc(15);
        @include breakpoint (small down) {
            transform: scale(0.8);
            transform-origin: top center;
            margin-bottom: rem-calc(8);
        }
    }
    h4 {
        font-size: rem-calc(18);
        font-weight: 500;
        line-height: rem-calc(24);
        @include breakpoint (small down) {
            font-size: rem-calc(17);
            line-height: 1;
        }
    }
}

.suggestedProductsContainer {
    &--tezenis {
        margin-top: rem-calc(70);
        padding-right: rem-calc(40);
        display: none;
        @media screen and (max-width: 1024px) {
            margin-right: rem-calc(20);
        }
        h6 {
            font-size: rem-calc(24);
            font-weight: 600;
            display: block;
            text-align: center;
        }
        .carousel-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem-calc(27);

            svg {
                path {
                    fill: $white;
                }
            }

            &__button-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                gap: rem-calc(25);

                @include breakpoint(small down) {
                    display: none;
                }

                .slick-arrow {
                    cursor: pointer;
                }
            }
        }
        .crosssell-view {
            margin-right: rem-calc(8);

            .price-container {
                &.hidden {
                    display: none;
                }
            }
        }
    }
}

.prod-select-size-modal{
    @include breakpoint (medium up){
        .modal-title-selectsize{
            margin-bottom: rem-calc(16);
            font-variant: normal;
            font-style: normal;
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            font-weight: 600;
            font-family: Poppins;
            letter-spacing: rem-calc(0.42);
            color: $black;
            opacity: 1;
        }
        .add-to-cart.button{
            border-radius: rem-calc(40);
            padding: rem-calc(12 30);
        }

        li {
            &:nth-child(6n+1){
                button{
                    margin-left: 0px;
                }
            }
            &:nth-child(6n), &:last-child{
                button{
                    margin-right: 0px;
                }
            }
        }

        li button{
            margin: rem-calc(6);
            width: rem-calc(56);
            height: rem-calc(56);
            display: inline-block;
            line-height: rem-calc(46);
            position: relative;
            font-weight: 400;
            border: 1px solid #ccc;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            img{
                display: none;
                position: absolute;
                top: rem-calc(-6);
                right: rem-calc(-6);
                width: rem-calc(20);
                height: rem-calc(20);
            }
            &.selected{
                img{
                    display: block;
                }
            }

            &.notifyMe {
                min-width: fit-content;
                color: gray;
                display: flex;
                border-radius: 50%;
                align-items: center;
                svg {
                    &.alertNotify{
                        display: block;
                        margin-left: rem-calc(5);
                        width: rem-calc(11);
                    }
                }
            }
        }

    }
}

.size-modal-mobile{
    .tingle-modal-box{
        @include breakpoint (medium down){
            border-radius: rem-calc(5);
        }
        .tingle-modal-box__content{
            @include breakpoint (medium down){
                padding: rem-calc(16 16 70 16);
            }
            .modal-title-selectsize{
                @include breakpoint (medium down){
                    text-align: left;
                    font-size: rem-calc(14);
                }
            }
            #smartgiftSizeSelection{
                @include breakpoint (medium down){
                    display: flex;
                    justify-content: flex-start;
                    margin-top: rem-calc(28);
                }
                li{
                    @include breakpoint (medium down){
                        padding-right: rem-calc(10);
                        padding-top: rem-calc(10);
                    }
                }
            }
        }
    }
    .size-selector{
        color: black;
        @include breakpoint (medium down){
            margin: 0;
        }
    }
}

.pricing-vat{
    margin-bottom: 0;
    margin-left: rem-calc(10);
    color: gray;
    @include breakpoint(small down){
        font-size: rem-calc(8) !important;
        margin-left: 0;
    }
}

.product-detail {
    .cart-and-ipay .add-to-cart,
    .attribute-container.quantity,
    .cart-and-ipay .find-in-store-btn,
    .add-mobile-regular-add,
    .mobile-size-guide {
        display: block;
    }
    .cart-and-ipay .find-in-store-soldout,
    .find-in-store-soldout.mobile-only,
    .soldout-banner,
    .add-mobile-soldout-add {
        display: none;
    }

    &.is-soldout {
        .find-in-store-soldout + .add-to-cart,
        .attribute-container.quantity,
        .cart-and-ipay .find-in-store-btn,
        .add-mobile-regular-add,
        .mobile-size-guide {
            display: none;
        }
        .cart-and-ipay .find-in-store-soldout,
        .find-in-store-soldout.mobile-only,
        .soldout-banner,
        .add-mobile-soldout-add {
            display: block;
        }
    }
    .find-in-store-soldout {
        width: rem-calc(398);
        height: rem-calc(72);
        padding-top: rem-calc(25);
        padding-bottom: rem-calc(25);
        margin-top: rem-calc(64);
        margin-bottom: rem-calc(24);
        &.mobile-only{
            width: rem-calc(382);
            height: rem-calc(52);
            padding-top: rem-calc(14);
            padding-bottom: rem-calc(14);
            margin-top: rem-calc(32);
            margin-bottom: rem-calc(30);
        }
    }
}

.smartGiftModal{
    .tingle-modal-box{
        overflow: auto;
        max-height: 90vh;
        @include breakpoint (medium up){
            width: rem-calc(828);
            max-width: unset;
        }
    }
    .tingle-modal-box__content{
        padding: rem-calc(40 64)!important;
        @include breakpoint (medium down){
            padding: rem-calc(32 0)!important;
        }
    }
    .tingle-modal__close.smart-gift-close{
        display: block;
        img{
            position: absolute;
            right: rem-calc(72);
            width: rem-calc(16);
            height: rem-calc(16);
            top: rem-calc(56);
            cursor: pointer;
            @include breakpoint (medium down){
                right: rem-calc(21);
                top: rem-calc(36);
            }
        }
    }
    .modalSmartGift{
        .smart-gift-help-content{
            @include breakpoint (medium down){
                padding: rem-calc(0 32);
                margin-left: 0;
                margin-bottom: 0;
            }
        }
        .smart-gift-help-title{
            text-align: center;
            font-size: rem-calc(24);
            line-height: rem-calc(36);
            border-bottom: 1px solid #E8E8E8;
            padding-bottom: rem-calc(12);
            font-weight: 700;
            @include breakpoint (medium down){
                font-size: rem-calc(18);
                line-height: rem-calc(27);
                text-align: left;
                padding-left: rem-calc(32);
            }
        }
        .item-help{
            font-weight: 600;
            font-size: rem-calc(16);
            line-height: rem-calc(36);
        }
        .item-help-description{
            margin-bottom: rem-calc(20);
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            color: #777777;
            @include breakpoint (medium down){
                font-size: rem-calc(15);
                line-height: rem-calc(22.5);
            }
        }
    }
}
.pdpCarousel__wrapper.imagecarousel-container{
    .slick-cloned {
        .playVideoIcon.playVideoIconPrimary {
            display: none;
        }
    }
    .productVideoContaner {
        position: relative;
        .playVideoIcon.playVideoIconPrimary{
            top: 50%;
            opacity: 1;
        }
    }
    &.world {
        .productVideoContaner {
            .playVideoIcon.playVideoIconPrimary {
                top: 46%;
            }
        }
    }
}

.price-info-pdp-container {
    &:not(.hidden) {
        display: inline-block;
    }
    .omnibus {
        .product-quickview & {
            margin-bottom: rem-calc(-16);
        }

        .search-results & {
            @include breakpoint (medium down) {
                font-size: rem-calc(10);
                line-height: rem-calc(16);
                margin: rem-calc(4 0 0);
            }
        }

        .product-info-row & {
            font-size: rem-calc(10);
            color: $medium-gray;
            margin-top: rem-calc(4);
            @include breakpoint (medium down) {
                font-size: rem-calc(8);
                line-height: rem-calc(14);
            }
        }

        .crosssell-view & {
            margin-top: rem-calc(-5);
            @include breakpoint (medium down) {
                font-size: rem-calc(10);
            }
        }
    }

    .einstein-carousel &, .cc-einstein-carousel & {
        width: 100%;

        .omnibus {
            color: $medium-gray;
            font-size: rem-calc(12);
            line-height: rem-calc(19);
            margin-top: rem-calc(8);
        }
    }

    #descriptionSidebar & {
        &:not(.hidden) {
            display: inline;
            &.cc-omnibusStack-item {
                display: inline-flex;
                gap: rem-calc(4);
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }

}

.tooltip-omnibus {
    &-modal {
        .tingle-modal-box {
            border: 1px solid $water-green;
            border-radius: rem-calc(8);
            &__content {
                position: relative;
                padding: rem-calc(24);
            }
        }
    }
    &-info {
        .modal-close {
            display: none;
            top: rem-calc(18);
            right: rem-calc(20);
            font-size: unset;
        }

        .recap-store-info-text {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
        }
    }
}
.authenticity.with-accordion, .description-cell.with-accordion, #det.with-accordion{
    .accordion-arrow{
        position: absolute;
        align-items: center;
        height: rem-calc(22);
        right: 0;
        cursor: pointer;
        svg{
            transform: rotateZ(-90deg);
            transition: all 0.4s ease-out;
        }
    }
    p{
        font-size: rem-calc(12);
        font-weight: 400;
    }
    &.active{
        .accordion-arrow svg{
            transform: rotateZ(90deg);
        }
    }
}
// remove klarna label
#test-badge-element-id {
    display: none!important;
}

.accordion-wrapper {
    .accordion-title {
        position: relative;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .arrow-down {
            width: rem-calc(16);
            height: rem-calc(8);
            fill: $white;
            transform: rotate(-180deg);
        }
    }
    .accordion-content {
        display: none;
        padding-top: rem-calc(16);
    }

    &.active-accordion {
        .accordion-content {
            display: block;
        }

        .accordion-title {
            .arrow-down {
                transform: none;
            }
        }
    }
}
.description-and-detail {
    border-top: rem-calc(1) solid $light-gray;

    .accordion-wrapper {
        padding: rem-calc(24) 0;
        border-bottom: rem-calc(1) solid $light-gray;

        .accordion-title {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            font-weight: 500;
        }

        &.authenticity {
            * {
                color: $sustainability;
            }
        }

        .accordion-content {
            line-height: rem-calc(21);
            &, & .value {
                font-size: rem-calc(14);
            }
        }

        .item-product-fabric,
        .item-product-logistic {
            margin-top: rem-calc(16);
        }
    }

    .composition-info {
        display: flex;
        flex-direction: column;
        gap: rem-calc(20);

        .comp-material {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
        }
    }
}

.productSwatchModal {
    .tingle-modal-box {
        height: 100%;
        max-height: 100vh;
        width: unset;

        @include breakpoint (large down){
            width: 100%;
        }

        &__content {
            @include breakpoint (large up){
                width: rem-calc(590);
            }
            @include breakpoint (small down) {
                padding-top: rem-calc(16);
            }
        }
    }

    .color-modal-header {
        margin-bottom: rem-calc(40);
        span {
            font-size: rem-calc(12);
            line-height: rem-calc(21);
        }

        button {
            span {
                font-size: rem-calc(37);
                font-weight: 100;
            }
        }
    }

    .modal-color-header {
        &__h4 {
            margin-bottom: 0;
        }
    }
}
.productSwatch {
    // Someone misused important in another location
    max-width: rem-calc(610)!important;

    .tingle-modal-box__content {
        padding-top: 0;
        @include breakpoint (small down) {
            padding: rem-calc(0 16 16);
        }

        .modal-color-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: rem-calc(24 0);
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            background-color: white;
            z-index: 1;
            @include breakpoint (small down) {
                padding: rem-calc(16 0);
            }

            h4 {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: 500;
                letter-spacing: rem-calc(0.2);
            }
        }

        .tingle-modal__closeIcon {
            cursor: pointer;
            svg {
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }

        .color-variation {
            width: unset;
            height: auto;
            margin: rem-calc(0 0 24 0);
            display: flex;
            flex-direction: column;

            img {
                display: none;
            }

            .tooltiptext {
                margin-top: rem-calc(8);
            }
        }

        .swatch-circle {
            width: unset;
            height: unset;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: rem-calc(4);
            background-color: #F2F2EA;
            background-size: cover;
            aspect-ratio: 0.6517057690128074;

            &.selected {
                border: 1px solid $black;
            }
        }

        .attribute-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: rem-calc(0 16);
            @include breakpoint (ipad down) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            .tooltip-sale-text,
            .tooltiptext {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-normal;
            }

            .tooltip-sale-text {
                color: $sale;
            }
        }
    }
}

.pdpCarousel__slider-container {
    @include breakpoint(large up) {
        border-radius: rem-calc(4);
        &:hover {
            .pd-scrolling-text__content-text-p {
                @include breakpoint (small up) {
                    -webkit-animation-play-state: paused;
                }
            }
        }
    }
}

.wrapperModalNotifyMe,
.productSwatchModal {
    background: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(10px);
    align-items: flex-end;

    .tingle-modal-box {
        background-color: $white;
        box-shadow: none;
        border-radius: 0;
    }
}

.wrapperModalNotifyMe {
    z-index: 1020;
    align-items: flex-end;

    .notifyMe-header {
        display: flex;
        flex-direction: row;
        align-content: center;
        gap: rem-calc(23);
        margin-bottom: rem-calc(48);

        @include breakpoint (small down) {
            margin-bottom: rem-calc(40);
        }

        svg {
            fill: $white;
        }

        .ico-close {
            width: rem-calc(24);
            height: rem-calc(24);
            cursor: pointer;
        }
    }

    .tingle-modal-box {
        height: 100%;
        max-height: 100vh;
        margin: 0;
        max-width: rem-calc(459);

        @include breakpoint (small down) {
            width: 100vw;
            max-width: none;
        }
    }
    .tingle-modal-box__content {
        padding: rem-calc(24 40 24 24);
        @include breakpoint (small down) {
            padding: rem-calc(16);
        }
    }

    .tingle-modal__close {
        display: none;
    }

    .titleComingSoon,
    .titleNotComingSoon {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        font-weight: 500;
        margin-bottom: 0;
    }

    .subTitleNotComingSoon,
    .subTitleComingSoon {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        font-weight: 400;
        margin-bottom: rem-calc(24);

        @include breakpoint (small down) {
            margin-bottom: rem-calc(24);
        }
    }

    .form-item {
        width: 100%;
    }

    .notifyBody {
        margin-bottom: rem-calc(24);

        p {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            font-weight: 400;
            margin-right: rem-calc(8);
            margin-bottom: 0;
        }
        .notifyMe-selectedSize {
            display: inline-block;
            border: rem-calc(1) solid $light-gray;
            border-radius: 20px;
            padding: rem-calc(8 16);
            margin-left: rem-calc(8);
            font-weight: 500;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
        }
    }

    .input-group {
        margin-bottom: rem-calc(4);
    }

    .emailNotify {
        border: 0;
        border-bottom: rem-calc(1) solid $light-gray;
        padding: rem-calc(8) 0;
        margin-bottom: 0;

        &.error {
            border-color: $error !important; /* To remove this !important, the UI Kit scss needs to be reviewed */
        }
    }

    .emailError,
    .duplicateError {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        color: $error;
        margin-top: rem-calc(-16);
    }

    .input-group-button {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        z-index: 8;
        padding: rem-calc(24);
        border-top: 1px solid $light-gray;

        @include breakpoint (small down) {
            padding: rem-calc(16);
        }

        .submitNotify {
            position: relative;
            transform: none;
            background-color: $black;
            color: $white;
            text-decoration: none;
            padding: rem-calc(16 32);
            width: 100%;
            border-radius: rem-calc(43);
            font-size: rem-calc(14);
            border: 0;

            &.disabled {
                background: #646464;
                color: #C4C0C0;
                pointer-events: all;
            }

            @include breakpoint (small down) {
                padding: rem-calc(17.5 32);
                margin: 0;
            }
        }
    }
}

.cc-pdp__zoomImage__slider,
.primary-images {
    .swiper-pagination-progressbar {
        position: absolute;
        bottom: rem-calc(16);
        left: rem-calc(16);
        right: rem-calc(16);
        background: rgba(255, 255, 255, 0.3);
        mix-blend-mode: normal;
        border-radius: rem-calc(1.5);
        height: rem-calc(3);
        z-index: 1;

        @include breakpoint (small down) {
            bottom: rem-calc(33);
        }

        .swiper-pagination-progressbar-fill {
            display: block;
            height: rem-calc(3);
            background: $white;
            border-radius: rem-calc(1.5);
            transform-origin: left top;
        }
    }
}

.cc-pdp__zoomImage__slider {
    .swiper-pagination-progressbar {
        @include breakpoint (large up) {
            display: none;
        }
    }
}

[data-action="Product-Show"] {
    .findinstore-wrapper {
        margin: 0;
        width: 100vw;
        height: 100%;
        max-height: none;
        padding: 0;
        overflow-x: hidden;

        #map {
            max-height: 100%;
        }

        .map-wrapper {
            height: 100%;

            @include breakpoint (medium down) {
                flex-direction: column-reverse;
                justify-content: flex-end;

                &.find-in-store {
                    height: auto;
                }
            }
        }

        .modal-title-selectsize {
            display: inline-block;
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(21);
            padding-top: rem-calc(27);
            padding-left: 0;
            margin-bottom: rem-calc(40);

            @include breakpoint (small down) {
                padding-top: rem-calc(19);
                margin-bottom: rem-calc(32);
                margin-left: rem-calc(16);
                margin-right: rem-calc(16);
            }
        }

        .modal-paragraph-selectsize {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(21);
            padding-left: rem-calc(8);
            margin-bottom: rem-calc(24);

            @include breakpoint (small down) {
                margin-left: 0;
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }
        }

        .close-custom {
            display: block;
            font-size: rem-calc(35);
            right: rem-calc(40);
            font-weight: 200;
            z-index: 2000;

            @include breakpoint (small down) {
                right: rem-calc(20);
            }
        }

        .select-size-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: rem-calc(8);

            @include breakpoint (small down) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }
        }

        button.size-selector {
            width: auto;
            height: auto;
            border-radius: rem-calc(20);
            padding: rem-calc(8 16);
            line-height: 1;
            margin: 0;

            &.outofstock-tag:not(.notifyMe) {
                .size-label {
                    color: $black;
                }
            }

            .alertNotify {
                max-width: rem-calc(12);
                line-height: 1;
                height: rem-calc(12);
                margin-left: rem-calc(5);
            }
        }

        #findinstore-form {
            padding-right: rem-calc(40);
            padding-left: 0;

            @include breakpoint (small down) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }

            .boolean-field {
                @include breakpoint (small down) {
                    margin-bottom: rem-calc(24);
                }
            }

            .form-item {
                margin-top: rem-calc(24);
            }

            .input-form-foote {
                font-size: rem-calc(14);
            }

            .label-inside {
                margin-bottom: rem-calc(25);
            }
        }
        .scrollbar-wrapper {
            @include breakpoint (small down) {
                overflow-y: auto;
                max-height: calc(100vh - 372px);
            }
        }

        .findinstore-results {
            @include breakpoint (small down) {
                padding-top: rem-calc(28);
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }

            .find-in-store-toolbar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & > p {
                    flex: 1;
                }
            }

            .store-locator-nearby-button {
                display: none;

                @include breakpoint (small down) {
                    display: flex;
                    flex-direction: row;
                    gap: rem-calc(20);
                    justify-content: flex-end;
                    flex: 1;

                    svg {
                        fill: $white;
                        width: rem-calc(24);
                        height: rem-calc(24);
                    }

                    .storeLocatorNearbyList {
                        &:after {
                            content: '|';
                            display: inline-block;
                            color: $light-gray;
                            padding-left: rem-calc(20);
                        }
                    }
                    .change-opacity{
                        opacity: .3;
                    }
                }
            }
        }
        .view-all-stores {
            position: absolute;
            bottom: 0;
            width: 33.33333%;
            right: 0;
            padding: rem-calc(24);
            overflow: visible;
            border-top: rem-calc(1) solid $light-gray;
            z-index: 21;
            background: $white;

            @include breakpoint (small down) {
                width: 100%;
                padding: rem-calc(16);
            }

            .button {
                width: 100%;
                margin-left: 0;
                margin-bottom: 0;
            }
        }
    }
    .suggestedProductsContainer--tezenis {
        width: 100%;
        border-top: rem-calc(1) solid $light-gray;
        margin-top: rem-calc(9);
        padding-top: rem-calc(21);
        padding-right: 0;

        #crossSellContainer {
            @include breakpoint (large up) {
                width: calc(100% + 40px);
            }
        }

        .slick-track {
            margin-left: 0;
        }

        h6 {
            text-align: left;
            margin-bottom: 0;

            span {
                font-size: rem-calc(18);
                line-height: rem-calc(27);
                font-weight: 500;
            }
        }

        .swatches__variation-list {
            padding-left: rem-calc(2);
        }

        .js-discount-value-percentage.hidden {
            display: none;
        }
    }
    .no-other-results {
        .other-result{
            display: none;
        }
    }
}
.pdpCompleteMobileModal {
    .tingle-modal-box {
        width: 100%;
        border-radius: 0;
        margin-bottom: 0;
    }

    .tingle-modal-box__content {
        padding: 0;
    }

    .cross-sell-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        margin: rem-calc(16 16 0);
        align-items: flex-start;

        h6 {
            margin-bottom: 0;
        }

        &__h6 {
            @include breakpoint (ipad down) {
                font-size: rem-calc(14);
            }
        }

        .tingle-modal__closeIcon {
            cursor: pointer;

            svg {
                width: rem-calc(20);
            }
        }
    }

    #completeTheLook,
    #crossSellVariation {
        margin-top: rem-calc(16);

        .product {
            margin-bottom: rem-calc(20);

            .color-swatches {
                display: none;
            }
        }
    }
}

.authAddition {
    margin-top: rem-calc(10);
    a {
        text-decoration: underline;
    }
}


.cc-completeLookPromoCarousel {
    @include breakpoint (ipad down) {
        & &-title {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
            font-weight: 600;
            margin: rem-calc(16 16 0);
        }
    }
}

.cc-completeThePromo {
    & &-quickAdd {
        position: absolute;
        bottom: rem-calc(16);
        left: rem-calc(16);
        z-index: 1;
        display: block;
    }

    & &-title {
        font-size: rem-calc(14);
    }

    &-modal {
        z-index: 1010;

        .tingle-modal-box {
            width: 100vw;
            height: 100dvh;
            min-width: 100vw;
            min-height: 100dvh;
            max-height: 100dvh;
            overflow-y: unset;

            &__content {
                padding: rem-calc(16 0 16 16);
                height: 100%;
            }
        }

        &-header,
        &-info,
        &-size,
        &-attribute_color,
        &-quantity {
            padding-right: rem-calc(16);
        }

        &-header {
            display: flex;
            align-items: flex-start;
            padding-bottom: rem-calc(16);
            gap: rem-calc(16);

            &-title {
                flex: 1 1 0;
            }
        }

        &-color {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(12);
            line-height: rem-calc(18);

            &-wrapper {
                overflow-x: auto;
                overflow-y: hidden;
                width: calc(100% + #{rem-calc(16)});
                padding-right: rem-calc(16);
                gap: rem-calc(8);
                display: flex;

                &::-webkit-scrollbar {
                    display: none;
                    width: 0;
                }
            }

            &-link,
            &-swatch {
                width: rem-calc(52);
                height: rem-calc(80);
                margin-right: 0;
            }

            &-swatch {
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: rem-calc(4);

                .selected &,
                &.selected {
                    border: rem-calc(1) solid $medium-gray;
                }
            }
        }

        &-size {
            font-size: rem-calc(12);
            line-height: rem-calc(18);

            &-label {
                &.radio-label {
                    height: unset;
                    width: unset;
                    padding: rem-calc(8 16);
                    line-height: unset;
                    border-radius: rem-calc(20);
                    text-decoration: none;
                    border: rem-calc(1) solid $light-gray;
                    margin: 0;
                    min-width: rem-calc(49);
                    display: flex;
                    justify-content: center;
                    gap: rem-calc(4);

                    &, &:hover {
                        background-color: white;
                    }

                    &.cc-outOfStock {
                        color: $medium-light-gray;
                    }

                    .selected & {
                        background-color: $water-green-light;
                        border-color: $water-green;
                    }
                }

                &-wrapper {
                    overflow-x: auto;
                    display: flex;
                    gap: rem-calc(8);
                    padding-right: rem-calc(16);

                    &::-webkit-scrollbar {
                        display: none;
                        width: 0;
                    }
                }
            }
        }

        &-attribute,
        &-info {
            padding-bottom: rem-calc(24);
            border-bottom: rem-calc(1) solid $light-gray;
            margin-bottom: rem-calc(16);
        }

        &-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &-overflow {
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
            padding-bottom: rem-calc(16);
        }

        &-info {
            display: flex;
            gap: rem-calc(16);

            &-image,
            &-wrapper {
                width: calc(50% - #{rem-calc(8)});
            }

            &-image {
                border-radius: rem-calc(12);
            }

            &-name {
                font-weight: $global-weight-normal;
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.154);
                border-bottom: 0;
                display: flex;
                margin-bottom: rem-calc(4);
            }

            &-price {
                span {
                    font-size: rem-calc(12);
                }
            }
        }

        &-quantity {
            align-items: center;
            display: flex;
            gap: rem-calc(24);

            .cc-quantity {
                &-value {
                    border-radius: rem-calc(20);
                    border: rem-calc(1) solid $light-gray;
                    padding: rem-calc(8 20);
                    min-width: rem-calc(47);
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    text-align: center;
                }

                &-circle {
                    width: rem-calc(24);
                    height: rem-calc(24);
                    border-radius: 100%;
                    background-color: $water-green;
                    padding: 0;

                    &-icon {
                        padding: rem-calc(1.5);
                    }
                }
            }
        }

        &-addToCart {
            margin: rem-calc(0 -16 -16);
            width: calc(100% + #{rem-calc(16)});
            padding: rem-calc(16);
            background: $white;
            border-top: rem-calc(1) solid $light-gray;
        }
    }

    .cc-pillsMessage-container {
        display: none;
    }
}


.product-quickview-bonus-product {
    padding-bottom: rem-calc(86);
    padding-top: rem-calc(16);

    .image-cell {
        width: rem-calc(169);
    }

    .product-image {
        border-radius: rem-calc(16);
        width: 100%;
    }

    .cell-flex-quick {
        flex: 1;
        padding-left: rem-calc(16);
    }

    .add-to-cart-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: rem-calc(16);
        border-top: rem-calc(1) solid $light-gray;
        z-index: 1;
        background: $white;

        @include breakpoint (large up) {
            padding: rem-calc(24 40 24 24);
        }

        .button {
            width: 100%;
            margin: 0;
        }
    }

    .promo-and-name-cell {
        .product-name {
            .full-pdp-link {
                font-weight: $global-weight-normal;
                display: block;
                margin: rem-calc(4 0);
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }
    }

    .prices {
        font-size: rem-calc(12);
        line-height: rem-calc(18);

        .value {
            font-weight: $global-weight-dark;
            font-size: rem-calc(12);
        }
    }

    .code-color {
        margin-top: rem-calc(24);
        border-top: rem-calc(1) solid $light-gray;
        padding-top: rem-calc(16);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(16);

        .family-color,
        .attribute-label {
            font-weight: $global-weight-dark;
        }
    }

    .attrValuesSlider {
        height: auto;
        width: 100%;
        overflow: visible;

        &::-webkit-scrollbar {
            @include breakpoint (medium down) {
                display: none;
            }
        }

        @include breakpoint (medium down) {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    .slider-values-container {
        width: 100%;
        overflow-x: auto;
        padding-bottom: rem-calc(16);
        overflow-y: hidden;

        @include scrollbars();

        &::-webkit-scrollbar {
            @include breakpoint (medium down) {
                display: none;
            }
        }

        @include breakpoint (medium down) {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .color-variation {
            transform: none;
            width: rem-calc(52);
            flex: 0 0 rem-calc(52);
            height: rem-calc(80);
            margin: rem-calc(0 8 0 0);

            .swatch-circle {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: #F2F2EA;
                position: relative;
                border-radius: rem-calc(4);

                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    border: rem-calc(1) solid transparent;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: rem-calc(4);
                }

                &.selected {
                    &:after {
                        border-color: $medium-gray;
                    }
                }
            }

            &.color-on-sale {
                .swatch-circle {
                    &:before {
                        content: '';
                        width: rem-calc(4);
                        height: rem-calc(4);
                        border-radius: 100%;
                        background: $sale;
                        display: block;
                        position: absolute;
                        bottom: rem-calc(4);
                        left: 50%;
                        transform: translateX(-50%);

                    }
                }

                &:hover {
                    .tooltip-sale-wrapper {
                        @include breakpoint (small up) {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            .tooltip {
                &:hover {
                    .tooltiptext {
                    display: none;
                    }
                }
            }
        }
    }

    .bonus-color-selection {
        margin-top: rem-calc(8);
        border-top: rem-calc(1) solid $light-gray;
        padding-top: rem-calc(16);

        .size-title {
            display: block;
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(16);

            .size-value {
                font-weight: $global-weight-dark;
            }
        }

        .sizes-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom: rem-calc(16);

            @include breakpoint (medium down) {
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-bottom: rem-calc(16);
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .boolean-field-wrapper {
            margin-right: rem-calc(8);
            margin-bottom: rem-calc(16);

            @include breakpoint (medium down) {
                margin-bottom: 0;
            }

            &::-webkit-scrollbar {
                @include breakpoint (medium down) {
                    display: none;
                }
            }

            @include breakpoint (medium down) {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        }

        .swatch-boolean {
            padding: 0;
            margin: 0;
            &:last-of-type {
                margin-right: 0;
            }
            img {
                display: none;
            }
            input[type="radio"].clicked-size+.radio-label:not(.notifyMe),
            input[type="radio"]:checked+.radio-label:not(.notifyMe) ,
            input[type="radio"][data-attr-value="TU"]+.radio-label {
                text-decoration: none;
                background-color: $water-green-light;
                border: rem-calc(1) solid $water-green;

                & ~ img {
                    display: block;
                    position: absolute;
                    top: rem-calc(-2);
                    right: rem-calc(-3);
                    width: rem-calc(22);
                    height: rem-calc(22);
                }
            }

            .outofstock-tag{
                margin: 0;
            }
        }
        .boolean-field {
            .radio-label {
                width: auto;
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                line-height: rem-calc(18);
                position: relative;
                font-weight: $global-weight-normal;
                border: 1px solid #ccc;
                border-radius: rem-calc(20);
            }

            svg.alertNotify {
                display: block !important;
                margin-left: rem-calc(5);
                width: rem-calc(13);
                height: rem-calc(13);
                margin-top: 0;
            }
            .notifyMe {
                inline-size: fit-content;
                width: auto;
                height: auto;
                color: gray;
                display: flex;
                border-radius: rem-calc(20);
                align-items: center;
                justify-content: center;
                svg {
                    &.alertNotify{
                        display: block;
                        margin-left: rem-calc(5);
                        width: rem-calc(13);
                    }
                }
            }
            .alertNotify{
                display: none;
            }
        }
    }

    &.product-variationGroup {
        .attribute-color-wrp {
            display: none;
        }
        .slider-values-container .color-variation:not(.selected) {
            display: none;
        }
    }
}
.bonus-product-item .slider-values-container > a.no-hover.color-variation{
    margin-right: rem-calc(8)!important;
}
.bonus-product-info, .bonus-product-summary {
    .strike-through {
        display:none;
    }
    .text-sale  {
        color:#000
    }
}
