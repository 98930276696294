@import 'swatch-colors';
@import 'quantity';

.swatch-circle.unselectable {
    opacity: 0.2;
    cursor: not-allowed;
}

.swatch-circle,
.swatch-button {
    display: inline-block;
    width: rem-calc(21);
    height: rem-calc(21);
    border-radius: 50%;
}

.swatch-boolean {
    display: inline-block;
    padding: 0;
    text-align: center;

    .radio-input {
        padding-left: 0;
        position: absolute;
        left: 0;
        bottom: rem-calc(5);
    }

    .radio-input:before {
        content: none;
        display: none;
    }

    .radio-input:after,
    input[type="radio"]:checked+.radio-input:after {
        height: rem-calc(2);
        width: rem-calc(21);
        top: auto;
        left: auto;
        bottom: rem-calc(-8);
        border: none;
        border-radius: 0;
    }

    input[type="radio"]:checked ~ .radio-label {
        font-weight: $global-weight-bold;
    }
}
