.cc-pdp {
    &__zoomImage {
        &__modal {
            .tingle-modal-box {
                position: relative;
                width: 100%;
                height: 100%;
                max-width: none;
                max-height: none;
                overflow: hidden;
            }
            .tingle-modal-box__content {
                padding: 0;
            }
        }

        &__closeModal {
            position: absolute;
            top: rem-calc(40);
            right: rem-calc(40);
            border: rem-calc(1) solid $light-gray;
            border-radius: 100%;
            padding: rem-calc(10);
            cursor: pointer;
            z-index: 2;
            @include breakpoint(large up){
                background-color: $white;
            }

            svg {
                width: rem-calc(18);
                height: rem-calc(18);
            }

            @include breakpoint(small down){
                top: rem-calc(16);
                right: rem-calc(16);
                padding: 0;
                border: 0;

                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }

        &__zoomButton {
            display: flex;
            flex-direction: column-reverse;
            gap: 16px;
            position: absolute;
            top: rem-calc(96);
            right: rem-calc(40);
            z-index: 2;

            @include breakpoint(small down){
                display: none;
            }

            button {
                background-color: $white;
                margin-right: 0;
            }
        }

        &__zoomIn,
        &__zoomOut {
            border: rem-calc(1) solid $light-gray;
            border-radius: 100%;
            padding: rem-calc(10);
            cursor: pointer;

            svg {
                width: rem-calc(18);
                height: rem-calc(18);
            }

            &.disabled {
                opacity: 0.3;
            }
        }

        &__navThumbnails {
            display: flex;
            flex-direction: column;
            gap: rem-calc(4);
            position: absolute;
            top: 50%;
            left: rem-calc(48);
            transform: translateY(-50%);
            z-index: 2;
            width: 3.806734992679356vw;

            @include breakpoint(small down){
                display: none;
            }
        }

        &__thumbnail {
            border-radius: rem-calc(4);

            &.active {
                opacity: 0.3;
            }
        }

        &__item {
            overflow: hidden;
            @include breakpoint(medium up){
                height: 100vh;

                .swiper-zoom-container {
                    height: 100%;
                }
            }

            &.cc-pdp__zoomImage__scaleDown {
                .zoom {
                    width: unset !important;
                    height: 100% !important;
                }
            }

            &.cc-pdp__zoomImage__initialScale {
                .zoom {
                    width: 78% !important;
                    height: unset !important;
                    @include breakpoint(small down){
                        width: 100% !important;
                    }
                }
            }

            &.cc-pdp__zoomImage__scaleUp {
                .zoom {
                    width: 100% !important;
                    height: unset !important;
                }
            }

            &.cc-pdp__zoomImage__scaleDown,
            &.cc-pdp__zoomImage__initialScale,
            &.cc-pdp__zoomImage__scaleUp {
                .zoom {
                    position: relative;
                    @include breakpoint(small down){
                        transform: scale(1);
                    }
                }
            }

            img {
                display: block;
                min-height: 100vh;
                object-fit: cover;

                @include breakpoint(medium up){
                    margin: auto;
                    min-height: auto;
                }
            }
        }
    }

}
