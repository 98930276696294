$brand-palette: (
    primary: #FF6699,
    secondary: #FA87E6,
    // shadow-text: #ee3233,
    // pink-banner: #FFE6E6,
    black: #1C1C1C,
    dark-gray: #000000B2,
    medium-gray:#646464,
    darkish-gray: #999999,
    medium-light-gray: #C4C0C0,
    more-light-gray: #00000033,
    light-gray: #E8E8E8,
    component-grey: #FFFFFF4D,
    over-gray:#F2F2F2,
    off-white: #989898,
    white: #FFFFFF,
    alert: #ff6666,
    // christmas: #ca1617,
    success: #53AD44,
    info: #537af8,
    brand-green: #8AC492,
    brand-blue: #2C73D2,
    salmon: #ff8066,
    snoopy: #FFE959,
    error: #DD1C11,
    sustainability: #22746A,
    water-green: #82C1BD,
    water-green-light: #D0E6E2,
    // sustainability-light: #E9F1F0,
    promotion: #8C12FF,
    promotion-light: #F3E7FF,
    sale: #D10B82,
    pink: #F1A7DC,
    orange: #FB8904
    // sale-light: #FAE7F3
);

$foundation-palette: (
    primary: #1C1C1C,
    secondary: #85715D,
    success: #8AB349,
    warning: #FFA12E,
    alert: #FF0000,
);

$buttons-palette-black-text: (
    primary: map-get($brand-palette, white),
    // christmas: map-get($brand-palette, christmas),
    secondary: map-get($brand-palette, light-gray)
);

$buttons-palette-white-text: (
    black: map-get($brand-palette, black)
);

$primary: map-get($brand-palette, primary);
$secondary: map-get($brand-palette, secondary);
$shadow-text: map-get($brand-palette, shadow-text);
$black: map-get($brand-palette, black);
$dark-gray: map-get($brand-palette, dark-gray);
$medium-dark-gray: map-get($brand-palette, darkish-gray);
$medium-gray: map-get($brand-palette, medium-gray);
$medium-light-gray: map-get($brand-palette, medium-light-gray);
$more-light-gray: map-get($brand-palette, more-light-gray);
$light-gray: map-get($brand-palette, light-gray);
$border-gray: lighten($medium-light-gray, 25%);
$border-light-gray: lighten($more-light-gray, 10%);
$off-white: map-get($brand-palette, off-white);
$white: map-get($brand-palette, white);
$success: map-get($brand-palette, success);
$info: map-get($brand-palette, info);
$alert: map-get($brand-palette, alert);
$error: map-get($brand-palette, error);
$warning: map-get($foundation-palette, warning);
// $christmas: map-get($brand-palette, christmas);
$brand-green: map-get($brand-palette, brand-green);
$brand-blue: map-get($brand-palette, brand-blue);
$over-gray: map-get($brand-palette, over-gray);
$component-grey: map-get($brand-palette, component-grey);
$pink-banner: map-get($brand-palette, pink-banner);
$color-error: map-get($brand-palette, error);
$sustainability: map-get($brand-palette, sustainability);
$water-green: map-get($brand-palette, water-green);
$water-green-light: map-get($brand-palette, water-green-light);
// $sustainability-light: map-get($brand-palette, sustainability-light); // ONLY IN UIKIT
$promotion: map-get($brand-palette, promotion);
$promotion-light: map-get($brand-palette, promotion-light); // ONLY IN UIKIT
$sale: map-get($brand-palette, sale);
// $sale-light: map-get($brand-palette, sale-light); // ONLY IN UIKIT
$pink: map-get($brand-palette, pink);
$orange: map-get($brand-palette, orange);
/// Generate font-color & bg-color class helpers
///
/// @param {Map} $palette - colors map
@mixin extend-palette($palette) {
    @each $color, $hex in $palette {
        .color-#{$color} {
            color: $hex !important;
        }

        .bg-#{$color} {
            background-color: $hex !important;
        }
    }
}

.bg-loyalty-70 {
    background: linear-gradient(135deg, rgba(236, 177, 100, 0.7) 0%, rgba(233, 153, 96, 0.7) 21.98%, rgba(238, 132, 114, 0.7) 37.41%, rgba(249, 109, 143, 0.7) 59.66%, rgba(227, 102, 156, 0.7) 77.35%, rgba(149, 98, 159, 0.7) 100%);
}

.bg-loyalty-60 {
    background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%);
}

.bg-beginner-70 {
    background: linear-gradient(137.41deg, rgba(231, 124, 100, 0.7) -1.82%, rgba(247, 193, 107, 0.7) 102.76%);
}

.bg-famous-70 {
    background: linear-gradient(136.35deg, rgba(240, 134, 109, 0.7) 2.25%, rgba(255, 102, 153, 0.7) 100%);
}

.bg-celebrity-70 {
    background: linear-gradient(135.69deg, rgba(255, 102, 153, 0.7) 13.34%, rgba(63, 93, 163, 0.7) 100%);
}
