@mixin price-tezenis {
  .price--tezenis {
        &:not(.cc-hidePercentage) {
            display: inline-block;
            &.discount-percentage {
                display: inline;
            }
            @media screen and (max-width: 768px) {
                display: block;
                width: 100%;
            }
            @include breakpoint (small down) {
                display: inline-block;
                width: auto;
                margin-right: rem-calc(7);
                line-height: rem-calc(20);
            }
        }
        span {
          font-size: rem-calc(26);
          font-weight: $global-weight-dark;
          @media (max-width: 1440px) {
              font-size: rem-calc(24);
          }
          @media screen and (max-width: 1024px) {
              font-size: rem-calc(21);
              line-height: rem-calc(21);
          }
          @media screen and (max-width: 768px) {
              font-size: rem-calc(19);
          }
          @include breakpoint (small down) {
              font-size: rem-calc(14);
              font-weight: $global-weight-dark;
              line-height: rem-calc(18);
          }
          &.strike-through {
              .value {
                  @include breakpoint (small down) {
                      color: #ccc;
                  }
              }
          }
      }
      .strike-through ~ .sales {
          .onsale {
              display: none;
          }
      }
      &.cc-hidePercentage {
        display: none;
      }
  }
  .price--tezenis ~ .js-new-msg {
      display: inline-block;
      width: auto;
      margin-left: rem-calc(10);
      text-transform: uppercase;
      font-size: rem-calc(12);
      @include breakpoint (small down) {
          margin-left: 0;
      }
  }
}

@mixin slider-arrows {
  .prev-button,
  .next-button {
      outline: transparent;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      padding: rem-calc(18);
      background-color: #fff;
      cursor: pointer;
      @include breakpoint (small down) {
          top: 45%;
      }
      svg {
          width: auto;
          height: auto;
          @include breakpoint (small down) {
              transform: scale(.9);
              transform-origin: center;
          }
      }
  }
  .prev-button {
      left: 0;
      padding: rem-calc(18 15 18 7);
      border-top-right-radius: rem-calc(25);
      border-bottom-right-radius: rem-calc(25);
      @include breakpoint (small down) {
          padding: rem-calc(9 11 9 6);
      }
  }
  .next-button {
      right: 0;
      padding: rem-calc(18 7 18 15);
      border-top-left-radius: rem-calc(25);
      border-bottom-left-radius: rem-calc(25);
      @include breakpoint (small down) {
          padding: rem-calc(9 6 9 11);
      }
  }
}

.desc-sale{
    @include breakpoint (medium down){
        margin-bottom: rem-calc(12);
    }
}

@mixin pills-wrapper {
  .pills-wrapper {
      position: absolute;
      top: rem-calc(15);
      left: 0;
      width: 100%;
      @media (max-width: 1024px) {
          top: rem-calc(10);
      }
      @include breakpoint(small down) {
          top: rem-calc(6);
          margin: rem-calc(20 0 0 8);
      }
      .js-loyalty-points-msg,
      .js-discount-msg,
      .js-new-msg {
          display: inline-block;
          width: auto;
          margin-left: rem-calc(10);
          text-transform: uppercase;
          font-size: rem-calc(12);
          position: relative;
          top: auto;
          left: auto;
          height: auto;
          text-align: center;
          color: $white;
          line-height: 1;
          background-color: #ee7199;
          border-radius: rem-calc(25);
          font-weight: $global-weight-bold;
          letter-spacing: rem-calc(0.3);
          padding: rem-calc(9 14 8);
          @media (max-width: 1024px) {
              font-size: rem-calc(10);
              padding: rem-calc(7 11 6);
              margin-left: rem-calc(7);
          }
          @include breakpoint(small down) {
              padding: rem-calc(5 8);
              margin-left: rem-calc(5);
              margin-left: 0;
          }
          &.hidden {
              display: none;
          }
          &.loyalty-discount {
              background: rgb(231,126,100);
              background: linear-gradient(120deg, rgba(231,126,100,1) 0%, rgba(253,107,147,1) 60%, rgba(86,94,161,1) 100%);
          }
      }
      .js-new-msg {
          background-color: #4A9E9E;
      }
        .js-loyalty-points-msg {
            text-transform: lowercase;
            &.points-banner{
                padding: rem-calc(4 15);
                @include breakpoint(small down) {
                    padding: rem-calc(4 10);
                }
                .loyalty-points-banner {
                    font-size: rem-calc(14);
                    line-height: rem-calc(24);
                    display: flex;
                    align-items: center;
                    font-weight: $global-weight-dark;
                    @include breakpoint(small down) {
                        font-size: rem-calc(9);
                        line-height: rem-calc(15);
                    }
                }
                .loyalty-tezenis-talent-star {
                    filter: invert(1);
                    width: rem-calc(11);
                    height: rem-calc(11);
                    margin-left: rem-calc(2);
                    margin-top: rem-calc(-2);
                    @include breakpoint(small down) {
                        width: rem-calc(8);
                        height: rem-calc(8);
                    }
                }
            }
        }
    }
}

@mixin product-info-details {
.product-quickview{
    .boolean-field {
        .radio-label {
            width: auto;
            height: auto;
            line-height: rem-calc(18);
        }
        .notifyMe {
            width: auto;
            height: auto;
            line-height: rem-calc(18);
        }
    }
}
.notifyMe .size-label {
    color: $medium-light-gray;
}
    .default-code-color {
        .attribute-label,
        .family-color {
            font-weight: $global-weight-dark;
        }
    }
    .code-color,
    .size-label {
        font-size: rem-calc(12);
        color: $black;
        margin-bottom: 0;
        display: block;
        font-weight: $global-weight-normal;

        .small-svg{
            width: unset;
            height: unset;
        }
        .font-normal{
            font-weight: $global-weight-normal;
        }
        .value {
            font-weight: $global-weight-normal;
        }
        .on-sale-color{
            color:$primary;
        }

        .sales {
            color: $primary;
            .onsale {
                display: none;
            }
        }

        .price-info-pdp-container.tooltip {
            display: none;
        }
    }

    .size-label {
        display: inline-block;
    }
    .product-availability {
        display: inline-flex;
        margin-bottom: rem-calc(7);
        @media (max-width: 1440px) {
            margin-bottom: rem-calc(4);
        }
        span {
            font-size: rem-calc(12);
            font-weight: $global-weight-dark;
            letter-spacing: rem-calc(0.2);
            &.sizeSelected{
                margin-right: rem-calc(8);
                font-weight: $global-weight-dark;
                font-style: unset;
            }

            &.text-salmon{
                font-size: rem-calc(12);
                font-style: normal;
                color: #FF6699;
                font-weight: 700;
                letter-spacing: rem-calc(0.5);
            }

            &.size-not-selected-label {
                display: none;
            }
        }
    }
    .product-availability.size-not-selected {
        span {
            display: none;

            &.size-not-selected-label {
                display: block;
                color: $error;
            }
        }
    }
    .attribute-button {
        @include breakpoint (small down) {
            height: auto;
            border: none;
        }
        &:first-of-type {
            @include breakpoint (small down) {
                border-right: none;
                padding: 0;
                align-self: center;
                display: flex;
                background-color: #F2F4ED;
                padding: rem-calc(7 0);
                justify-content: center;
                margin-right: rem-calc(10);
                border-radius: rem-calc(40);
                text-transform: capitalize;
                font-weight: $global-weight-normal;
            }
            .triangle-down {
                @include breakpoint (small down) {
                    border: solid black;
                    border-width: rem-calc(0 6 6 0);
                    display: inline-block;
                    padding: rem-calc(11);
                    margin-top: rem-calc(-5);
                    transform-origin: center;
                    transform: translateY(0px) scale(0.2) rotate(45deg);
                }
                &.rotate {
                    @include breakpoint (small down) {
                        transform: translateY(4px) scale(0.2) rotate(-132deg);
                    }
                }
            }
            .attribute-selection {
                @include breakpoint (small down) {
                    order: -1;
                    max-width: rem-calc(26);
                    height: rem-calc(26);
                    margin-right: rem-calc(5);
                }
                > .swatch-circle {
                    @include breakpoint (small down) {
                        width: rem-calc(26);
                        height: rem-calc(26);
                        margin-top: 0;
                    }
                }
            }
        }
        &:nth-of-type(2) {
            @include breakpoint(small down) {
                display: none;
            }
        }
    }
    .attribute-container {
        @media screen and (min-width: 640px) {
            position: relative;
        }
        @include breakpoint (small down) {
            margin-left: rem-calc(0);
            margin-right: rem-calc(0);
        }
        hr {
            @include breakpoint (small down) {
                display: none;
            }
        }
        &.quantity {
            @include breakpoint (small down) {
                margin-left: auto;
                margin-right: auto;
                width: auto;
                position: relative;
                bottom: 0;
            }
            .accordion-panel-group {
                @include breakpoint (small down) {
                    width: auto;
                }
            }
        }
        .accordion-panel-group {
            box-shadow: none;
            margin-bottom: rem-calc(7);
            @media screen and (max-width: 1024px) {
                margin-bottom: 0;
            }
            @include breakpoint (small down) {
                border-top-left-radius: rem-calc(5);
                border-top-right-radius: rem-calc(5);
                padding: rem-calc(20 15 8 15);
                position: relative;
                width: 100%;
            }
            div {
                > span {
                    @include breakpoint (small down) {
                        margin-bottom: rem-calc(10);
                        display: inline-block;
                        &.font-normal{
                            font-weight: $global-weight-normal;
                        }
                        &.text-brand-green {
                            font-size: rem-calc(12);
                            font-weight: $global-weight-dark;
                            text-transform: uppercase;
                            font-style: normal;
                            color: #00AB43;
                        }
                        &.text-brand-red {
                            font-size: rem-calc(12);
                            font-weight: $global-weight-dark;
                            text-transform: uppercase;
                            font-style: normal;
                            color: red;
                        }
                        &.sizeSelected{
                            margin-right: rem-calc(8);
                            font-weight: $global-weight-dark;
                        }
                        &.text-salmon{
                            font-size: rem-calc(12);
                            font-style: normal;
                            color: #FF6699;
                            font-weight: $global-weight-dark;
                            letter-spacing: rem-calc(0.5);
                        }
                    }
                    span {
                        &.size-label {
                            font-weight: $global-weight-dark;
                        }
                    }
                }
            }
            @include breakpoint (large up) {
                &[data-attr="color"] {
                    margin: rem-calc(16 0 0 0);
                }
            }
            &[data-attr="size"] {
                margin-top: 0;
                margin-bottom: 0;
                @include breakpoint (small down) {
                    position: static;
                }
                &.not-enabled {
                    pointer-events: none;
                }
            }
            .color-variation {
                width: rem-calc(34);
                height: rem-calc(34);
                margin-left: rem-calc(4);
                margin-right: rem-calc(18);
                margin-bottom: rem-calc(23);
                @media (max-width: 1440px) {
                    transform: scale(0.9);
                    transform-origin: left;
                    margin-right: rem-calc(14);
                }
                @media (max-width: 1366px) {
                    margin-bottom: rem-calc(18);
                }
                @media screen and (max-width: 1024px) {
                    transform: scale(0.8);
                    margin-right: rem-calc(14);
                    margin-bottom: rem-calc(14);
                }
                &:last-of-type {
                    margin-right: 0;
                }
                img {
                    display: none;
                }
                .swatch-circle {
                    width: rem-calc(168);
                    height: rem-calc(200);
                    &:after {
                        content: '';
                        position: absolute;
                        width: rem-calc(168);
                        height: rem-calc(200);
                        border: rem-calc(1) solid #ccc;
                        border-radius: 7%;
                    }
                    &.selected {
                        &:after {
                            border: rem-calc(1) solid $black;
                        }
                    }
                }
                .color-change {
                    width: rem-calc(55);
                    height: rem-calc(55);
                    &:after {
                        width: rem-calc(55);
                        height: rem-calc(55);
                    }
                }
                .swatch-circle.selected ~ img {
                    display: block;
                    position: absolute;
                    top: rem-calc(-10);
                    right: rem-calc(-10);
                    width: rem-calc(22);
                    height: rem-calc(22);
                }
                /* Tooltip container */
                .tooltip {
                    position: relative;
                    display: inline-block;
                    .tooltiptext {
                        visibility: hidden;
                        width: rem-calc(223);
                        text-align: center;
                        background-color: $white;
                        color: $black;
                        border-radius: rem-calc(5);
                        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);
                        padding: rem-calc(11 0 10);
                        font-size: rem-calc(11);
                        font-weight: $global-weight-normal;
                        position: absolute;
                        z-index: 1;
                        line-height: 1;
                        bottom: 125%;
                        left: 50%;
                        margin-left: rem-calc(-111);
                        opacity: 0;
                        transition: opacity 0.3s;
                        &:first-letter {
                            text-transform: capitalize;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: rem-calc(-10);
                            border-width: rem-calc(10);
                            border-style: solid;
                            border-color: #fff transparent transparent transparent;
                        }
                    }
                    &:hover {
                        .tooltiptext {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .availability-msg {
            margin-bottom: rem-calc(3);
            &.text-brand-green {
                font-size: rem-calc(12);
                font-weight: 700;
                text-transform: uppercase;
                font-style: normal;
                color: #00AB43;
            }
            &.text-brand-red {
                font-size: rem-calc(12);
                font-weight: 700;
                text-transform: uppercase;
                font-style: normal;
                color: red;
            }
        }
        .size-guide {
            margin-bottom: 0;
            a {
                font-size: rem-calc(12);
                font-weight: $global-weight-normal;
                text-decoration: underline;
                text-underline-offset: rem-calc(4);
                &:hover {
                    border-bottom: none;
                }
                .size-guide-icon{
                    margin-right: rem-calc(11);
                }
            }
        }
        &.quantity {
            padding: 0;
            margin-top: rem-calc(40);
            @media (max-width: 1440px) {
                margin-top: rem-calc(30);
            }
            &.not-enabled {
                pointer-events: none;
            }
            .accordion-panel-group {
                padding: rem-calc(10 0 8);
                display: inline-block !important;
                border: rem-calc(1) solid #ccc;
                border-radius: rem-calc(35);
                margin-bottom: rem-calc(12);
                @media screen and (max-width: 1024px) {
                    padding: rem-calc(8 0 6);
                }
            }
            .quantity-circle {
                border: none;
                width: auto;
                height: auto;
                font-size: rem-calc(13);
                padding: rem-calc(0 20);
                color: #EE7199;
                font-weight: 700;
            }
            .quantity-value {
                margin: 0;
                font-weight: $global-weight-normal;
                font-size: rem-calc(13);
                padding: rem-calc(0 5);
            }
        }
        .sizes-container{
            display: flex;
            flex-wrap: wrap;
            gap: rem-calc(8);
        }
    }
    .swatch-boolean {
        padding: 0;
        margin: 0;
        &:last-of-type {
            margin-right: 0;
        }
        img {
            display: none;
        }
        input[type="radio"].clicked-size+.radio-label:not(.notifyMe),
        input[type="radio"]:checked+.radio-label:not(.notifyMe) ,
        input[type="radio"][data-attr-value="TU"]+.radio-label {
            text-decoration: none;
            background-color: $water-green-light;
            border: rem-calc(1) solid $water-green;

            & ~ img {
                display: block;
                position: absolute;
                top: rem-calc(-2);
                right: rem-calc(-3);
                width: rem-calc(22);
                height: rem-calc(22);
            }
        }

        .outofstock-tag{
            margin: 0;
        }
    }
    .boolean-field {
        .radio-label {
            width: rem-calc(56);
            height: rem-calc(56);
            display: inline-block;
            line-height: rem-calc(56);
            position: relative;
            font-weight: $global-weight-normal;
            border: rem-calc(1) solid #ccc;
            border-radius: 50%;
        }
        .notifyMe {
            inline-size: fit-content;
            width: rem-calc(56);
            height: rem-calc(56);
            color: gray;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            svg {
                &.alertNotify{
                    display: block;
                    margin-left: rem-calc(5);
                    width: rem-calc(13);
                }
            }
        }
        .alertNotify{
            display: none;
        }
    }
    .add-to-cart-actions {
        margin-top: 0;
        .button.add-to-cart {
            font-size: rem-calc(15);
            font-weight: $global-weight-normal;
            padding: rem-calc(23 0 25);
            border-radius: rem-calc(40);
            outline: transparent;
            font-weight: $global-weight-bold;
            @media (max-width: 1440px) {
                padding: rem-calc(20 0 22);
            }
            @media (max-width: 1366px) {
                font-size: rem-calc(14);
                padding: rem-calc(18 0 20);
            }
            @media screen and (max-width: 1024px) {
                font-size: rem-calc(13);
                padding: rem-calc(15 0 17);
            }
            @media screen and (max-width: 768px) {
                font-size: rem-calc(12);
                padding: rem-calc(13 0 15);
            }
            &:disabled,
            &[disabled] {
                background-color: #949494;
                color: #fff;
            }
        }
    }
    .add-to-cart.button-edited-cart, .add-mobile-soldout-add {
        @include breakpoint(small down) {
            width: 65%;
            border-radius: rem-calc(40);
            outline: transparent;
            font-size: rem-calc(13);
            font-weight: $global-weight-bold;
            height: rem-calc(48);
        }
        svg {
            @include breakpoint(small down) {
                width: auto;
                height: auto;
                margin-right: rem-calc(5);
            }
        }
    }
    .add-mobile-soldout-add:disabled{
        background-color: #666;
        color: $white;
        border: 0;
    }
    .pdp-icon {
        width: rem-calc(20);
        height: rem-calc(20);
        top: rem-calc(1);
    }
    .button.button-primary:not(:disabled):not(.disabled) {
        &.find-in-store-btn.button {
            padding: 0;
            text-align: left;
            border: none;
            margin-bottom: rem-calc(30);
            outline: none;
            background: $white;
            color: $black;
            @media (max-width: 1440px) {
                margin-bottom: rem-calc(25);
            }
            span {
                position: relative;
                font-size: rem-calc(12);
                font-weight: $global-weight-dark;
                margin-left: rem-calc(8);
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: rem-calc(1);
                    bottom: 0;
                    left: 0;
                    background-color: $black;
                    transform: scaleX(0);
                    transform-origin: bottom right;
                    transition: transform 0.3s;
                }
            }
            &:hover {
                background: $white;
                color: $black;
                span {
                    &:after {
                        transform-origin: bottom left;
                        transform: scaleX(1);
                    }
                }
            }
            &.not-enabled {
                color: #949494;
                pointer-events: none;
            }
        }
    }
    .item-description {
        font-size: rem-calc(14);
        font-weight: $global-weight-normal;
        line-height: rem-calc(24);
        letter-spacing: rem-calc(0.3);
        margin-bottom: rem-calc(5);
        .content {
            min-height: auto;
            padding: 0;
            @media (max-width: 1366px) {
                font-size: rem-calc(13);
                line-height: rem-calc(19);
            }
            @media screen and (max-width: 1024px) {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
            }
        }
    }
    .item-product-code {
        font-size: rem-calc(13);
        font-weight: $global-weight-normal;
        color: #CCCCCC;
        padding-top: 0;
        padding-bottom: rem-calc(25);
    }
    .item-composition {
        .content {
            position: relative;
            min-height: auto;
            padding: 0;
            bottom: auto;
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            letter-spacing: rem-calc(0.3);
            font-weight: $global-weight-normal;
            @media (max-width: 1366px) {
                font-size: rem-calc(13);
                line-height: rem-calc(19);
            }
            @media screen and (max-width: 1024px) {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
            }
            .block-title {
                font-weight: $global-weight-bold;
            }
            .js-composition-info.grid-x {
                .cell {
                    width: 100%;
                }
                .comp-material {
                    display: inline-block;
                    margin-right: rem-calc(7);
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
                .icon-wash {
                    float: none !important;
                    padding: 0;
                    margin-top: rem-calc(15);
                    @media screen and (max-width: 1024px) {
                        margin-top: rem-calc(10);
                        margin-right: rem-calc(8);
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    svg {
                        @media screen and (max-width: 1024px) {
                            transform: scale(0.9);
                            transform-origin: left;
                        }
                    }
                }
            }
        }
    }
}

@mixin pdp-carousel {
    .pdpCarousel,
    .quickviewCarousel {
        &__wrapper {
            display: flex;
            width: 100%;
            padding-right: rem-calc(40);
            @media screen and (max-width: 1024px) {
                padding-right: rem-calc(20);
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
                padding-right: 0;
            }
        }
        &__slider-nav {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
            gap: 8px;
            margin-right: 8px;
            .mini-video {
                border-radius: rem-calc(4);
                aspect-ratio: 0.6692913385826772;
                height: 100%;
                object-fit: cover;
            }
        }
        &__thumbnail {
            text-align: left;
            margin-bottom: rem-calc(10);
            cursor: pointer;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
                margin-left: rem-calc(10);
            }
            &:last-of-type {
                margin-bottom: 0;
                @media screen and (max-width: 768px) {
                    margin-left: 0;
                }
            }
            &.active {
                opacity: 0.3;
            }
        }
        &__slider-container {
            width: 42.5vw;
            overflow: unset;
            position: relative;
            @include pills-wrapper;
            .pills-wrapper {
                z-index: 1;
            }
            @media screen and (max-width: 768px) {
                order: -1;
                width: 96%;
            }
            @include breakpoint (small down) {
                width: 100%;
            }
            .veil {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .3);
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all .25s ease-out;
                &.show {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }
            .slick-dotted.slick-slider {
                @include breakpoint (small down) {
                    margin-bottom: 0;
                }
            }
            @include slider-arrows;
            .slick-dots {
                bottom: rem-calc(40);
                text-align: left;
                padding-left: rem-calc(10);
                li {
                    margin: rem-calc(0 7);
                    &.slick-active {
                        button {
                            &:before {
                                border: rem-calc(1) solid #fff;
                                background-color: #fff;
                                opacity: 1;
                            }
                        }
                    }
                    button {
                        &:before {
                            width: rem-calc(11);
                            height: rem-calc(11);
                            background-color: #fff;
                            border: rem-calc(1) solid #fff;
                            opacity: 0.5;
                        }
                    }
                }
            }
            .carouselWithVideo  {
                .slick-dots li{
                    &:nth-child(2) {
                        button {
                            &:before {
                                background: none;
                                border: unset;
                                content: url('../images/play-video-slider.svg');
                            }
                        }
                    }
                }
            }
        }
        &__slide {
            width: 100%;
            img {
                @include breakpoint (small down) {
                    padding-bottom: rem-calc(20);
                }
            }
        }
    }
}
.notify-block{
    @include breakpoint (medium up){
        box-shadow: 0px 5px 5px $light-gray;
        padding: rem-calc(31 0 59 36);
        margin: rem-calc(30 0);
        border-radius: rem-calc(5);
        width: rem-calc(536);
    }
    @include breakpoint (medium down){
        width: rem-calc(300);
        padding-left: rem-calc(10);
        margin-top: 0;
    }
    h3{
        font-size: rem-calc(18);
        font-weight: 800;
        margin-bottom: 0;
        @include breakpoint(medium down) {
            font-size: rem-calc(14);
        }
    }
    h4{
        font-size: rem-calc(13);
        font-weight: $global-weight-dark;
        margin-bottom: rem-calc(20);
        @include breakpoint(medium down) {
            font-size: rem-calc(12);
        }
    }
    .input-group {
        margin-bottom: 0;
        @include breakpoint(small only) {
            margin-left: rem-calc(10);

        }
    }
    #emailNotify {
        width: rem-calc(328);
        background-color: #F2F4ED;
        font-size: rem-calc(15);
        font-weight: $global-weight-normal;
        padding: rem-calc(0 0 0 45);
        border: rem-calc(1) solid transparent;
        border-bottom: rem-calc(1) solid #666;
        border-top-left-radius: rem-calc(3);
        border-top-right-radius: rem-calc(3);
        height: rem-calc(56);
        position: relative;
        transition: all .2s ease;
        @media screen and (max-width:1024px) {
        padding: rem-calc(10 0 10 38);
        font-size: rem-calc(14);
        }
        @include breakpoint(small only) {
        padding: rem-calc(13 0 13 38);
        font-size: rem-calc(13);
        }
        @include breakpoint (medium only){
            width: rem-calc(280);
        }
        &.is-invalid-input {
        ~ .label-for-input {
            display: none;
        }
        }
        &:hover {
        padding-top: rem-calc(1);
        background-color: #CCCCCC;
        border-bottom: rem-calc(2) solid #666;
        }
        &:focus {
        outline: none;
        background-color: #F2F4ED;
        border-bottom: rem-calc(2) solid #FF6699;
        padding: rem-calc(12 0 0 45);
        &::-webkit-input-placeholder {
            opacity: 0;
        }
        &::-moz-placeholder {
            opacity: 0;
        }
        &:-ms-input-placeholder {
            opacity: 0;
        }
        &:-moz-placeholder {
            opacity: 0;
        }
        &:not(:placeholder-shown) {
            border-bottom: rem-calc(2) solid #FF6699;
            ~ .input-label {
            color: #FF6699;
            }
        }
        ~ .input-label {
            opacity: 1;
            color: #FF6699;
        }
        }
        &:not(:placeholder-shown) {
        border-bottom: rem-calc(2) solid #666;
        padding: rem-calc(12 0 0 45);
        ~ .input-label {
            opacity: 1;
            color: $black;
        }
        }
    }
    svg {
        width: rem-calc(17);
        height: rem-calc(12);
        position: absolute;
        top: 38%;
        left: rem-calc(17);
        transform: translateY(-50%);
        @media screen and (max-width:1024px) {
        left: rem-calc(10);
        }
    }
    #submitNotify, #submitNotifyMobile {
        text-decoration: none;
        color: $white;
        background-color: $black;
        height: rem-calc(44);
        top: 38%;
        transform: translateY(-50%);
        right: rem-calc(178);
        border-radius: rem-calc(3);
        @include breakpoint(small only) {
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
            right: rem-calc(10);
        }
        @include breakpoint (medium only){
            right: rem-calc(20);
        }
    }
    .green{
        color: #00AB43;
    }
    .red{
        color: red;
    }
}
.find-in-store-soldout {
    font-size: rem-calc(15);
    font-weight: $global-weight-normal;
    padding: rem-calc(15 0 15);
    margin-top: rem-calc(30);
    border-radius: rem-calc(40);
    outline: transparent;
    font-weight: $global-weight-bold;
    width: 80%;
}
.attrValuesSlider {
    display: inline-flex;
    width: rem-calc(710);
    height: rem-calc(230);
    overflow-x: hidden;
}
.attrValuesSlider .swatch-circle{
    border-radius: 7%;
}
.slider-values-container{
    display: flex;
}
.slider-values-container > a.no-hover.color-variation{
    display: block;
    margin-right: rem-calc(140)!important;
    position:relative;
}
.wishlist-section {
    position: relative;

    h4 {
        font-size: rem-calc(14);
        font-weight: 700;
    }

    .mini-product-item{
        .code{
            font-size: rem-calc(10);
            font-weight: $global-weight-normal;
            color: #949494;
        }
        .product-name{
            font-size: rem-calc(14);
            font-weight: $global-weight-normal;
            letter-spacing: rem-calc(0.2);
            line-height: rem-calc(21);
        }
    }

    .cart {
        background-color: $white;
        border: rem-calc(1) solid $light-gray;
        border-right: none;
        padding: rem-calc(0);
        .bonus-product-list {
            .bonus-product-line-item {
                border-top: rem-calc(1) solid #d9d9d9;
            }
        }

        .item-container{
            padding: rem-calc(20);

            .price--tezenis .value {
                font-weight: 700;
            }
            &.shopping-bag-popover{
                padding: 0;
                .shopping-bag-title-box{
                    line-height: rem-calc(24);
                    font-size: rem-calc(14);
                    padding: rem-calc(23 40 23 37);
                    border-bottom: rem-calc(1) solid #CCCCCC;
                }
                .product-summary{
                    margin-right: rem-calc(15);
                    margin-top: rem-calc(25);
                    padding-left: rem-calc(40);
                    padding-right: rem-calc(25);
                    .minicart-item:first-child{
                        .card{
                            margin-top: 0;
                            .card-body{
                                padding-top: 0;
                            }
                        }
                    }
                    .card{
                        margin-bottom: rem-calc(20);
                    }
                    .card-body{
                        padding: rem-calc(15 0 8.5 0);
                        .mini-product-item{
                            &.fix-margin-grid{
                                margin-left: rem-calc(-7.5);
                                margin-right: rem-calc(-7.5);
                                .mini-product-item-cell{
                                    margin-left: rem-calc(7.5);
                                    margin-right: rem-calc(7.5);
                                    &.medium-3{
                                        width: calc(25% - #{rem-calc(15)});
                                    }
                                    &.medium-9{
                                        width: calc(75% - #{rem-calc(15)});
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .estimated-total{
            padding: rem-calc(16 40);
            .iva-text{
                font-size: rem-calc(12);
                padding-left: rem-calc(4);
            }
        }
        .button-continue-pay{
            padding: rem-calc(0 40 24 24);
            @include breakpoint (small down) {
                padding: rem-calc(0 16 24 16);
            }
            .checkout-button-circle{
                margin-bottom: 0;
            }
        }
        .checkout-button-circle{
            border-radius: rem-calc(40);
        }
    }

    .remove-btn {
        color: $medium-gray;
        float: right;
        background-color: $white;
        border: none;
        font-size: rem-calc(1.625em);
        margin-top: rem-calc(-0.313em);
        padding: 0;
    }

    .product-summary {
        margin-right: rem-calc(-0.938em);
        max-height: rem-calc(300);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: rem-calc(0.938em);


        &::-webkit-scrollbar {
            width: rem-calc(8);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #CCCCCC;
            border-radius: rem-calc(5/2);
        }

        .card{
            border:0;
            border-bottom: rem-calc(1) solid $border-gray;
        }
        .no-border-card{
            .card{
                border-bottom: 0;
            }
        }

    }

    .card-body {
        padding: rem-calc(0.625em);

        .product-line-item{
            .giftcard-product-info{
                .giftcard-code{
                    font-size: rem-calc(10);
                    font-weight: $global-weight-normal;
                    color: #949494;
                }
                .giftcard-name{
                    font-size: rem-calc(14);
                    font-weight: 700;
                    letter-spacing: rem-calc(0.3);
                    margin: 0;
                }
                .line-item-pricing-info{
                    margin-bottom: 0;
                    .line-item-total-text, .line-item-total-price-amount{
                        font-size: rem-calc(12);
                        letter-spacing: rem-calc(0.3);
                        color: $black;
                        font-weight: $global-weight-normal;
                    }
                }
                .line-item-product-info-taxt{
                    font-size: rem-calc(10);
                    font-weight: $global-weight-normal;
                    color: #949494;
                    margin: rem-calc(30 0 5);
                }
            }
        }
    }

    .price, .quantity-label {
        font-size: rem-calc(15);
        span {
            font-size: rem-calc(15);
        }
    }

    .quantity {
        width: 100%;
    }
    &.affixed-top{
        @include breakpoint(small down){
            position: unset;
        }
    }
    @include breakpoint(medium up){
        position: relative;
    }
    .wishlist-popover {
        position: absolute;
        left: auto;
        @include breakpoint(medium up){
            height: auto !important;
            top: rem-calc(40);
            right: rem-calc(-88);
        }
        width: rem-calc(313);
        @include breakpoint(small down) {
            width: 100vw;
        }
        display: block;
        transition: all 0.3s ease;
        visibility: hidden;
        opacity: 0;
        background: #fff;

        &::before {
            left: auto;
            right: rem-calc(15);
        }

        &::after {
            left: auto;
            right: rem-calc(16);
        }

        &.show,
        &.js-minicart-popover:hover {
            visibility: visible;
            opacity: 1;
            z-index: 906;
            @include breakpoint(small down) {
                position: fixed;
                top: rem-calc(0);
                left: rem-calc(0);
            }
        }

        .cart-title {
            display: block;
            padding: rem-calc(24 0 0 24);
            width: rem-calc(177);

            @include breakpoint (small down) {
                padding: rem-calc(25 0 0 16);
                width: rem-calc(273);
            }
        }

        .closeButton {
            position: absolute;
            top: rem-calc(28);
            right: rem-calc(44);
            width: rem-calc(24);

            @include breakpoint (small down) {
                right: rem-calc(20);
            }
        }

        .omnibus-in-checkout,
        .product-name-cell {
            width: 100%;
            flex: 0 0 auto;
        }

        .price-with-omnibus {
            margin: rem-calc(0 0 8 0);

            .sales {
                margin-left: 0;
            }

            .strike-through {
                .value {
                    padding-right: rem-calc(8);
                }
            }

            span {
                font-weight: $global-weight-normal;
                font-size: rem-calc(14);

                &.price-info {
                    font-size: rem-calc(10);
                }

                &.discount-value-percentage {
                    display: none;
                }
                &.discount-value-percentage.cc-partiallyShownCountry {
                    display: inline-block;
                }
            }

            .cc-salesWrapper .value{
                .cc-value {
                    order: 1;
                    padding-right: rem-calc(4);
                    &.cc-isOmnibus {
                        color: $sale;
                    }
                }
                .strike-through {
                    order: 0;
                    color: $black;
                }
                .discount-value-percentage {
                    order: 2;
                }
            }

            .cc-CountryWithDifferentTemplate .value{
                .cc-value {
                    order: 0;
                    color: $black;
                    padding-right: rem-calc(8);
                }
                .strike-through {
                    order: 1;
                    color: $off-white;
                }
                .discount-value-percentage {
                    order: 2;
                }
            }
        }

        .feedback-cart{
            .closeButton.color-black.pos-absolute{
                position: absolute;
                top: rem-calc(15);
                right: rem-calc(20);
            }
            .item-container{
                padding: rem-calc(40 20 24 24);
                @include breakpoint(small down){
                    padding: rem-calc(16 16 24 16);
                }
                .product-summary{
                    padding: 0;
                    margin: 0;
                }
                .product-line-item{
                    .fix-margin-grid{
                        margin-left: rem-calc(-7.5);
                        margin-right: rem-calc(-7.5);
                        .mini-product-cell{
                            margin-left: rem-calc(7.5);
                            margin-right: rem-calc(7.5);
                            &.small-3{
                                width: calc(25% - #{rem-calc(15)});
                            }
                            &.medium-9{
                                width: calc(75% - #{rem-calc(15)});
                            }
                            .line-item-added{
                                margin-bottom: rem-calc(8);
                            }
                            .product-name{
                                margin-bottom: rem-calc(4);
                            }
                        }
                    }
                    .line-item-added.promotion{
                        svg{
                            width: rem-calc(13);
                            margin: rem-calc(0 4 0 0);
                        }
                    }
                    .line-item-product-info-taxt{
                        font-size: rem-calc(10);
                        font-weight: 400;
                        color: #949494;
                        margin: rem-calc(30 0 5);
                        @include breakpoint (medium down){
                            margin: rem-calc(6 0 5);
                        }
                    }
                }
            }
            .estimated-total{
                .sub-total-label{
                    .iva-text{
                        font-size: rem-calc(12);
                        color: black;
                        font-weight: 400 !important;
                        padding-left: rem-calc(4);
                    }
                }
            }
        }

        .checkoutButtons {
            .wishlist-guest-description {
                font-size: rem-calc(12);
                font-weight: 400;
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.2);
                margin-bottom: rem-calc(24);
            }

            a, .button {
                width: 100%;
            }
        }
    }

    @include breakpoint (small down) {
        &.open {
            position: fixed;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3000;
            h6{
                font-weight: $global-weight-dark;
            }
            background-color: transparent;
        }

    }
}

.cc-findYourSizeModal{

    z-index: 1003;

    @include breakpoint (small down){
        overflow-y: auto;
        height: -webkit-fill-available;
    }
    .content-asset{
        @include breakpoint (small down){
            height: calc(100vh - #{rem-calc(141)});
            padding: rem-calc(0 16);
        }
    }
    &-close{
        position: absolute;
        top: rem-calc(15);
        right: rem-calc(2);
        @include breakpoint (medium up){
            top: rem-calc(40);
            right: rem-calc(24);
        }
    }
    &-back{
        @include breakpoint (small down){
            position: absolute;
            top: rem-calc(16);
        }
    }
    &-header{
        @include breakpoint (small down){
            position: sticky;
            top: 0;
            z-index: 3;
            background: $white;
            border-bottom: rem-calc(1) solid $over-gray;
            padding-left: rem-calc(16);
        }
    }

    .tingle-modal-box{
        width: 100vw;
        height: 100vh;
        height: -webkit-fill-available;
        max-height: 100vh;
        @include breakpoint (medium up){
        max-width: max-content;
        height: unset;
        max-height: unset;
        }
        &__content{
            padding: 0;
            height: calc(100vh - #{rem-calc(86)});
            @include breakpoint (medium up){
                padding: rem-calc(40);
                height: unset;
            }
        }
    }
    &-categoryName{
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-dark;
        line-height: normal;
        letter-spacing: rem-calc(0.2);
        margin-bottom: 0;
        @include breakpoint (small down){
            margin: rem-calc(0 0 16 40);
            padding-top: rem-calc(16);
            font-weight: $global-weight-bold;
        }
    }
    &-buttonMobile{
        @include breakpoint (medium down){
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
            padding: rem-calc(16);
            border-top: rem-calc(1) solid $over-gray;
            background: $white;
            &-button{
                width: rem-calc(343);
                margin-bottom: 0;
                // override _cta.scss:192
                background-color: $white !important;
            }
        }
    }
    &-buttonDesktop{
        @include breakpoint (medium up){
            position: sticky;
            left: rem-calc(40);
            bottom: rem-calc(40);
        }
    }
}

// Bra size guide content asset (aid = "size-guide-woman-bras")
.ca-pageStyleGuide{

    &-table-hidden {
        display: none;
        @include breakpoint (small down) {
            display: table-cell;
        }
    }

    &-braGuideType{
    color: $black;
    font-family: $body-font-family;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: rem-calc(871);
    @include breakpoint (small down){
        width: 100%;
        max-width: 100%;
    }
        &__table {
            width: 100%;
            display: block;
        }

        &__table th {
            font-weight: $global-weight-normal;
            font-size: rem-calc(14);
            font-family: $body-font-family;
            line-height: normal;
            letter-spacing: rem-calc(0.2);
            min-width: calc(#{rem-calc(63.68)} + 4vw);
            max-width: calc(#{rem-calc(63.68)} + 4vw);
            height: 6.9025vh;
        }

        &__table td {
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            padding: rem-calc(0 16);
            font-weight: $global-weight-normal;
            font-size: rem-calc(14);
            width: rem-calc(86);
            height: 6.902vh;
            text-align: center;
        }

        &__tableContainer {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint (small down){
                padding-bottom: rem-calc(87);
            }
        }

        &__tableGrid {
            display: flex;
            width: 100%;
            @include breakpoint (small down) {
                order: 1;
                width: 100%;
                position: relative;
            }
        }

        &__tableInner {
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow-x: auto;
            margin: rem-calc(24 0);
            padding-left: rem-calc(139);
            width: rem-calc(871);
            max-width: rem-calc(871);
            &::-webkit-scrollbar{
                display: none;
            }
        }

        &__tableOuter {
            width: 100%;
        }

        &__tableOuter.simple-table{
            display: inline-flex;
            align-items: center;
        }

        &-firstItem{
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            font-weight: $global-weight-dark;
        }
        &-firstLine{
            border-width: rem-calc(1 1 1 0);
            border-style: solid;
            border-color: $over-gray;
        }
        &-topLeft{
            border-top: rem-calc(1) solid $over-gray;
            @include breakpoint (small down){
                height: calc(#{rem-calc(38)} + 4vw);
            }
        }
        &-titleLeft{
            padding: rem-calc(16 16 16 0);
            border-bottom: 0;
            margin: 0;
            p{
                font-weight: $global-weight-dark;
                letter-spacing: rem-calc(0.14);
                margin-bottom: 0;
                width: rem-calc(85);
            }
        }
        &-titleRight{
            margin: 0;
            @include breakpoint (small down){
                padding-left: rem-calc(87.5);
                text-align: center;
            }
            p{
                font-weight: $global-weight-dark;
                margin: 0;
                @include breakpoint (small down){
                    position: absolute;
                    top: rem-calc(39);
                    width: rem-calc(50);
                }
            }
        }
        &-lastItem{
            border-right: 0;
        }
        &-lastLine{
            border-bottom: 0;
        }
    }
}

th.ca-pageStyleGuide-braGuideType-titleLeft{
    width: 7.1721vw;
}

td.ca-pageStyleGuide-braGuideType-lastItem {
    border-right: 0;
}

th.ca-pageStyleGuide-braGuideType-firstItem{
    font-weight: $global-weight-dark;
    @include breakpoint (medium up){
        min-width: rem-calc(139);
        max-width: rem-calc(139);
        position: absolute;
        background: $white;
        margin-left: rem-calc(-139);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
}

td.ca-pageStyleGuide-braGuideType-lastLine{
    border-bottom: 0;
    @include breakpoint (small down){
        // override among common element in CA
        height: rem-calc(74) !important;
    }
}

th.ca-pageStyleGuide-braGuideType__special-th{
    border-bottom: none;
}

@include breakpoint (small down) {

    .ca-pageStyleGuide{
        &-braGuideType{
            &__table tr:first-of-type th {
                width: rem-calc(1000);
            }

            &__table tr:first-of-type {
                border-bottom: none;
            }

            &__table {
                display: block;
                width: calc(100%);
            }

            &__tableInner {
                width: 100%;
                overflow-x: scroll;
                padding-left: rem-calc(96);
                padding-bottom: rem-calc(13);
            }

            &__table th {
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                min-width: calc(#{rem-calc(60)} + 4vw);
                max-width: calc(#{rem-calc(60)} + 4vw);
                height: 7.945vh;
            }

            &__table td {
                max-width: none;
                min-width: calc(#{rem-calc(60)} + 4vw);
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                height: 7.945vh;
            }

            &__table td:last-of-type {
                padding-left: calc(#{rem-calc(6)} + 2vw);;
                padding-right: calc(#{rem-calc(6)} + 2vw);
            }

            &__table th:nth-of-type(1) {
                min-width: calc(#{rem-calc(83)} + 4vw);
                max-width: calc(#{rem-calc(83)} + 4vw);
                z-index: 1;
                position: absolute;
                left: 0;
                background: white;
                top: auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__table tr:nth-of-type(1) th {
                min-width: calc(#{rem-calc(63)} + 4vw);
                max-width: none;
                height: calc(#{rem-calc(58)} + 4vw);
            }

            &__table tr:nth-of-type(1) th:nth-of-type(1) {
                min-width: calc(#{rem-calc(83)} + 4vw);
                max-width: calc(#{rem-calc(83)} + 4vw);
                z-index: 2;
                word-break: break-word;
                word-wrap: break-word;
                background: white;
                padding-left: 0;
                padding-right: rem-calc(10);
            }
        }
    }

    th.ca-pageStyleGuide-braGuideType__special-th {
        font-size: rem-calc(14);
        font-weight: $global-weight-dark;
        text-align: center;
        padding: rem-calc(16 16 16 0);
        height: rem-calc(74);
        border-bottom: 0;
    }

}

// Body size guide content asset (aid = "size-guide-woman-generic")
.ca-pageStyleGuide{

    &-table-hidden {
        display: none;
        @include breakpoint (small down) {
            display: table-cell;
        }
    }

    &-bodyGuideType{
    color: $black;
    font-family: $body-font-family;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
        &__table {
            width: 100%;
        }

        &__table th {
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            font-family: $body-font-family;
            line-height: normal;
            letter-spacing: rem-calc(0.2);
            height: 6.9025vh;
        }

        &__table td {
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            padding: rem-calc(0 16);
            font-weight: $global-weight-normal;
            font-size: rem-calc(14);
            width: rem-calc(86);
            height: 6.902vh;
            text-align: center;
        }

        &__tableContainer {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint (medium up){
                width: auto;
            }
        }

        &__tableGrid {
            display: flex;
            width: 100%;
            @include breakpoint (small down) {
                order: 1;
                width: 100%;
                position: relative;
                margin-top: rem-calc(24);
            }
        }

        &__tableInner {
            max-width: rem-calc(962);
            overflow-x: scroll;
            padding-left: rem-calc(59.5);
            padding-bottom: rem-calc(13);
            margin: unset;
            &::-webkit-scrollbar{
                display: none;
            }
            @include breakpoint (medium up){
                width: 100%;
                display: flex;
                flex-direction: column;
                overflow-x: auto;
                margin: rem-calc(24 0);
                padding-bottom: rem-calc(8);
            }
        }

        &__tableOuter {
            overflow-x: hidden;
            z-index: 0;
            @include breakpoint (medium up){
                width: 100%;

            }
        }

        &__tableOuter.simple-table{
            display: inline-flex;
            align-items: center;
        }

        &-firstItem{
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            font-weight: $global-weight-dark;
        }
        &-firstLine{
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            &__size{
                min-width: calc(#{rem-calc(5.35)} + 4vw);
            }
            &__sizeConversion{
                // override common styles in content asset
                min-width: auto !important;
                max-width: calc(#{rem-calc(151)} + 4vw);
                padding: rem-calc(0 16);
                @include breakpoint (large up){
                    width: auto !important;
                    max-width: unset;
                    white-space: nowrap;
                }
            }
            &__breast{
                // override common styles in content asset
                min-width: auto !important;
                max-width: calc(#{rem-calc(151)} + 4vw);
                padding: rem-calc(0 16);
                @include breakpoint (large up){
                    width: auto !important;
                    max-width: unset;
                    white-space: nowrap;
                }
            }
            &__underband{
                // override common styles in content asset
                min-width: auto !important;
                max-width: calc(#{rem-calc(151)} + 4vw);
                padding: rem-calc(0 16);
                @include breakpoint (large up){
                    width: auto !important;
                    max-width: unset;
                    white-space: nowrap;
                }
            }
            &__waist{
                // override common styles in content asset
                min-width: auto !important;
                max-width: calc(#{rem-calc(151)} + 4vw);
                padding: rem-calc(0 16);
                @include breakpoint (large up){
                    width: auto !important;
                    max-width: unset;
                    white-space: nowrap;
                }
            }
            &__hips{
                // override common styles in content asset
                min-width: auto !important;
                max-width: calc(#{rem-calc(151)} + 4vw);
                padding: rem-calc(0 16);
                @include breakpoint (large up){
                    width: auto !important;
                    max-width: unset;
                    white-space: nowrap;
                }
            }

        }
        &-topLeft{
            border-width: rem-calc(0 1 1 0);
            border-style: solid;
            border-color: $over-gray;
            @include breakpoint (small down){
                height: calc(#{rem-calc(38)} + 4vw);
            }
        }
        &-titleLeft{
            padding: rem-calc(16 16 16 0);
            border-bottom: 0 ;
            margin: 0 ;
            p{
                font-weight: $global-weight-dark;
                letter-spacing: rem-calc(0.14);
                margin-bottom: 0;
            }
        }
        &-titleRight{
            margin: 0;
            @include breakpoint (small down){
                padding-left: rem-calc(87.5);
                text-align: center;
            }
            p{
                font-weight: $global-weight-dark;
                margin: 0;
                @include breakpoint (small down){
                    width: rem-calc(50);
                }
            }
        }
        &-lastItem{
            border-right: 0;
        }
        &-lastLine{
            border-bottom: 0;
        }
    }
}

th.ca-pageStyleGuide-bodyGuideType-titleLeft{
    width: 7.1721vw;
}

td.ca-pageStyleGuide-bodyGuideType-lastItem {
    border-right: 0;
}

th.ca-pageStyleGuide-bodyGuideType-firstItem{
    font-weight: $global-weight-dark;
    @include breakpoint (medium up){
        position: absolute;
        min-width: rem-calc(60);
        margin-left: rem-calc(-60);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background: $white;
    }
}

td.ca-pageStyleGuide-bodyGuideType-lastLine{
    border-bottom: 0;
}

th.ca-pageStyleGuide-bodyGuideType__special-th{
    border-bottom: none;
}

@include breakpoint (small down) {

    .ca-pageStyleGuide{
        &-bodyGuideType{
            width: 100%;
            max-width: 100%;
            &__table tr:first-of-type th {
                width: rem-calc(1000);
            }

            &__table tr:first-of-type {
                border-bottom: none;
            }

            &__table {
                display: block;
                width: calc(100%);
            }

            &__table th {
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                min-width: calc(#{rem-calc(60)} + 4vw);
                max-width: calc(#{rem-calc(60)} + 4vw);
                height: 7.945vh;
            }

            &__table td {
                max-width: none;
                min-width: calc(#{rem-calc(60)} + 4vw);
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                height: 7.945vh;
            }

            &__table td:last-of-type {
                padding-left: calc(#{rem-calc(6)} + 2vw);;
                padding-right: calc(#{rem-calc(6)} + 2vw);
            }

            &__table th:nth-of-type(1) {
                min-width: calc(#{rem-calc(45)} + 4vw);
                max-width: calc(#{rem-calc(45)} + 4vw);
                z-index: 1;
                position: absolute;
                left: 0;
                background: white;
                top: auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__table tr:nth-of-type(1) th {
                min-width: calc(#{rem-calc(45)} + 4vw);
                max-width: none;
                height: calc(#{rem-calc(59)} + 4vw);
            }

            &__table tr:nth-of-type(1) th:nth-of-type(1) {
                min-width: calc(#{rem-calc(45)} + 4vw);
                max-width: calc(#{rem-calc(45)} + 4vw);
                z-index: 2;
                word-break: break-word;
                word-wrap: break-word;
                background: white;
                padding: 0;
            }
        }
    }

    th.ca-pageStyleGuide-bodyGuideType__special-th {
        font-size: rem-calc(14);
        font-weight: $global-weight-dark;
        text-align: center;
        padding: rem-calc(16 16 16 0);
        height: rem-calc(74);
        border-bottom: 0;
    }

}

.cc-plpEmptyPage{
    &-container{
        display: flex;
        background: $over-gray;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: rem-calc(16);
        margin-top: rem-calc(16);
        margin-bottom: rem-calc(24);
        margin-left: rem-calc(-16);
        width: calc(100% + #{rem-calc(31)});
        @include breakpoint (medium up){
            padding: rem-calc(40);
            margin-top: rem-calc(40);
            margin-bottom: 0;
            margin-left: rem-calc(-40);
            width: calc(100% + #{rem-calc(80)});
        }
    }
    &-title{
        font-size: rem-calc(18);
        font-style: normal;
        font-weight: $global-weight-bold;
        line-height: normal;
        width: 100%;
        margin-bottom: rem-calc(8);
        @include breakpoint (medium up){
            font-size: rem-calc(26);
            font-style: normal;
            font-weight: $global-weight-bold;
            line-height: rem-calc(34);
            width: 30.592vw;
        }
    }
    &-description{
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: normal;
        letter-spacing: rem-calc(0.2);
        width: 100%;
        margin-bottom: rem-calc(16);
        @include breakpoint (medium up){
            font-size: rem-calc(16);
            font-weight: $global-weight-normal;
            line-height: normal;
            letter-spacing: rem-calc(0.2);
            width: 30.592vw;
            margin-bottom: rem-calc(24);
        }
    }
    &-button{
        margin-bottom: 0;
    }
    &-einsteinRecommendations{
        #ProdRecommendation{
            &.theMostPopularContainer--tezenis{
                .carousel-header{
                    padding-left: 0;

                    h4.recommendation {
                        font-size: rem-calc(26);
                        @include breakpoint (medium up) {
                            font-size: rem-calc(40);
                        }
                    }
                }
                .einstein-carousel{
                    padding: 0;
                    width: calc(100% + #{rem-calc(16)});
                    @include breakpoint (medium up){
                        width: calc(100% + #{rem-calc(40)});
                    }
                    .slick-list{
                        @include breakpoint (small down){
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
.cc-plpEmptyPage{
    &-imageContainer{
        &-image{
            border-radius: rem-calc(16);
            width: 46.473vw;
        }
    }
}