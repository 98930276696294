.product {
    margin-bottom: rem-calc(32);
    &-tile {
        display: flex;
        flex-direction: column;

        &:hover {
            @include breakpoint(large up) {
                .tile-image {
                    border-radius: rem-calc(4);

                    &--still-life {
                        opacity: 1;
                    }

                    &--change {
                        opacity: 0;
                    }
                }
                .quick-buy-sizes {
                    opacity: 1;
                }
                .pd-scrolling-text {
                    border-radius: rem-calc(4 4 0 0);
                }
                .card-overlay {
                    opacity: 0;
                }
            }

            .color-swatches {
                .swatches {
                    @include breakpoint(large up) {
                        &__variation-number {
                            display: none;
                        }
                        &__variation-list {
                            display: flex;
                            align-items: center;
                            gap: rem-calc(8);
                            line-height: rem-calc(18);
                            height: rem-calc(18);
                        }
                    }
                }
            }
        }

        &, .js-pdp-link {
            font-weight: 400;
        }

        .pills-message {
            &__container {
                position: absolute;
                bottom: rem-calc(12);
                left: rem-calc(16);
                z-index: 1;
            }

            &__coming-soon {
                background-color: $black;
            }

            &__text.label-cta {
                @include breakpoint (medium down) {
                    max-width: rem-calc(110);
                }
            }
        }

        @include breakpoint(large up) {
            &:hover {
                .pd-scrolling-text__content-text-p {
                    -webkit-animation-play-state: paused;
                }
            }
        }

        .pd-scrolling-text {
            visibility: hidden;
            display: flex;
            padding: rem-calc(3 0);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(150px);
            border-radius: rem-calc(16 16 0 0);
            transition: all 0.3s ease;
            color: $sustainability;

            @keyframes scrollingtext {
                from {
                    -webkit-transform: translate3d(0%, 0, 0);
                    transform: translate3d(0%, 0, 0)
                }

                to {
                    -webkit-transform: translate3d(-100%, 0, 0);
                    transform: translate3d(-100%, 0, 0)
                }
            }

            overflow: hidden;
            white-space: nowrap;
            width: 100%;

            &__content-text {
                display: inline-block;
                position: relative;
                text-decoration: none;

                &-p {
                    display: inline-flex;
                    align-items: center;
                    box-sizing: content-box;
                    animation: scrollingtext 10s linear -1s infinite forwards;
                    position: relative;
                    white-space: nowrap;
                    margin: 0;
                    padding-right: rem-calc(8);
                    font-weight: 500;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
            }
        }
    }
}

.image-container {
    .tile-image,
    video {
        transition: all 0.3s ease;
        border-radius: rem-calc(16);

        &--still-life {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all 0.2s linear;
            width: 100%;
        }
    }

    .quick-buy-sizes {
        background: $white;
        padding: rem-calc(16);
        border-radius: rem-calc(4);
        position: absolute;
        left: rem-calc(16);
        bottom: rem-calc(16);
        width: calc(100% - 32px);
        opacity: 0;
        transition: opacity 0.1s linear;
        z-index: 1;

        &__label {
            font-size: rem-calc(10);
            line-height: rem-calc(15);
            margin-bottom: 0;
            color: $black;

            &--unavailable,
            &--lowInStock {
                display: none;
            }
        }

        &__row {
            &-item {
                border: 1px solid transparent;
                border-radius: rem-calc(20);
                padding: rem-calc(4 10);
                cursor: pointer;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin-top: rem-calc(12);
                color: $black;

                &:hover {
                    border-color: #E8E8E8;
                }

                .notify-icon,
                .low-in-stock-icon {

                    svg {
                        width: rem-calc(12);
                        height: rem-calc(12);
                        margin: rem-calc(0 0 0 2);
                    }
                }

                &.disable {
                    color: #C4C0C0;
                }
            }
        }
    }

    .pills-wrapper {
        .js-new-msg {
            color: $black;
        }
    }
}

.tile-body {
    color: $black;
    margin-top: rem-calc(16);

    .color-swatches{
        margin-bottom: rem-calc(8);

        .swatches {
            line-height: rem-calc(17);
            font-size: rem-calc(12);
            @include breakpoint (medium down) {
                font-size: rem-calc(10);
                line-height: rem-calc(10);
            }

            &__more-colors {
                font-size: rem-calc(12);
                line-height: rem-calc(17);
            }

            &__container {
                font-size: 0;
                &.selected::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: rem-calc(16);
                    height: rem-calc(16);
                    border: rem-calc(1) solid $black;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &__circle {
                width: rem-calc(12);
                height: rem-calc(12);
                border-radius: 50%;
            }

            &__variation-list,
            &__variation-number {
                line-height: rem-calc(18);
                font-weight: 400;
                @include breakpoint (medium down) {
                    line-height: rem-calc(10);
                }
            }
        }
    }
}

.pdp-link {
    font-weight: 400;
    &__title {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(8);
        padding-right: rem-calc(24);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: $black;
        @include breakpoint(small down) {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            padding-right: rem-calc(8);
            margin-bottom: rem-calc(4);
        }
    }

    &--tezenis {
        .wishlistTile {
            background-image: url("../../images/wishlist.svg");
            width: rem-calc(16);
            height: rem-calc(16);
            background-size: rem-calc(16);
            background-repeat: no-repeat;
            transition: background-image 0.1s linear;
            margin-right: rem-calc(8);
            margin-top: rem-calc(2);

            &:hover {
                background-image: url("../../images/wishlist-watergreen.svg");
            }

            &.added {
                background-image: url("../../images/wishlist-watergreen-full.svg");
            }
        }
    }
}


.price-container {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    color: $black;
    @include breakpoint(large up) {
        margin-top: rem-calc(4);
    }
    @include breakpoint(small down) {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    .price {
        width: 100%;
        .sales {
            display: inline;
            &.cc-hidePercentage {
                display: none;
            }
        }
    }

    .price .strike-through{
        display: inline-block;

        & ~ .sales {
            color: $sale;
            margin-left: rem-calc(8);
        }
    }

    .price .cc-CountryWithDifferentTemplate {
        .strike-through {
            margin-left: rem-calc(8);
        }
    }
    .price-second-currency{
        margin-top: rem-calc(4);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
        text-align: left;

        @include breakpoint(small down) {
            font-size: rem-calc(10);
            line-height: rem-calc(15);
        }
        .double-currency-sales{
            &.discount-sale {
                display: inline-block;
                margin-left: rem-calc(4);
                color: #D10B82;
            }
        }

        .double-currency-sales, .double-currency-list, .discount-value-percentage{
            @include breakpoint(small down) {
                font-size: rem-calc(10);
                line-height: rem-calc(15);
            }
        }
    }
}

