@import '../pdp/product';

.quickview {
    display: inline-block;
    padding: rem-calc(8);
    position: absolute;
    bottom: 0;
    right: 0;

    &.quick-add {
        position: static;
        width: 100%;
        margin-bottom: 0;
    }

    svg {
        width: rem-calc(18);
        height: rem-calc(18);
    }

    &--tezenis {
        right: rem-calc(9);
        bottom: rem-calc(9);
        padding: rem-calc(15 11);
        background: transparent;
        backdrop-filter: blur(1px) contrast(.5);
        --webkit-backdrop-filter: blur(1px) contrast(.5);
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: rem-calc(5);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .25s ease;
        svg {
            width: rem-calc(28);
            height: auto;
        }

        @media (max-width: 1024px) {
            padding: rem-calc(6 3);
            svg {
                transform: scale(.7);
                transform-origin: center;
            }
        }
        @media (max-width: 768px) {
            padding: rem-calc(4 2);
        }
        @include breakpoint (small down) {
            display: none;
        }
    }
}

.quickview-modal, .bonus-product-modal {
    .tingle-modal-box__content {
        padding: rem-calc(20);
    }

    .cell-flex-quick{
        @include breakpoint (large up){
            display: flex;
            flex-direction: column;
        }

        .cc-omnibusStack {
            margin-top: rem-calc(4);
        }
    }

    & .marketing-messages {
        margin-left: rem-calc(10);
        & .callout {
            display: inline-block;
        }
    }

    .prices {
        .value {
            font-size: rem-calc(14);
            font-weight: $global-weight-bold;
        }
    }

    .add-to-wishlist {
        display: inline-block;
        margin-left: rem-calc(16);

        &:hover {
            color: $primary;
        }
    }

    // Custom tingle
    .tingle-modal-box {
        max-width: rem-calc(459);
        overflow: auto;

        &__content {
            .close {
                font-weight: 100;
                font-size: rem-calc(48);
            }
            .close-custom{
                position: absolute;
                top: rem-calc(24);
                right: rem-calc(40);
                cursor: pointer;
                margin-right: 0;
                @include breakpoint (small down) {
                    top: rem-calc(16);
                    right: rem-calc(16);
                }
            }
        }
    }
}

a {
    &.added {
        color: $primary;
    }
}
.button-padding-right5{
    padding-right:rem-calc(5);
}
.button-padding-left5{
    padding-left:rem-calc(5);
}
.button-bag-full-width{
    width:100%;
    margin-right: 0;
}
.quick-add-button-wrapper {
    @include breakpoint (ipad up) {
        display: none;
    }
    margin-top: rem-calc(8);
}