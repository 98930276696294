@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';


.slick-list {

    .slick-loading & {
        background: #fff url("../images/ajax-loader.gif") center center no-repeat;
    }
}

.slick-dots {
    li {
        width: rem-calc(5);
        height: rem-calc(5);
        margin: 0 rem-calc(2.5);

        button:before {
            opacity: 1;
            content: '';
            width: rem-calc(5);
            height: rem-calc(5);
            border-radius: 50%;
            border: 1px solid $medium-light-gray;
            background: $medium-light-gray;
        }

        &.slick-active {
            button:before {
                opacity: 1;
                border-color: $primary;
                background: $primary;
                width: rem-calc(5);
                height: rem-calc(5);
            }
        }
    }
}

/* Remove slick font family from nav and dots */
.slick-prev:before,
.slick-next:before,
.slick-dots li button:before {
    font-family: $body-font-family;
}
